import React, {useCallback, useState,} from "react";
import { init, send } from "emailjs-com";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import NotSignInHeader from "../components/header/NotSignInHeader"
import { useDispatch, useSelector } from "react-redux";
import FooterVar2 from "../components/footer/footer_ver2"
import { getIsSignedInCompany } from "../redux/company/selectors";
import SideHeader from "../components/header/SideHeader";
import Footer from "../components/footer/footer";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: "auto",
        [theme.breakpoints.down('sm')]: {
        width: "100%",      
        height: "auto",
        display: "block"        
        },
    },
    form: {
        width: 700,
        margin: "0 auto",
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            width: 300,
            padding: "20px 0",                        
        },     
    },
    contact: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",        
    },
    subject: {
        color: "#e4002b",
        fontSize: "20px",        
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    table: {
        border: "1px solid #ddd",   
        width: 700,
        [theme.breakpoints.down('sm')]: {
            width: 300
        },            
    },
    tr: {
        borderBottom: "1px solid #ddd",   
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexFlow: "column",
            width: 300
        },                     
    },
    th: {
        fontSize: 15,
        backgroundColor: "#f2f2f2",
        borderRight: "1px solid #ddd",        
        padding: "10px 20px",
        textAlign: "left",               
        verticalAlign: "top",
        width: 200,
        [theme.breakpoints.down('sm')]: {
            width: 300
        },                          
    },  
    td: {                                                    
        padding: "10px 20px",
        display: "flex",
        backgroundColor: "#fff",
        width: 500,
        [theme.breakpoints.down('sm')]: {
            width: 300,
            flexFlow: "column",
            alignItems: "flex-start",
            marginLeft: 0
        },                               
    },
    nessesay: {
        backgroundColor: "#e4002b",
        color: "#fff",
        width: "max-content",
        padding: "3px 15px",
        marginTop: 20,         
    },
    field: {
        width: 500,
        [theme.breakpoints.down('sm')]: {
            width: 250
        },           
    },
    fieldName: {
        width: 160,
    },      
    flex: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: 200
        },     
    },  
    margin: {
        marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
}))

const Contact = () => {     
    const classes = useStyles();
    const disptach = useDispatch();
    const selelctor = useSelector((state) => state)
    const isSignIn = getIsSignedInCompany(selelctor)
    const [title, setTitle ] = useState(""),
          [content, setContent] = useState(""),
          [firstName, setFirstName] = useState(""),
          [lastName, setLastName] = useState(""),       
          [email, setEmail] = useState(""),
          [confirmEmail, setConfirmEmail] = useState("");        
    
    const inputTitle = useCallback((e) => {
        setTitle(e.target.value)
    }, [setTitle])
    const inputContent = useCallback((e) => {
        setContent(e.target.value)
    }, [setContent])
    const inputFirstName = useCallback((e) => {
        setFirstName(e.target.value)
    }, [setFirstName])
    const inputLastName = useCallback((e) => {
        setLastName(e.target.value)
    }, [setLastName])   
    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    }, [setEmail])
    const inputConfirmEmail = useCallback((e) => {
        setConfirmEmail(e.target.value)
    }, [setConfirmEmail]) 

    const sendMail = () => {        
        const userID = process.env.REACT_APP_EMAIL_JS_USER_ID;
        const serviceID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
        const templateID = process.env.REACT_APP_EMAIL_JS_CONTACTFORM_TEMPLATE_ID;        

        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(userID);
            const template_param = {
                title: title,
                content: content,
                firstName: firstName,
                lastName: lastName,           
                email: email,              
            }; 

            if(firstName === "" || lastName === "" || email === "" || confirmEmail === "") {
                alert("必須項目が未入力です")
                return false
            }
            
            if(email !== confirmEmail) {
                alert("メールアドレスが一致しません。")
                return false
            }

            send(serviceID, templateID, template_param)
            .then(() => {
                alert("お問合せありがとうございます。")
            }).catch(() => {
                alert('送信に失敗しました。')
            })                        
        }        
    }

    return (
        <div>
        {!isSignIn && (
            <NotSignInHeader />
        )}  
        <div className={classes.root}>
        {isSignIn && (
            <SideHeader />
        )} 
        <div className={classes.form}>
            <div className={classes.contact}>
                {!isSignIn && (
                    <div className="module-spacer--medium" />
                )}            
                <h2 className={classes.subject}>お問い合わせ内容</h2>
                <div className="module-spacer--small" />
                <table className={classes.table}>
                    <tr className={classes.tr}>
                        <th className={classes.th}>件名<br /><span className={classes.nessesay}>必須</span></th>
                        <td className={classes.td}>
                            <TextField variant="outlined" placeholder="件名を入力してください" className={classes.field} value={title} onChange={inputTitle} />
                        </td>
                    </tr>
                    <tr className={classes.tr}> 
                        <th className={classes.th}>お問い合わせ内容<br /><span className={classes.nessesay}>必須</span></th>
                        <td className={classes.td}>
                            <TextField variant="outlined" type="text" rows={8} multiline={true} placeholder="お問い合わせ内容を入力してください" className={classes.field} value={content} onChange={inputContent} />
                        </td>
                    </tr>
                </table>
            </div>
            <div className="module-spacer--small" />
            <div className={classes.contact}>
            <h2 className={classes.subject}>お客様情報</h2>
            <div className="module-spacer--small" />
            <table className={classes.table}>
                <tr className={classes.tr}>
                    <th className={classes.th}>氏名<br /><span className={classes.nessesay}>必須</span></th>
                    <td className={classes.td}>
                        <div className={classes.flex}>
                            <span style={{marginRight: 20}}>姓</span>
                            <TextField variant="outlined" type="text" placeholder="例)小林" className={classes.fieldName} value={firstName} onChange={inputFirstName} />
                        </div>
                        <div className={classes.flex}>
                            <span className={classes.margin} style={{marginRight: 20}}>名</span>
                            <TextField variant="outlined" type="text" placeholder="例)雄大" className={classes.fieldName} value={lastName} onChange={inputLastName} />
                        </div>                        
                    </td>
                </tr>                
                <tr className={classes.tr}>
                    <th className={classes.th}>メールアドレス<br /><span className={classes.nessesay}>必須</span></th>
                    <td className={classes.td}>
                        <TextField variant="outlined" type="email" rows={1} placeholder="例)aaa@example.com" className={classes.field} value={email} onChange={inputEmail} />
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>メールアドレス確認用<br /><span className={classes.nessesay}>必須</span></th>
                    <td className={classes.td}>
                        <TextField variant="outlined" type="email" rows={1} placeholder="例)aaa@example.com" className={classes.field} value={confirmEmail} onChange={inputConfirmEmail} />
                    </td>
                </tr>                                                                      
            </table>
            </div>             
            <div className="module-spacer--small" />
            <button onClick={() => sendMail()} className={classes.button}>送信する</button>
            </div>
        <div className="module-spacer--medium" />
        </div>
        {!isSignIn && (
            <FooterVar2 />
        )}
        </div>
    )
}

export default Contact;

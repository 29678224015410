import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCompanyMails } from '../../redux/company/operation';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, List, Typography } from '@material-ui/core';
import SideHeaderMenu from './SideHeaderMenu';
import SearchDrower from './SerchDrower';
import SideDrower from './SideDrower';
import Logo from '../../assets/img/logo1.png';
import { push } from 'connected-react-router';

const useStyles = makeStyles((theme) => ({    
    logo: {
        width: 150,
        marginTop: 15,            
        cursor: "pointer",        
        [theme.breakpoints.down('sm')]: {            
            marginTop: 0,
            marginLeft: 10
        },        
    },
    list: {
        display: 'flex', 
        justifyContent: 'space-between',    
        flexFlow: "column", 
        alignItems: "center",           
        [theme.breakpoints.down('sm')]: {            
            height: "auto",
            flexFlow: "row"            
        },                        
    },
    paper: {    
        width: 220,
        height: "100vh",
        backgroundColor: "#FF9D00",
        color: "#fff",
        padding: 10,
        zIndex: 999,
        [theme.breakpoints.down('sm')]: {
          width: "100%",
          height: "auto",
          position: "fixed",
          padding: 0
      },
    },  
    nested: {    
        width: 180,
        color: "#000000",
        marginTop: -20
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },   
}))

const SideHeader = () => {
    const dispatch = useDispatch();
    const classes = useStyles();        
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState(false);

    const menuDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(!open)
    }, [setOpen, open]);          
    const searchDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        search(!search)
    }, [setSearch, search]);          

    useEffect(() => {
        dispatch(fetchCompanyMails())
    }, [])

    return(
        <div>            
            <Paper className={classes.paper} elevation={0}>  
            <List component="nav" className={classes.list}>  
                <Typography variant="h6" noWrap component="div" className={classes.logo}  >
                    <img src={Logo} alt='ロゴ' onClick={() => dispatch(push('/companyhome'))} />                                         
                </Typography>
                <SideHeaderMenu menuDrawerToggle={menuDrawerToggle} />
            </List>
            </Paper>                   
            <SideDrower open={open} onClose={menuDrawerToggle} />
            {/* <SearchDrower open={search} onClose={searchDrawerToggle} /> */}
        </div>
    )
}

export default SideHeader;
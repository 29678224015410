import React, { useCallback, useContext, useState } from "react";
import { Button, TextField, Paper, makeStyles, IconButton, OutlinedInput, InputAdornment } from '@material-ui/core';
import { multiStepContext } from "../../SteoContext";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { isValidRequiredInput, isValidEmailFormat } from '../../function/common'

const useStyle = makeStyles((theme) => ({
    card: {
        width: 600,
        padding: 30,
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        border: "solid 1px #707070",
        [theme.breakpoints.down('sm')]: {            
            width: "90%",
            margin: "0 auto"          
        },
    },
    title: {
        fontSize: 20,
        fontWeight: 400
    },
    nessesary: {
        color: "red",
        fontSize: 10
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
    buttonDisabled: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#ccc",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
    },
    position: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column",
        }
    },
    text: {
        width: "max-content",
        cursor: "pointer",
        paddingRight: 20,
        fontSize: 12,
        color: "#707070",
        [theme.breakpoints.down('sm')]: {            
            marginTop: 10
        },
        '&:hover': {
            opacity: 0.8
        },
    },
    field: {
        width: 300,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    fieldHarf: {
        [theme.breakpoints.down('sm')]: {
            width: 150
        }
    },
    link: {
        display: "flex",
        flexFlow: "column",
        textAlign: "left",
        justifyContent: "start",
        marginRight: 160,
        color: "#707070",
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        }
    },
    list: {
        fontSize:12,
        cursor: "pointer",
        color: "#0027FF",
        '&:hover': {
            opacity: 0.8
        },
        [theme.breakpoints.down('sm')]: {
            width: 200
        },
    },
    flexFlow: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",
    },
    flex: {
        display: "flex",
        width: 300,
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    space_big:{
        height: 50,
    },
    space_small: {
        height: 15,
    },
    phone_space: {        
        [theme.breakpoints.down('sm')]: {
            height: 100
        }
    }
}))

const BasicInfo = () => {
    const classes = useStyle();
    const { setCurrentStep,
            companyName, setCompanyName,
            firstName, setFirstName,
            lastName, setLastName,
            email, setEmail,
            password, setPassword,
            confirmPass, setConfirmPass,
            addressNum, setAddressNum,
            address, setAddress,
            tel, setTel
        } = useContext(multiStepContext);

    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const [isRevealConfirmPassword, setIsRevealConfirmPassword] = useState(false);
    const inputCompanyName = useCallback((e) => {
        setCompanyName(e.target.value)
    }, [setCompanyName])
    const inputFirstName = useCallback((e) => {
        setFirstName(e.target.value)
    }, [setFirstName])
    const inputLastName = useCallback((e) => {
        setLastName(e.target.value)
    }, [setLastName])
    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    }, [setEmail])
    const inputPassword = useCallback((e) => {
        setPassword(e.target.value)
    }, [setPassword])
    const inputConfirmPass = useCallback((e) => {
        setConfirmPass(e.target.value)
    }, [setConfirmPass])
    const inputAddressNum = useCallback((e) => {
        setAddressNum(e.target.value)
    }, [setAddressNum])
    const inputAddress = useCallback((e) => {
        setAddress(e.target.value)
    }, [setAddress])
    const inputTel = useCallback((e) => {
        setTel(e.target.value)
    }, [setTel])

    const togglePassword = () => {
        setIsRevealPassword((prevState) => !prevState);
    }
    const toggleConfirmPassword = () => {
        setIsRevealConfirmPassword((prevState) => !prevState);
    }
    return(
        <div className={classes.form}>
            <Paper elevation={0} className={classes.card}>
            <h2 className={classes.title}>基本情報</h2>
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>店舗名<span className={classes.nessesary}>※必須</span></p>    
            <TextField
            multiline={true} variant="outlined" className={classes.field} margin='dense'
            required={true} rows={1} value={companyName} type={"text"} onChange={inputCompanyName} placeholder='例)店舗名'
            />
            </div>
            <div className={classes.flex}>
                <div className={classes.flexFlow}> 
                <p style={{fontSize: 14, color: "#707070"}}>(姓)<span className={classes.nessesary}>※必須</span></p>    
                <TextField
                multiline={true} variant="outlined" margin='dense'style={{marginTop: 0}} placeholder='例)山田'
                required={true} rows={1} value={firstName} type={"text"} onChange={inputFirstName} className={classes.fieldHarf}
                />
                </div>
                <div className={classes.flexFlow}> 
                <p style={{fontSize: 14, color: "#707070"}}>(名)<span className={classes.nessesary}>※必須</span></p>    
                <TextField
                multiline={true} variant="outlined" margin='dense'style={{marginTop: 0}} placeholder='例)太郎'
                required={true} rows={1} value={lastName} type={"text"} onChange={inputLastName} className={classes.fieldHarf}
                />
                </div>
            </div>
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>メールアドレス<span className={classes.nessesary}>※必須</span></p>    
            <TextField
             multiline={true} variant="outlined" className={classes.field} margin='dense'
             required={true} rows={1} value={email} type={"email"} onChange={inputEmail} placeholder='例)example@aaa.com'
            />
            {isValidEmailFormat(email) ? (
                <p style={{display: "none"}}></p>
                ) : (
                <p style={{fontSize: 12, color: "blue"}}>※正しい表記でご入力ください</p>
            )} 
            </div>
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>電話番号<span className={classes.nessesary}>※必須(ハイフンあり)</span></p>    
            <TextField
             multiline={true} variant="outlined" className={classes.field} margin='dense' 
             required={true} rows={1} value={tel} type={"text"} onChange={inputTel} placeholder='例)000-0000-0000'
            />    
            </div>
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>郵便番号<span className={classes.nessesary}>※必須(ハイフンあり)</span></p>    
            <TextField
             multiline={true} variant="outlined" className={classes.field} margin='dense' 
             required={true} rows={1} value={addressNum} type={"text"} onChange={inputAddressNum} placeholder='例)123-4567'
            />    
            </div>
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>住所<span className={classes.nessesary}>※必須</span></p>    
            <TextField
             multiline={true} variant="outlined" className={classes.field} margin='dense' 
             required={true} rows={1} value={address} type={"text"} onChange={inputAddress} placeholder='例)山陽小野田市小野田1-11'
            />    
            </div>
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>パスワード<span className={classes.nessesary}>※必須</span></p>               
            <OutlinedInput 
                id="outlined-adornment-password"    
                placeholder='8文字以上(半角英数)'            
                className={classes.field}           
                margin='dense'  
                type={isRevealPassword ? "text" : "password"}
                value={password}
                onChange={inputPassword}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePassword}
                            edge="end"
                        >
                            {isRevealPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            /> 
            {password.length >= 8 ? (
                <p style={{display: "none"}}></p>
                ) : (
                <p style={{fontSize: 12, color: "blue"}}>※8文字以上でご登録ください</p>
            )} 
            </div>   
            <div className='module-spacer--extra' />                              
            <div className={classes.flexFlow}> 
            <p style={{fontSize: 14, color: "#707070"}}>パスワード(確認)<span className={classes.nessesary}>※必須</span></p>    
            <OutlinedInput 
                id="outlined-adornment-password"      
                placeholder='8文字以上(半角英数)'             
                className={classes.field}             
                margin='dense'
                type={isRevealConfirmPassword ? "text" : "password"}
                value={confirmPass}
                onChange={inputConfirmPass}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleConfirmPassword}
                            edge="end"
                        >
                            {isRevealConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            />     
            {confirmPass.length >= 8 ? (
                <p style={{display: "none"}}></p>
                ) : (
                <p style={{fontSize: 12, color: "blue"}}>※8文字以上でご登録ください</p>
            )} 
            {password === confirmPass || confirmPass === "" ? (
                <p style={{display: "none"}}></p>
                ) : (
                <p style={{fontSize: 12, color: "blue"}}>※パスワードが一致しません</p>
            )} 
            </div>         
            <div className={classes.space_small} />
            <div>
                <button 
                onClick={() => setCurrentStep(2)} 
                className={isValidRequiredInput(companyName, firstName, lastName, email, password, confirmPass, addressNum, address, tel) && password === confirmPass && password.length >= 8 && isValidEmailFormat(email) ? (classes.button) : (classes.buttonDisabled)}
                disabled={!isValidRequiredInput(companyName, firstName, lastName, email, password, confirmPass, addressNum, address, tel) && password === confirmPass && password.length >= 8 || !isValidEmailFormat(email)}
                >
                次へ
                </button>
            </div>
            </Paper>
        </div>
    )
}

export default BasicInfo;
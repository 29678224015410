import React, { useCallback, useState } from 'react';
import { Toolbar, Typography, Box, makeStyles } from '@material-ui/core';
import NotSignHeaderMenu from './NotSignHeaderMenu';
import MenuDrower from './MenuDrower';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Logo from '../../assets/img/logo.png';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: 270,
        [theme.breakpoints.down('sm')]: {          
          width: 200
        }
      },
      title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      toolbar: {
        display: 'flex', 
        justifyContent: 'space-between',        
        height: 70,
        width: "100%",
        margin: '0 auto',
        maxWidth: 1350,
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        position: "fixed",        
        width: "100%",
        backgroundColor: "#fff",
        borderBottom: "solid 2px #FF9D00",
        height: 70
      },
      searchLg: {
        display: "none",
        [theme.breakpoints.up('sm')]: {
          display: "block",
        }
      },
      searchSm: {
        display: "block",
        [theme.breakpoints.up('sm')]: {
          display: "none",
        }
      },
      menu: {
        marginTop: 30
      }
}))

const NotSignInHeader = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuDrawerToggle = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
        setMenuOpen(!menuOpen)
    }, [setMenuOpen, menuOpen]);

    return(
        <div className={classes.root}>
        <header color="inherit" className={classes.appBar} >
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" noWrap component="div" className={classes.logo} onClick={() => dispatch(push('/'))} >
                    <img src={Logo} alt="ロゴ" onClick={() => dispatch(push('/'))}/>
                </Typography>
                <Box display="flex" className={classes.menu}>
                    <Typography variant="h6" >
                        <NotSignHeaderMenu menuDrawerToggle={menuDrawerToggle}/>
                    </Typography>
                </Box>
            </Toolbar>
            <MenuDrower open={menuOpen} onClose={menuDrawerToggle} />
        </header>
        </div>
    )
}

export default NotSignInHeader;
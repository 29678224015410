import React, { useContext, useCallback, useState } from "react";
import { TextField, Paper } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import { multiStepContext } from "../../SteoContext";
import PopoverLabel from "../../UIkit/Popover";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const useStyle = makeStyles((theme) => ({
    card: {
        width: 600,
        padding: 30,
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        border: "solid 1px #707070", 
        [theme.breakpoints.down('sm')]: {            
            width: "90%",
            margin: "0 auto"
        },
    },
    title: {
        fontSize: 20,
        fontWeight: 400
    },
    nessesary: {
        color: "red",
        fontSize: 10
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
    field: {
        width: 400,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    fieldHarf: {
        [theme.breakpoints.down('sm')]: {
            width: 100
        }
    },
    topromotion: {
        fontSize: 12,
        color: "blue",
        cursor: "pointer",
        '&:hover': {
            opacity: 0.6
         },
    },
    flexFlow: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",
    },
    flex: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    caution: {
        border: "1px solid #333",
        borderRadius: 5,
        width: 400,
        padding: 10,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    space_big:{
        height: 50,
    },
    space_small: {
        height: 15,
    },
    phone_space: {        
        [theme.breakpoints.down('sm')]: {
            height: 100
        }
    }
}))


const AnyDetails = () => {
    const dispatch = useDispatch();
    const { setCurrentStep, 
            shiftDetails, setShiftDetails,
            saralyDetails, setSaralyDetails,
            partTimeDetails, setPartTimeDetails,
            welfareDetails, setWelfareDetails
        } = useContext(multiStepContext)

    const [saralyExe, setSaralyExe] = useState(null),
          [shiftExe, setShiftExe] = useState(null), 
          [timeExe, setTimeExe] = useState(null), 
          [welfareExe, setWelfareExe] = useState(null);

    const saralyHandleOpen = (e) => {
        setSaralyExe(e.currentTarget)
    }
    const shiftHandleOpen = (e) => {
        setShiftExe(e.currentTarget)
    }
    const timeHandleOpen = (e) => {
        setTimeExe(e.currentTarget)
    }
    const welfareHandleOpen = (e) => {
        setWelfareExe(e.currentTarget)
    }

    const saralyHandleClose = () => {
        setSaralyExe(null)
    }
    const timeHandleClose = () => {
        setTimeExe(null)
    }
    const shiftHanldeClose = () => {
        setShiftExe(null)
    }
    const welfareHandleClose = () => {
        setWelfareExe(null)
    }

  const saralyOpen = Boolean(saralyExe);
  const timeOpen = Boolean(timeExe);
  const shiftOpen = Boolean(shiftExe);
  const welfareOpen = Boolean(welfareExe);

  const saralyId = saralyOpen ? 'simple-popover' : undefined;
  const timeId = timeOpen ? 'simple-popover' : undefined;
  const shiftId = shiftOpen ? 'simple-popover' : undefined;
  const welfareId = welfareOpen ? 'simple-popover' : undefined;

    const inputSaralyDetails = useCallback((e) => {
        setSaralyDetails(e.target.value)
    }, [setSaralyDetails])
    const inputShiftDetails = useCallback((e) => {
        setShiftDetails(e.target.value)
    }, [setShiftDetails])
    const inputPartTimeDetails = useCallback((e) => {
        setPartTimeDetails(e.target.value)
    }, [setPartTimeDetails])
    const inputWelfareDetails = useCallback((e) => {
        setWelfareDetails(e.target.value)
    }, [setWelfareDetails])
    const classes = useStyle();
    return(
        <div className={classes.form}>
            <Paper elevation={0} className={classes.card}>
            <h2 className={classes.title}>店舗任意詳細情報</h2>
            <div className={classes.flexFlow}>            
            <div style={{display: "flex"}}>
            <p style={{fontSize: 14, color: "#707070"}}>給与(詳細説明)</p>  
            <PopoverLabel 
                    handleClick={saralyHandleOpen}
                    id={saralyId}
                    open={saralyOpen}
                    anchorEl={saralyExe}
                    handleClose={saralyHandleClose}
                    message={
                        <p>
                            時給やボーナス、交通費などに関する情報を詳しくご記入ください。<br/>
                            ・時給は上がっていくのか？<br />
                            ・研修期間は時給どのくらいなのか？<br />
                            ・毎月支給なのか、日払い、週払いなのか？<br/>
                            ・支給日はいつなのか？<br/>
                            ・土日祝日は時給が上がるのか？<br />
                            ・ボーナスはあるのか etc...<br />
                            <span style={{color: "red", fontSize: 12}}>※改行部分は店舗詳細画面にもそのまま反映されます。</span>
                        </p>
                    }
            />     
            </div>
                <TextField
                    multiline={true}  variant="outlined" margin='dense' className={classes.field}
                    rows={6} value={saralyDetails} type="text" onChange={inputSaralyDetails} 
                    placeholder='給与・時給等の詳しい説明を記入してください。'
                />
            </div>
            <div className='module-spacer--extra' />           
            <div className={classes.flexFlow}>
            <div style={{display: "flex"}}>
            <p style={{fontSize: 14, color: "#707070"}}>シフト・勤務時間(詳細説明)</p> 
            <PopoverLabel 
                handleClick={shiftHandleOpen}
                id={shiftId}
                open={shiftOpen}
                anchorEl={shiftExe}
                handleClose={shiftHanldeClose}
                message={
                    <p>
                        希望する出勤日数や出勤時間、シフトに関する情報などを詳しくご記入ください。<br />
                        ・シフトをどういった形で決めるのか？<br />
                        ・テスト期間はお休みをもらえるのか？<br />
                        ・週に何日出勤しないといけないのか？<br />
                        ・短い時間でも応募可能なのか？ etc...<br />
                        <span style={{color: "red", fontSize: 12}}>※改行部分は店舗詳細画面にもそのまま反映されます。</span>
                    </p>
                }
            />     
            </div>
            <TextField
                multiline={true} variant="outlined" className={classes.field} margin='dense'
                required={true} rows={10} value={shiftDetails} type={"text"} onChange={inputShiftDetails} 
                placeholder='シフト・勤務時間等の詳しい説明を記入してください。'
            />    
            </div>   
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}>
            <div style={{display: "flex"}}>
            <p style={{fontSize: 14, color: "#707070"}}>勤務期間(詳細説明)</p> 
            <PopoverLabel 
                handleClick={timeHandleOpen}
                id={timeId}
                open={timeOpen}
                anchorEl={timeExe}
                handleClose={timeHandleClose}
                message={
                    <p>
                        応募するバイト期間をご記入ください<br />
                        1ヶ月以内→短期<br />
                        1ヶ月以上→長期<br />
                        <strong>記入例</strong><br />
                        短期(1ヶ月以内)<br />
                        長期(1ヶ月以上)<br />
                        単発<br />
                        夏休み限定/春休み限定<br />
                        <span style={{color: "red", fontSize: 12}}>※短期と長期で採用後にお支払いいただく料金が異なりますので、ご注意ください</span><br />
                        <span className={classes.topromotion} onClick={() => dispatch(push('/promotion'))}>詳しくはこちら</span>
                    </p>
                }
            />     
            </div>
            <TextField
                multiline={true} variant="outlined" className={classes.field} margin='dense'
                required={true} rows={6} value={partTimeDetails} type={"text"} onChange={inputPartTimeDetails} placeholder='長期（3ヶ月以上）'
            />
            </div>
            <div className={classes.flexFlow}>
            <div style={{display: "flex"}}>
            <p style={{fontSize: 14, color: "#707070"}}>待遇・福利厚生(詳細説明)</p> 
            <PopoverLabel 
                handleClick={welfareHandleOpen}
                id={welfareId}
                open={welfareOpen}
                anchorEl={welfareExe}
                handleClose={welfareHandleClose}
                message={
                    <p>
                        福利厚生・待遇、服装等の説明を記入してください<br />
                        <strong>記入例</strong><br />
                        ・まかない無料<br />
                        ・交通費支給<br />
                        ・有給取得可能<br />
                        ・制服貸与<br />
                        ・昇給あり<br />
                        <span style={{color: "red", fontSize: 12}}>※改行部分は店舗詳細画面にもそのまま反映されます。</span>
                    </p>
                }
            />    
            </div>
            <TextField
                multiline={true} variant="outlined" className={classes.field} margin='dense'
                required={true} rows={10} value={welfareDetails} type={"text"} onChange={inputWelfareDetails} 
                placeholder='福利厚生・待遇、服装等の説明を記入してください'
            />
            </div>
            <div className='module-spacer--extra' /> 
            <div className='module-spacer--extra' /> 
            <div className={classes.caution}>
                <h2>注意点</h2>
                <p style={{fontSize: 14, textAlign: "left"}}>
                    上記の各詳細内容に関してはテンプレート分があらかじめ入力してありますが、このままではご使用いただけない部分がございます。
                    必ず内容をご確認の上、修正が必要な部分の修正を行なってから使用いただくようお願い致します。
                </p>
            </div>
            <div className='module-spacer--extra' /> 
            <div className='module-spacer--extra' /> 
            <div>
                <button onClick={() => setCurrentStep(3)} className={classes.button} >戻る</button>
                <button onClick={() => setCurrentStep(5)} className={classes.button} >確認</button>
            </div>
            </Paper>
        </div>
    )
}

export default AnyDetails;
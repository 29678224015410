import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexFlow: 'row wrap',        
        width: 500,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            width: 300,
            flexFlow: "column",
            alignItems: "flex-start"
        }
    },
    label: {
        marginLeft: 0,
        marginRight: 'auto'
    },
    value: {
        fontWeight: 600,        
        marginRight: 0        
    }
}))

const TextDetailLong = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.row}>
            <div className={classes.label}>{props.label}</div>
            <div className={classes.value}>{props.value}</div>
        </div>
    );
};

export default TextDetailLong;
import React, { useContext, useCallback, useState } from "react";
import { TextField, Paper } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import { multiStepContext } from "../../SteoContext";
import SelectBox from "../../UIkit/SelectBox";
import SetJobTime from "../../UIkit/SerJobTime";
import SetContent from "../../UIkit/setContent";
import { isValidRequiredInput } from '../../function/common'
import PopoverLabel from "../../UIkit/Popover";
import ContentsExe from "../../UIkit/ContentsExe";
import TimesExe from "../../UIkit/TimesExe";
import GakutikaRadio from "../../UIkit/GakutikaRadio";
import RadioForm from "../../UIkit/Radio";
import MultiSelect from "../../UIkit/MultiSelect";
import PharmacyRadio from "../../UIkit/PhamacyRadio";

const useStyle = makeStyles((theme) => ({
    card: {
        width: 600,
        padding: 30,
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        border: "solid 1px #707070", 
        [theme.breakpoints.down('sm')]: {            
            width: "90%",               
            margin: "0 auto"
        },
    },
    title: {
        fontSize: 20,
        fontWeight: 400
    },
    nessesary: {
        color: "red",
        fontSize: 10
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
    buttonDisabled: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#ccc",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
    },
    exeSpace: {
        fontSize: 14,
    },
    field: {
        width: 400,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    fieldHarf: {
        width: 100,
        margin: 10
    },
    saralyfield: {
        width: 400,
        borderRadius: 5,
        padding: 5,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    flexFlow: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",
    },
    favorite: {
        border: "3px solid #fca505",      
        width: 420,
        [theme.breakpoints.down('sm')] : {
            width: "100%"
        }
    },
    favoriteText: {
        textAlign: "left",
        fontWeight: "bold",
        backgroundColor: "#fca505",
        padding: "5px 10px",
        color: "#fff"
    },
    phone_space: {        
        [theme.breakpoints.down('sm')]: {
            height: 100
        }
    }
}))

const categories = [
    {id: "eat", name: "飲食"}, //赤
    {id: "leisure", name: "レジャー"}, //水色
    {id: "customer", name: "接客"}, //黄色
    {id: "sale", name: "販売"}, //ピンク
    {id: "education", name: "教育"}, //青
    {id: "delivery", name: "配送"}, //黄緑
    {id: "easy_work", name: "軽作業"}, //紫
    {id: "it", name: "IT"}, //黒
    {id: "medical", name: "医療・福祉"}, //薄いグレー
    {id: "specialty", name: "専門"}, //
    {id: "office", name: "事務"}, //緑
    {id: "ahter", name: "その他"}, //濃いグレー
]

const partTimes = [
    {id: "1", name: "1"},
    {id: "2", name: "2"},
    {id: "3", name: "3"}, 
    {id: "4", name: "4"}, 
    {id: "5", name: "5"}, 
    {id: "6", name: "6"}, 
    {id: "7", name: "7"},
    {id: "8", name: "8"}, 
]

const attendanceshifts = [
    '週1日から',
    '週2日から',
    '週3日から',
    '週4日から',
];

const MandatoryInfo = () => {
    const { 
        setCurrentStep,  
        attendanceshift, setAttendanceshift,
        gakutika, setGakutika,
        students, setStudents,
        studentspast, setStudentspast,
        pharmacy, setPharmacy,
        introduce, setIntroduce,
        category, setCategory,
        saraly, setSaraly,
        saralyNight, setSaralyNight,
        times, setTimes,
        contents, setContents,
    } = useContext(multiStepContext)
    const attendanceshiftChange = (event) => {
        setAttendanceshift(event.target.value)
    }  
    const [introExe, setIntroExe] = useState(null),
          [timesExe, setTimesExe] = useState(null),
          [contentsExe, setContentsExe] = useState(null),
          [gakutikaExe, setGakutikaExe] = useState(null),
          [studentsExe, setStudentsExe] = useState(null),
          [studentspastExe, setStudentspastExe] = useState(null),
          [pharmacyExe, setPharmacyExe] = useState(null);

    const introHandleOpen = (e) => {
        setIntroExe(e.currentTarget)
    }
    const timesHandleOpen = (e) => {
        setTimesExe(e.currentTarget)
    }
    const contentsHandleOpen = (e) => {
        setContentsExe(e.currentTarget)
    }
    const gakutikaHandleOpen = (e) => {
        setGakutikaExe(e.currentTarget)
    }
    const studentsHandleOpen = (e) => {
        setStudentsExe(e.currentTarget)
    }
    const studentspastHandleOpen = (e) => {
        setStudentspastExe(e.currentTarget)
    }
    const pharmacyHandleOpen = (e) => {
        setPharmacyExe(e.currentTarget)
    }

    const introHandleClose = () => {
        setIntroExe(null)
    }
    const timesHanldeClose = () => {
        setTimesExe(null)
    }
    const contentsHandleClose = () => {
        setContentsExe(null)
    }
    const gakutikaHandleClose = () => {
        setGakutikaExe(null)
    }
    const studentsHandleClose = () => {
        setStudentsExe(null)
    }
    const studentspastHandleClose = () => {
        setStudentspastExe(null)
    }
    const pharmacyHandleClose = () => {
        setPharmacyExe(null)
    }

    const introOpen = Boolean(introExe);
    const timesOpen = Boolean(timesExe);
    const contentsOpen = Boolean(contentsExe);
    const gakutikaOpen = Boolean(gakutikaExe);
    const studentsOpen = Boolean(studentsExe);
    const studentspastOpen = Boolean(studentspastExe);
    const pharmacyOpen = Boolean(pharmacyExe);

    const introId = introOpen ? 'simple-popover' : undefined;
    const timesId = timesOpen ? 'simple-popover' : undefined;
    const contentsId = contentsOpen ? 'simple-popover' : undefined;
    const gakutikaId = gakutikaOpen ? 'simple-popover' : undefined;
    const studentsId = studentsOpen ? 'simple-popover' : undefined;
    const studentspastId = studentspastOpen ? 'simple-popover' : undefined;
    const pharmacyId = pharmacyOpen ? 'simple-popover' : undefined;

    const classes = useStyle();

    const inputIntroduce = useCallback((e) => {
        setIntroduce(e.target.value)
    }, [setIntroduce])  
    const inputSaraly = useCallback((e) => {
        setSaraly(e.target.value)
    }, [setSaraly])
    const inputSaralyNight = useCallback((e) => {
        setSaralyNight(e.target.value)
    }, [setSaralyNight])
    const inputGakutika = useCallback((e) => {
        setGakutika(e.target.value)
    }, [setGakutika])
    const inputStudent = useCallback((e) => {
        setStudents(e.target.value)
    }, [setStudents])
    const inputStudentpast = useCallback((e) => {
        setStudentspast(e.target.value)
    }, [setStudentspast])
    const inputPhamacy = useCallback((e) => {
        setPharmacy(e.target.value)
    }, [setPharmacy])

    return(
        <div className={classes.form}>
            <Paper elevation={0} className={classes.card}>
            <h2 className={classes.title}>店舗必須情報</h2>
            <div className={classes.flexFlow}>
                <p style={{fontSize: 14, color: "#707070"}}>職種<span className={classes.nessesary}>※必須</span></p>   
                <SelectBox 
                    options={categories}
                    required={false}
                    value={category}
                    select={setCategory}
                    className={classes.field}
                />          
            </div>        
            <div className='module-spacer--small' /> 
            <div className={classes.flexFlow}>
            <div style={{display: "flex"}}>
            {introduce.length <= 20 ? (
                <p style={{fontSize: 14, color: "#707070"}}>一言紹介(文字数{introduce.length}字)<span className={classes.nessesary}>※必須</span></p>
            ) : (
                <p style={{fontSize: 14, color: "red"}}>一言紹介(文字数{introduce.length}字*制限を超えています)</p>
            )}          
            <PopoverLabel 
                    handleClick={introHandleOpen}
                    id={introId}
                    open={introOpen}
                    anchorEl={introExe}
                    handleClose={introHandleClose}
                    message={
                        <p>学生さんに一番目につくお店の紹介文章です。<br />お店の特徴が分かりやすい文章を20字以内でお願いします。</p>
                    }
            />     
            </div>
            <TextField
             multiline={true} variant="outlined" className={classes.field} margin='dense'
             required={true} rows={1} value={introduce} type={"text"} onChange={inputIntroduce} placeholder='20字以内'
            />              
            </div>  
            <div className='module-spacer--small' /> 
            <div className={classes.flexFlow}>
                <p style={{fontSize: 14, color: "#707070"}}>時給<span className={classes.nessesary}>※必須(数字のみ記入)</span>
                </p>  
                <input
                    variant="outlined" className={classes.saralyfield} margin='dense' step={10} min={850}
                    required={true} rows={1} value={saraly} type="number" onChange={inputSaraly} placeholder='山口県最低賃金857円'
                />
                {saraly < 857 && saraly !== "" ? (
                    <p style={{fontSize: 12, color: "blue"}}>※最低賃金を下回っています</p>
                ) : (
                    <p style={{display: "none"}}></p>
                )}       
            </div>                             
            <div className='module-spacer--small' />   
            <div className={classes.flexFlow}>
                <p style={{fontSize: 14, color: "#707070"}}>時給(22:00以降)<span className={classes.nessesary}>※深夜帯で募集される方はご入力ください</span></p>  
                <input
                    variant="outlined" className={classes.saralyfield} margin='dense' id="standard-number" step={10} min={saraly * 1.25}
                    required={true} rows={1} value={saralyNight} type="number" onChange={inputSaralyNight} placeholder='1000'
                />                  
                {saralyNight < saraly*1.25 && saralyNight !== "" ? (
                    <p style={{fontSize: 12, color: "blue"}}>※深夜帯の時給は通常の1.25倍以上で入力して下さい</p>
                ) : (
                    <p style={{display: "none"}}></p>
                )}          
            </div>                               
            <div className='module-spacer--small' /> 
            <div className={classes.flexFlow}>
            <div style={{display: "flex"}}>
            <p style={{fontSize: 14, color: "#707070"}}>勤務時間<span className={classes.nessesary}>※必須(複数記入可)</span></p>  
            <PopoverLabel 
                    handleClick={timesHandleOpen}
                    id={timesId}
                    open={timesOpen}
                    anchorEl={timesExe}
                    handleClose={timesHanldeClose}
                    message={
                        <div>
                        <p>
                            店舗様が希望する出勤時間をご記入ください。<br />
                            複数記入していただいて構いません。<br />
                            <span style={{color: "red"}}>※ご記入いただいたら必ず<strong>項目を追加ボタン</strong>をクリックして下さい。</span><br />
                            <strong>記入例</strong></p>
                        <TimesExe times={times} setTimes={setTimes} />  
                        </div>
                    }
            />     
            </div>
                <SetJobTime times={times} setTimes={setTimes} />
            </div>            
            <div className='module-spacer--small' /> 
            <div className={classes.flexFlow}>
            <div style={{display: "flex"}}>
            <p style={{fontSize: 14, color: "#707070"}}>仕事内容<span className={classes.nessesary}>※必須(複数記入可)</span></p>
            <PopoverLabel 
                    handleClick={contentsHandleOpen}
                    id={contentsId}
                    open={contentsOpen}
                    anchorEl={contentsExe}
                    handleClose={contentsHandleClose}
                    message={
                        <div>
                        <p>
                            募集する仕事の内容をご記入ください。<br />
                            複数記入していただいて構いません。<br />
                            <strong>仕事内容欄</strong>には大まかな仕事の内容、<br />
                            <strong>詳細欄</strong>にはその仕事内容についてできるだけ詳しく説明して下さい。<br />
                            <span style={{color: "red"}}>※ご記入いただいたら必ず<strong>項目を追加ボタン</strong>をクリックして下さい。</span><br />
                            <strong>記入例</strong>
                        </p>
                        <ContentsExe contents={contents} setContents={setContents} />  
                        </div>
                    }
            />     
            </div>
            <SetContent contents={contents} setContents={setContents} />          
            </div>
            <div className='module-spacer--small' /> 
            <div className={classes.flexFlow}>
                <p style={{fontSize: 14, color: "#707070"}}>週あたりの出勤日数<span className={classes.nessesary}>※必須(数字のみ記入)</span></p>  
                <MultiSelect value={attendanceshift} onChange={attendanceshiftChange} options={attendanceshifts} label={""} />            
            </div>          
            <div className='module-spacer--small' />  
            <Paper className={classes.favorite}>
            <p className={classes.favoriteText}>理科大生<span style={{fontSize: 18}}>必見</span>項目</p>
            <div className={classes.flexFlow} style={{padding: 10}}>
            <div style={{display: "flex"}}>
                <p style={{fontSize: 14, color: "#707070"}}>理科大生在籍<span className={classes.nessesary}>※必須</span></p>   
                <PopoverLabel 
                    handleClick={studentsHandleOpen}
                    id={studentsId}
                    open={studentsOpen}
                    anchorEl={studentsExe}
                    handleClose={studentsHandleClose}
                    message={
                        <ul>
                            <li>今現役の理科大生が働いているかを教えて下さい。</li>
                        </ul>
                    }
                />     
                </div>
                <RadioForm value={students} onChange={inputStudent} select={setStudents} />
            </div>  
            <div className={classes.flexFlow} style={{padding: 10}}>
            <div style={{display: "flex"}}>
                <p style={{fontSize: 14, color: "#707070"}}>過去に理科大生在籍<span className={classes.nessesary}>※必須</span></p>   
                <PopoverLabel 
                    handleClick={studentspastHandleOpen}
                    id={studentspastId}
                    open={studentspastOpen}
                    anchorEl={studentspastExe}
                    handleClose={studentspastHandleClose}
                    message={
                        <ul>
                            <li>過去に理科大生在籍されていたかどうか教えて下さい。</li>
                        </ul>
                    }
                />     
                </div>
                <RadioForm value={studentspast} onChange={inputStudentpast} select={setStudentspast} />
            </div>  
            <div className={classes.flexFlow} style={{padding: 10}}>
                <div style={{display: "flex"}}>
                <p style={{fontSize: 14, color: "#707070"}}>ガクチカ<span className={classes.nessesary}>※必須</span></p>   
                <PopoverLabel 
                    handleClick={gakutikaHandleOpen}
                    id={gakutikaId}
                    open={gakutikaOpen}
                    anchorEl={gakutikaExe}
                    handleClose={gakutikaHandleClose}
                    message={
                        <p>
                            ガクチカとは？<br />
                            学生時代頑張ったこと、力を入れたことの略称です。<br />
                            ガクチカは就職活動において必ず聞かれる内容であり、重要な項目の一つです。<br />
                            当サイトを通して採用された学生さんに就職活動で話せるような経験をさせていただきたいと思っています。<br />
                            <strong>例えば...</strong><br />
                            お店のPOP作成(求人募集や商品紹介など)<br />
                            業務効率化のための提案<br />
                            新商品の提案 etc...<br />
                        </p>
                    }
                />     
                </div>
                <GakutikaRadio value={gakutika} onChange={inputGakutika} select={setGakutika} />
            </div>                  
            <div className={classes.flexFlow} style={{padding: 10}}>
                <div style={{display: "flex"}}>
                <p style={{fontSize: 14, color: "#707070"}}>薬学部歓迎店舗<span className={classes.nessesary}>※必須</span></p>   
                <PopoverLabel 
                    handleClick={pharmacyHandleOpen}
                    id={pharmacyId}
                    open={pharmacyOpen}
                    anchorEl={pharmacyExe}
                    handleClose={pharmacyHandleClose}
                    message={
                        <p>
                            薬学部は工学部に比べてアルバイトに使う時間が少なく なってしまいます。<br />
                            そこで薬学部生でも安心して働ける よう協力してくださる店舗を募集しています。<br />
                                条件としては...<br />
                            ・18時or19時からの勤務可能<br />
                            ・週1.2日からOK<br />
                            ・土日のみOK<br />
                            ・テスト前考慮<br />
                            ・1日2hからOK 上記の条件を満たす店舗様は薬学部歓迎の項目にチェックをお願いします。
                        </p>
                    }
                />     
                </div>
                <PharmacyRadio value={pharmacy} onChange={inputPhamacy} select={setPharmacy} />
            </div>                  
            </Paper>   
            <div className={classes.space_small} />
            <div>
            <div className="module-spacer--small" />
                <button onClick={() => setCurrentStep(1)} className={classes.button} >戻る</button>
                <button 
                    onClick={() => setCurrentStep(3)} 
                    className={isValidRequiredInput(introduce, category, saraly, students, studentspast) && introduce.length <= 20 && times.length > 0 && attendanceshift.length > 0 && contents.length > 0 && (saralyNight >= saraly*1.25 || saralyNight === "") && saraly >= 857 ? (classes.button) : (classes.buttonDisabled)}
                    disabled={!isValidRequiredInput(introduce, category, saraly) || introduce.length >= 20 || times.length === 0 || contents.length === 0 || attendanceshift.length === 0 || (saralyNight < saraly*1.25  && saralyNight !== "") || saraly < 857}
                >
                次へ
                </button>
            </div>
            </Paper>
        </div>
    )
}

export default MandatoryInfo;
import React, { useCallback, useState } from "react";
import { Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, IconButton, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SelectBox from "./SelectBox";

const hour = [
    {id: "nine", name: "09"},
    {id: "nine", name: "17"},
]
const minutes = [
    {id: "zero", name: "00"},
    {id: "one", name: "10"},
    {id: "two", name: "20"},
    {id: "three", name: "30"},
    {id: "four", name: "40"},
    {id: "five", name: "50"},
]

const useStyles = makeStyles((theme) => ({
    root: {
        width: 400,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    checkIcon: {
        float: "right"
    },
    iconCell: {
        height: 50,
        width: 50
    },
    content: {
        display: "flex",
        flexFlow: "column",
    },
    field: {
        width: 50
    },
    flex: {
        display: "flex",
        alignItems: "center"
    }
}))
const TimesExe = (props) => {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.flex}>
                    <h2>開始時刻<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                    <TextField 
                        required={false} value={"09"} className={classes.field} variant="outlined" margin="dense"
                    />
                    <span style={{marginRight: 5, marginLeft: 5}}>時</span>
                    <TextField 
                        required={false} value={"00"} className={classes.field} variant="outlined" margin="dense"
                    />
                    <span style={{marginRight: 5, marginLeft: 5}}>分</span>
                </div>
                <div className={classes.flex}>
                    <h2>終了時刻<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                    <TextField 
                        required={false} value={"17"} className={classes.field} variant="outlined" margin="dense"
                    />
                    <span style={{marginRight: 5, marginLeft: 5}}>時</span>
                    <TextField 
                        required={false} value={"00"} className={classes.field} variant="outlined" margin="dense"
                    />
                    <span style={{marginRight: 5, marginLeft: 5}}>分</span>
                </div>
                <div className={classes.flex}>
                <h2>時給<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                    <TextField
                        variant="outlined" className={classes.saralyfield} margin='dense' step={10} min={850}
                        required={true} rows={1} value={"900"} type="number" placeholder='山口県最低賃金857円'
                    />  
                </div>
            </div>
            <div className={classes.checkIcon}>
                <div style={{display: "flex"}}>
                <p>項目を追加</p><CheckCircleIcon />
                </div>
            </div>
        </div>
    )
}

export default TimesExe;
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import NoImage from "../assets/img/no_image.png"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    imageList: {
        width: 500,
        height: 300,
        [theme.breakpoints.down('sm')]: {
            width: 300,
            height: 150
        }
    },
}));

const ImageView = (props) => {
    const images = props.images;
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <ImageList rowHeight={180} className={classes.imageList}>
                {images.length === 0 ? (
                    <div>
                        <img src={NoImage} alt="No Image" />
                    </div> 
                ) : (
                    images.map((item) => (
                        <img className={classes.image}  src={item.path} />
                    ))
                )}
            </ImageList>
        </div>
    )
}

export default ImageView;
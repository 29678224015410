import { Paper, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useCallback } from "react";
import HTMLReactParser from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 400,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    checkIcon: {
        display: "flex",
        padding: "5px 10px",
        color: "#fff",
        backgroundColor: "red",
        border: "none",
        borderRadius: 5,
        '&:hover': {
            opacity: 0.6
        },
    },
    cellDetail: {
        width: 150,
    },
    cellContent: {
        width: 75
    },
    iconCell: {
        height: 50,
        width: 50
    },
    content: {
        display: "flex",
        flexFlow: "column",
    },
    flex: {
        display: "flex",
        alignItems: "center"
    }
}))

const returnCodeToBr = (text) => {
    if(text === "") {
        return text
    } else {
        return HTMLReactParser(text.replace(/\r?\n/g, '<br>'))
    }
}

const SetContent = (props) => {
    const classes = useStyles();
    const [index, setIndex] = useState(0)
    const [content, setContent] = useState("");
    const [detail, setDetail] = useState("");

    const inputContent = useCallback((event) => {
        setContent(event.target.value)
    }, [setContent])
    const inputDetail = useCallback((event) => {
        setDetail(event.target.value)
    }, [setDetail]);

    const addContent = (index, content, detail) => {
        if(content === "" || detail === "") {
            return false
        } else {
            if(index === props.contents.length) {
                props.setContents(preveState => [...preveState, {content: content, detail: detail}])
                setIndex(index + 1)
                setContent("")
                setDetail("")
            } else {
                const newContents = props.contents
                newContents[index] = {content: content, detail: detail}
                props.setContents(newContents)
                setIndex(newContents.length)
                setContent("")
                setDetail("")
            }
        } 
    }

    const editContent = (index, content, detail) => {
        setIndex(index)
        setContent(content)
        setDetail(detail)
    };

    useEffect(() => {
        setIndex(props.contents.length)
    }, [props.contents.length])
    const deleteContent = (deleteIndex) => {
        const newContents = props.contents.filter((item, i) => i !== deleteIndex);
        props.setContents(newContents)
    }
    return(
        <div className={classes.root}>
                {props.contents.length > 0 && (
                    props.contents.map((item, i) => (
                        <Paper style={{padding: 10}} className={classes.content}>
                            <h2 style={{textAlign: "left"}}>{"項目" +( i + 1)}</h2>
                            <div className={classes.content}>
                                <ul style={{textAlign: "left"}}>
                                    <li>{item.content}</li>
                                </ul>
                                <ul style={{textAlign: "left"}}>
                                    <li>{returnCodeToBr(item.detail)}</li>
                                </ul>
                            </div>
                            <div className={classes.flex}>
                                <div className={classes.flex}>
                                    <p>編集</p>
                                    <IconButton className={classes.iconCell} onClick={() => editContent(i, item.content, item.detail)}>
                                        <EditIcon />
                                    </IconButton>
                                </div>
                                <div className={classes.flex}>
                                    <p>消去</p>
                                    <IconButton className={classes.iconCell} onClick={() => deleteContent(i)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>
                    ))
                )}
                <div className={classes.content}>
                    <TextField 
                        fullWidth={false} label="仕事内容" multiline={false} required={true}
                        onChange={inputContent} rows={1} value={content} type="text" margin="dense"
                    />
                    <TextField 
                        fullWidth={false} label="詳細" multiline={true} required={true}
                        onChange={inputDetail} rows={5} value={detail} type="text" margin="dense"
                    />
                </div>
                <button className={classes.checkIcon} onClick={() => addContent(index, content, detail)}>
                    <p>登録</p><CheckCircleIcon />
                </button>
        </div>
    )
}

export default SetContent;
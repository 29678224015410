import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyMailDetails } from "../redux/company/selectors";
import TextDetailLong from "../UIkit/TextDetailsLong";
import { auth, db } from "../firebase";
import SideHeader from "../components/header/SideHeader";
import { Paper, TextField, Button } from "@material-ui/core";
import { push } from "connected-react-router";
import { init, send } from "emailjs-com";
import InteviewContactItem from "../components/companys/InteviewContactItem";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: "#EBEBEB",
        height: "100vh",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "auto",
            margin: "auto"            
        }       
    },    
    application: {
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    detail : {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }        
    },
    paper: {
        width: 600,        
        height:"auto",
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "auto",    
            marginTop: 80,        
        }        
    },
    content: {
        margin: "0 auto",
        [theme.breakpoints.down('sm')]: {                     
          width: "100%",
          margin: "0 auto",
       },
    },
    contact: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",    
        [theme.breakpoints.down('sm')]: {
            padding: 10
        }
    },
    subject: {
        color: "#e4002b",
        fontSize: "23px",        
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    table: {
        border: "1px solid #ddd",   
        width: 700,
        [theme.breakpoints.down('sm')]: {
            width: 300,
            margin: "0 auto"
        },            
    },
    tr: {
        borderBottom: "1px solid #ddd",   
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexFlow: "column",
            width: 300
        },                     
    },
    th: {
        fontSize: 15,
        backgroundColor: "#f2f2f2",
        borderRight: "1px solid #ddd",        
        padding: "10px 20px",
        textAlign: "left",               
        verticalAlign: "top",
        minWidth: 200,
        [theme.breakpoints.down('sm')]: {
            width: 300
        },                          
    },  
    td: {                                                    
        padding: "10px 20px",
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            width: 300,
            flexFlow: "column",
            alignItems: "flex-start",
            marginLeft: 0
        },                               
    },
    nessesay: {
        backgroundColor: "#e4002b",
        color: "#fff",
        width: "max-content",
        padding: "3px 15px",
        marginTop: 20,         
    },
    field: {
        width: 500, 
        [theme.breakpoints.down('sm')]: {
            width: 250
        },           
    },
    fieldName: {
        width: 160,
    },      
    flex: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: 200
        },     
    },  
    margin: {
        marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
}))

const ApplicationDetailInteview = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state)
    const path = selector.router.location.pathname
    const id = path.split('/inteviewcontact/') [1]
    const inteviewcontacts = getCompanyMailDetails(selector);
    const [application, setApplication] = useState(null);
    const [company, setCompany] = useState(null);
    const [contents, setContents] = useState("");

    const inputContents = useCallback((e) => {
        setContents(e.target.value)
    }, [setContents])

    useEffect(() => {
        const user = auth.currentUser;
        const companyid = user.uid
        db.collection('companys').doc(companyid)
            .collection('maildetailbox')
            .doc(id)
            .get()
        .then( doc => {
            const data = doc.data();
            setApplication(data)
        })
    }, [id]);
    useEffect(() => {
        const user = auth.currentUser;
        const companyid = user.uid
        db.collection('companys').doc(companyid).get()
            .then(doc => {
                const data = doc.data()
                setCompany(data)
            })
    }, [])

    const datetimeToString = (dt) => {
        return dt.getFullYear() + '-'
            + ('00' + (dt.getMonth()+1)).slice(-2) + '-'
            + ('00' + dt.getDate()).slice(-2) + ' '
            + ('00' + dt.getHours()).slice(-2) + ':'
            + ('00' + dt.getMinutes()).slice(-2) + ':'
            + ('00' + dt.getSeconds()).slice(-2)
    };    

    const removeApplicationUser = (id) => {
        const companyid = auth.currentUser.uid
        return db.collection('companys').doc(companyid)
                 .collection('maildetailbox').doc(id)
                 .delete()
    }
    const sendRecurument = () => {
        const userID = process.env.REACT_APP_EMAIL_JS_USER_ID;
        const serviceID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
        const templateID = process.env.REACT_APP_EMAIL_JS_CONTACT_TEMPLATE_ID;

        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(userID);
            const template_param = {
                firstName: application.from_firstName,
                lastName: application.from_lastName,
                companyName: company.companyName,
                recurument: "採用",
            }

            send(serviceID, templateID, template_param)
                .then(() => {
                    dispatch(push('/inteviewcontact'))
                    removeApplicationUser(application.maildetailboxId)
                }).catch(() => {
                    alert('送信に失敗しました')
                })
        }
    }
    const sendNotRecurument = () => {
        const userID = process.env.REACT_APP_EMAIL_JS_USER_ID;
        const serviceID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
        const templateID = process.env.REACT_APP_EMAIL_JS_CONTACT_TEMPLATE_ID;

        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(userID);
            const template_param = {
                firstName: application.from_firstName,
                lastName: application.from_lastName,
                userEmail: application.email,
                companyName: company.companyName,
                recurument: "不採用",
            }

            send(serviceID, templateID, template_param)
                .then(() => {
                    dispatch(push('/inteviewcontact'))
                    removeApplicationUser(application.maildetailboxId)
                }).catch(() => {
                    alert('送信に失敗しました')
                })
        }
    }
    const sendMail = () => {
        const userID = process.env.REACT_APP_EMAIL_JS_USER_ID;
        const serviceID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
        const templateID = process.env.REACT_APP_EMAIL_JS_CONTACT_TEMPLATE_ID;

        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(userID);
            const template_param = {
                firstName: application.from_firstName,
                lastName: application.from_lastName,
                userEmail: application.email,
                companyName: company.companyName,
                contents: contents,
            }

            send(serviceID, templateID, template_param)
                .then(() => {
                    dispatch(push('/confirmapplications'))
                }).catch(() => {
                    alert('送信に失敗しました')
                })
        }
    }
    return(
        <div className={classes.root}>
            <SideHeader />            
            <Paper elevation={0} className={classes.application}>
                {inteviewcontacts.length > 0 && (
                    inteviewcontacts.map((inteviewcontact) => <InteviewContactItem inteviewcontact={inteviewcontact} key={inteviewcontact.maildetailboxId}/>)
                )}
            </Paper>            
            <main className={classes.content}>
            {application && (
                <div className={classes.detail}>
                    <Paper className={classes.paper}>                
                        <TextDetailLong label={"応募日時"} value={datetimeToString(application.send_at.toDate())} />
                        <TextDetailLong label={"氏名"} value={application.from_firstName + application.from_lastName} />            
                        <TextDetailLong label={"郵便番号"} value={"〒" + application.addressNum} />
                        <TextDetailLong label={"住所"} value={application.address + application.housenumber} />
                        <TextDetailLong label={"学科・学年"} value={application.department + "・" + application.grade} />
                        <TextDetailLong label={"年齢"} value={application.age + "歳"} />                   
                        <Button className={classes.button} variant="contained" color="secondary" onClick={() => sendRecurument()}>採用</Button>                  
                        <Button className={classes.button} variant="contained" color="secondary" onClick={() => sendNotRecurument()}>不採用</Button>                  
                    </Paper>
                </div>
            )}            
            <Paper className={classes.contact}>
                <table className={classes.table}>
                    <tr className={classes.tr}>
                        <th className={classes.th}>応募者氏名</th>
                        <td className={classes.td}>
                            <div className={classes.flex}>
                                {application && (
                                    <p>{application.from_firstName + application.from_lastName}</p>
                                )}                            
                            </div>                     
                        </td>
                    </tr>        
                    <tr className={classes.tr}> 
                        <th className={classes.th}>応募者へのメッセージ<br /><span className={classes.nessesay}>必須</span></th>
                        <td className={classes.td}>
                            <TextField variant="outlined" type="text" rows={8} multiline={true} placeholder="お問い合わせ内容を入力してください" className={classes.field} value={contents} onChange={inputContents} />
                        </td>
                    </tr>
                </table>     
            </Paper>
            <div className="module-spacer--small" />
            <button className={classes.button} onClick={() => sendMail()}>送信</button>
            </main>
        </div>
    )
}

export default ApplicationDetailInteview;
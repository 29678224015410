import React, { useContext, useEffect, useState } from "react";
import { multiStepContext } from "../../SteoContext";
import { makeStyles } from "@material-ui/core";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import HTMLReactParser from 'html-react-parser';
import { signUp } from "../../redux/company/operation";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Promis from "../../assets/pdf/promis.pdf";
import ImageView from "../../UIkit/ImageView";

const returnCodeToBr = (text) => {
    if(text === "") {
        return text
    } else {
        return HTMLReactParser(text.replace(/\r?\n/g, '<br>'))
    }
}

const useStyle = makeStyles((theme) => ({
    root: {
        margin: "0 auto",
        [theme.breakpoints.down('sm')]: {
        width: "100%",      
        height: "auto",
        display: "block"        
        },
    },
    title: {
        fontSize: 20,
        fontWeight: 400
    },
    form: {
        width: 1000,
        margin: "0 auto",
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            width: 300,
            padding: "20px 0",                        
        },     
    },
    contact: {
        display: "flex",
        flexFlow: "column",      
    },
    subject: {
        color: "#e4002b",
        fontSize: "23px",        
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    table: {
        border: "1px solid #ddd",   
        width: 1000,
        margin: "0 auto",
        [theme.breakpoints.down('sm')]: {
            width: 300
        },            
    },
    tr: {
        borderBottom: "1px solid #ddd",   
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexFlow: "column",
            width: 300
        },                     
    },
    th: {
        fontSize: 15,
        backgroundColor: "#f2f2f2",
        borderRight: "1px solid #ddd",        
        padding: "10px 20px",
        textAlign: "left",               
        verticalAlign: "top",
        minWidth: 200,
        [theme.breakpoints.down('sm')]: {
            width: 300
        },                          
    },  
    td: {                                                    
        padding: "10px 20px",
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            width: 300,
            flexFlow: "column",
            alignItems: "flex-start",
            marginLeft: 0
        },                               
    },
    contents: {                                                    
        padding: "10px 20px",
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            width: 300,
            flexFlow: "column",
            alignItems: "flex-start",
            marginLeft: 0
        },                               
    },
    filter: {                                                    
        padding: "10px 20px",
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            width: 300,
            flexFlow: "column",
            alignItems: "flex-start",
            marginLeft: 0
        },                               
    },
    nessesay: {
        backgroundColor: "#e4002b",
        color: "#fff",
        width: "max-content",
        padding: "3px 15px",
        marginTop: 20,         
    },
    field: {
        width: 500, 
        [theme.breakpoints.down('sm')]: {
            width: 250
        },           
    },
    fieldName: {
        width: 160,
    },      
    flex: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: 200
        },     
    },  
    margin: {
        marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    button: {
        fontSize: 18,
        border: "solid 1px #707070",
        backgroundColor: "#FDC04F",  
        width: "max-content",      
        borderRadius: 5,
        color: "#fff",
        padding: "8px 39px",
        fontWeight: "bold",
        marginRight: 33,
        '&:hover': {
            opacity: 0.5
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: 10,   
            fontSize: 12    
        },
    },
    buttonDisabled: {
        fontSize: 18,
        border: "solid 1px #707070",
        backgroundColor: "#ccc",  
        width: "max-content",      
        borderRadius: 5,
        color: "#fff",
        padding: "8px 39px",
        fontWeight: "bold",
        marginRight: 33,
        [theme.breakpoints.down('sm')]: {
            marginRight: 10,   
            fontSize: 12    
        },
    },
    list: {
        fontSize:12,
        cursor: "pointer",
        color: "#0027FF",
        '&:hover': {
            opacity: 0.8
        },
        [theme.breakpoints.down('sm')]: {
            width: 200
        },
    },
}));

const Confirm = () => {
    const { setCurrentStep, companyName, firstName, lastName, email, password, confirmPass,tel, 
            addressNum, address, category, introduce, saraly, saralyNight, station, range, times, 
            contents, attendanceshift, gakutika, pharmacy, url, message, students, studentspast, images, shift, term, welfare, onlyone, ather, shiftDetails, saralyDetails,
            partTimeDetails, welfareDetails, saralyMin, saralyMidle, saralyMax
        } = useContext(multiStepContext)

    const classes = useStyle();
    const [checked, setChecked] = useState(false);
    const handleClick = () => {
        setChecked(checked?false:true)
    }
    const dispatch = useDispatch();
    return(
        <div>
            <div className={classes.contact}>
            <h2 className={classes.title}>店舗情報確認</h2>
            <ImageView images={images} />
            <table className={classes.table}>
                <tr className={classes.tr}>
                    <th className={classes.th}>屋号</th>
                    <td className={classes.td}>
                        {companyName}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>代表者氏名</th>
                    <td className={classes.td}>
                        {firstName + lastName}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>メールアドレス</th>
                    <td className={classes.td}>
                        {email}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>パスワード</th>
                    <td className={classes.td}>
                        {password}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>電話番号</th>
                    <td className={classes.td}>
                        {tel}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>郵便番号</th>
                    <td className={classes.td}>
                        {addressNum}
                    </td>
                </tr>             
                <tr className={classes.tr}>
                    <th className={classes.th}>住所</th>
                    <td className={classes.td}>
                        {address}
                    </td>
                </tr>              
                <tr className={classes.tr}>
                    <th className={classes.th}>一言紹介</th>
                    <td className={classes.td}>
                        {introduce}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>職種</th>
                    <td className={classes.td}>
                        {category}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>時給</th>
                    <td className={classes.td}>
                        {saraly + "円"}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>深夜時給</th>
                    <td className={classes.td}>
                        {saralyNight + "円"}
                    </td>
                </tr>             
                <tr className={classes.tr}>
                    <th className={classes.th}>アクセス</th>
                    <td className={classes.td}>
                        <div className={classes.flex}>
                            {station}
                            <span>から</span>
                        </div>            
                        <div className={classes.flex}>
                            {range}
                            <span style={{marginRight: 10}}>m</span>
                        </div>                        
                    </td>
                </tr>    
                <tr className={classes.tr}>
                    <th className={classes.th}>ホームページURL</th>
                    <td className={classes.td}>
                        {url}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>メッセージ</th>
                    <td className={classes.td}>
                        {returnCodeToBr(message)}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>給与詳細</th>
                    <td className={classes.td}>
                        {returnCodeToBr(saralyDetails)}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>シフト詳細</th>
                    <td className={classes.td}>
                        {returnCodeToBr(shiftDetails)}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>勤務期間</th>
                    <td className={classes.td}>
                        {returnCodeToBr(partTimeDetails)}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>福利厚生詳細</th>
                    <td className={classes.td}>
                        {returnCodeToBr(welfareDetails)}
                    </td>
                </tr>               
                <tr className={classes.tr}>
                    <th className={classes.th}>勤務時間</th>
                    <td style={{padding: "10px 20px"}}>
                        {times && 
                            times.map(doc => (
                                <ul style={{display: "flex"}}>
                                    <li>{doc.startHour + " : " + doc.startMinutes + " ~ " + doc.finishHour + " : " + doc.finishMinutes + " 時給" + doc.saralycontent + "円"}</li>
                                </ul>
                            ))
                        }
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>勤務内容</th>
                    <td className={classes.contents}>
                        {contents && 
                            contents.map(doc => (
                                <ul>
                                    <li>◆{doc.content}</li>
                                    <li>{returnCodeToBr(doc.detail)}</li>
                                </ul>
                            ))
                        }
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>シフト</th>
                    <td>
                        <ul className={classes.td}>
                            {shift && 
                                shift.map(doc => (
                                        <li>{doc + ",　"}</li>
                                ))
                            }
                        </ul>
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>週あたりの出勤日数</th>
                    <td>
                        <ul className={classes.td}>
                            {attendanceshift && 
                                attendanceshift.map(doc => (
                                    <li>{doc + ",　"}</li>
                                ))
                            }
                        </ul>
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>福利厚生</th>
                    <td>
                        <ul className={classes.td}>
                        {welfare && 
                            welfare.map(doc => (
                                <li>{doc + ",　"}</li>
                            ))
                        }
                        </ul>
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>自分らしさ</th>
                    <td>
                        <ul className={classes.td}>
                        {onlyone && 
                            onlyone.map(doc => (
                                <li>{doc + ",　"}</li>
                            ))
                        }
                        </ul>
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>出勤期間</th>
                    <td>
                        <ul className={classes.td}>
                        {term && 
                            term.map(doc => (
                                <li>{doc + ",　"}</li>
                            ))
                        }
                        </ul>
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>その他</th>
                    <td>
                        <ul className={classes.td}>
                        {ather && 
                            ather.map(doc => (
                                <li>{doc + ",　"}</li>
                            ))
                        }
                        </ul>
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>理科大生在籍</th>
                    <td className={classes.td}>
                        {students}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>過去理科大生在籍</th>
                    <td className={classes.td}>
                        {studentspast}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>ガクチカ協力</th>
                    <td className={classes.td}>
                        {gakutika}
                    </td>
                </tr>
                <tr className={classes.tr}>
                    <th className={classes.th}>薬学部歓迎店舗</th>
                    <td className={classes.td}>
                        {pharmacy}
                    </td>
                </tr>
            </table>
            </div>
        <FormControlLabel
          value="end"
          control={<Checkbox color="primary" />}
          label="利用規約に同意する"
          labelPlacement="agree"
          onClick={handleClick}
        />
        <ul className={classes.link}>
            <li className={classes.list} onClick={() => dispatch(push('/poricyandtermpage'))}>ポリシー・規定一覧</li>
        </ul>
        <div className="module-spacer--small" />
        <div>
            <button onClick={() => setCurrentStep(4)} className={classes.button} >戻る</button>
            <a href={Promis} download={Promis}><button disabled={checked !== true} onClick={() => 
            dispatch(signUp(companyName, firstName, lastName, email, password, confirmPass, tel ,addressNum, address, category, saraly, saralyNight, introduce, station, range, times, 
            contents, attendanceshift, gakutika, pharmacy, url, message, students, studentspast, images, shift, term, welfare, onlyone, ather, shiftDetails, saralyDetails, partTimeDetails, welfareDetails, saralyMin, saralyMidle, saralyMax))} className={checked === true && classes.button || checked !== true && classes.buttonDisabled}>登録</button></a>
        </div>
        </div>
    )
}

export default Confirm;
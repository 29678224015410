import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { makeStyles } from '@material-ui/core';
import { storage } from '../../firebase';
import ImagePreview from './imagePrevie';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

const useStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        border: "1px solid #333",
        position: "relative",
    },
    imageList: {
        width: 500,
        height: 300,
        [theme.breakpoints.down('sm')]: {
            width: 300,
            height: 100
        }
    },
    regist: {
        background: "red",
        color: "#fff",
        padding: "5px 10px",
        borderRadius: 5,
        width: "max-contents",
        cursor: "pointer",
        fontSize: 20,
        '&:hover': {
            opacity: 0.6
        },
    },
    place: {
        margin: 10,
        textAlign: "right"
    },
    photo: {
        position: "absolute",
        top: "50%",
        left: "50%",
        WebkitTransform: "translateY(-50%) translateX(-50%)",     
        opacity: 0.5
    }
}))

const ImageArea = (props) => {
    const classes = useStyle();
    const deleteImage = useCallback(async(id) => {
        const ret = window.confirm('この画像を削除しますか？')
        if (!ret) {
            return false
        } else {
            const newImages = props.images.filter(image => image.id !== id)
            props.setImages(newImages);
            return storage.ref('images').child(id).delete()
        }
    }, [props.images])
    const uploadImage = useCallback((event) => {
        const file = event.target.files;
        let blob = new Blob(file, {type: "image/jpeg"});

        const S = "abcdefghijkmnlopqrstuvwxyzABCDEFGHIJKMNLOPQRSTYVWXYZ0123456789"
        const N = 16;
        const filename = Array.from(crypto.getRandomValues(new Uint32Array(N))).map((n) => S[n%S.length]).join('');
        
        const uploadRef = storage.ref('image').child(filename);
        const uploadTask = uploadRef.put(blob);
        
        uploadTask.then(() => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                const newImage = {id: filename, path: downloadURL};
                props.setImages((prevState => [...prevState, newImage]))
            });
        })
    }, [props.setImages]);
    return(
        <div>
            <div className={classes.root}>
                <div className={classes.photo}>
                    <AddPhotoAlternateIcon style={{fontSize: 100}} />
                </div>
                <ImageList rowHeight={160} className={classes.imageList}>
                {props.images.length > 0 && (
                    props.images.map(image => 
                    <ImageListItem key={image.id}>
                        <ImagePreview delete={deleteImage} id={image.id} path={image.path} key={image.id}/> 
                    </ImageListItem>
                    )
                    )}
                </ImageList>
            </div>
            <div className={classes.place}>
                <label  className={classes.regist}>
                    <span>店舗写真を登録する</span>
                    <input className="stelse" type="file" id="image" onChange={(event) => uploadImage(event)}/>
                </label>
            </div>
        </div>
    )
}

export default ImageArea;

import React, { useContext } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core';
const useStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        border: "solid 1px #707070",
        borderRadius: 5,
        width: "100%",
        width: 400,
        [theme.breakpoints.down('sm')]: {
            width: 250,
        },
    }
}))
const GakutikaRadio = (props) => {
    const classes = useStyle()
    return(
        <div>
        <RadioGroup value={props.value} onChange={props.onChange} row className={classes.root}>
            <FormControlLabel value="協力店" control={<Radio />} label="協力する" />
            <FormControlLabel value="" control={<Radio />} label="協力しない" />
        </RadioGroup>
        </div>
    )
}

export default GakutikaRadio;
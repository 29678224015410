import { makeStyles } from "@material-ui/styles";
import { margin } from "@mui/system";
export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: "#EBEBEB",   
        height: "100vh",      
        zIndex: 0,
        width: "100%"
    },
    content: {
        backgroundColor: "#FCFCFC",   
        width: 800,
        height: "100vh",
        margin: "0 auto",
        overflowY: "auto",
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 0
        },
    },
    companyHome: {
        listStyle: "none",
        width: 779,
        margin: "0 auto",
        fontFamily: "Hiragino Kaku Gothic ProN",
        [theme.breakpoints.down('sm')]: {
            width: "90%",
            marginTop: 70
        },
    },
    card: {
        width: 700,
        margin: "0 auto",
        border: "3px solid orange",
        borderRadius: 15,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    companyName: {
        maxWidth: 700,
        backgroundColor: "#FFEEDD",        
        fontSize: 20,
        textAlign: "left",
        paddingLeft: 25,
        paddingTop: 12,
        paddingBottom: 11,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    introduce: {
        width: 500,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    image: {
        maxWidth: 700,
        marginTop: 10,
        padding: 10,
        margin: 10,
        border: "solid 1px #707070"
    },
    table: {
        width: 850,
        margin: "0 auto",
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            width: 300,
            flexFlow: "column"
        },
    },
    field: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        margin: "0 auto",
        alignItems: "flex-start",
        width: 500,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            alignItems: "flex-start"
        },      
    },
    saralyfield: {
        width: 200,
        borderRadius: 5,
        padding: 5,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    url: {
        width: 400,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    margin: {
        marginTop: 0
    },
    access: {
        display: "flex",
        alignItems: "flex-start",
        flexFlow: "column",        
        marginTop: -10,      
    },
    contents: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        margin: "0 auto",
        alignItems: "flex-start",
        width: 400,    
    },
    point: {
        width: 750,
        margin: "0 auto",
        padding: 10,
        [theme.breakpoints.down('sm')]: {
            width: 300,
            margin: 0,
            padding: 0
        },
    },
    pointDetails: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",
    },

    column: {
        display: "flex",
        flexFlow: "column",
        [theme.breakpoints.down('sm')]: {
            margin: "0 auto"
        },
    },
    detail: {
        width: 300
    },
    ragio: {
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
    },
    button: {
        width: 300,
        background: "#fca505",
        borderRadius: 50,
        paddingTop: 10,
        paddingBottom: 10,
        color: "#fff",
        "&:hover": {
            opacity: 0.6
        }
    },
    buttonDisabled: {
        width: 300,
        color: "#707070",
        backgroundColor: "#ccc",
        borderRadius: 50,
        paddingTop: 10,
        paddingBottom: 10,
    },
    favoriteText: {
        textAlign: "left",
        fontWeight: "bold",
        backgroundColor: "#fca505",
        padding: "5px 10px",
        color: "#fff"
    },
    favorite: {
        border: "3px solid #fca505",      
    },
    fieldHarf: {
        width: 100,
        margin: 10
    },
}))
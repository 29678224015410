import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCompanyMails } from "../redux/company/selectors";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ConfirmApplicationItem from "../components/companys/ConfirmApplicationItem"
import SideHeader from "../components/header/SideHeader";
import { useDispatch } from "react-redux";
import { fetchCompanyMails } from "../redux/company/operation";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: "#EBEBEB",
        height: "100vh",           
    },      
    paper: {
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            marginTop: 50,
            width: "100%"
        }   
    }
}))

const ConfirmApplications = () => {
    const classes = useStyles();    
    const selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const confirmApplications = getCompanyMails(selector);

    useEffect(() => {
        dispatch(fetchCompanyMails())
    }, [])
    
    return(
        <div className={classes.root}>
        <SideHeader />
        <Paper className={classes.paper} elevation={0}>
            {confirmApplications.length > 0 && (
                confirmApplications.map((confirmApplication) => <ConfirmApplicationItem confirmApplication={confirmApplication} key={confirmApplication.mailboxId}/>)
            )}
        </Paper>              
    </div>
    )
}

export default ConfirmApplications;
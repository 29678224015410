import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import {makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const useStyles = makeStyles((theme) => ({
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: 256,
                flexShrink: 0,
            }
        },        
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: 250,
            [theme.breakpoints.up('sm')]: {
                width: 350,
                flexShrink: 0,
            }
        },
        searchField: {
            alignItems: 'center',
            display: 'flex',
            marginLeft: 32
        },
        list: {
            height: 150
        },
        image: {
            objectFit: 'cover',
            margin: 16,
            height: 96,
            width: 96
        },
        text: {
            width: '100%'
        },
        root: {
            backgroundColor: theme.palette.background.paper,
          },
}));

const MenuDrower = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {container} = props;

    return(
        <nav className={classes.drawer}>
            <Drawer
                container={container}
                variant="temporary"
                anchor="right"
                open={props.open}
                onClose={(e) => props.onClose(e)}
                classes={{paper: classes.drawerPaper}}
                ModalProps={{keepMounted: true}}
            >
            <div
                onClose={(e) => props.onClose(e)}
                onKeyDown={(e) => props.onClose(e)}
            >
            <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">
                <ListItem button>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                    <ListItemText primary="ログイン" onClick={() => dispatch(push('/'))}/>
                </ListItem>
                <ListItem button>
                <ListItemIcon>
                    <DraftsIcon />
                </ListItemIcon>
                    <ListItemText primary="会員登録" onClick={() => dispatch(push('/signup'))} />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="お問い合わせ" onClick={() => dispatch(push('/contactform'))} />
                </ListItem>
            </List>
            </div>
            </div>
            </Drawer>
        </nav>
    )
}

export default MenuDrower;
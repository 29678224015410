import { makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import Footer from "../components/footer/footer";
import NotSignInHeader from "../components/header/NotSignInHeader";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "70%",
        margin: "0 auto",
        textAlign: "left",   
        [theme.breakpoints.down('sm')]: {
            marginBottom: 150
        }   
    },
    title: {
        fontWeight: "bold",
        fontSize: 36,
        [theme.breakpoints.down('sm')]: {
            fontSize: 26
        }
    },
    subject: {
        fontSize: 18,
        borderBottom: "2px solid #ccc",
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    point: {
        cursor: "pointer",
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        },
        '&:hover': {
            opacity: 0.8
         },
    },
}))

const PoricyTermPage = () => {
    const classes = useStyles()
    const dispatch = useDispatch();
    return(
        <div>
            <NotSignInHeader />
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className={classes.root}>
                <h2 className={classes.title}>ポリシー・規定一覧</h2>
                <div className="module-spacer--medium" />
                <h3 className={classes.subject}>【規定】</h3>
                <div className="module-spacer--small" />
                <ul>
                    <li className={classes.point} onClick={() => dispatch(push('/termofservice'))}>利用規約</li>
                </ul>
                <div className="module-spacer--medium" />
                <h3 className={classes.subject}>【ポリシー】</h3>
                <div className="module-spacer--small" />
                <ul>
                    <li className={classes.point} onClick={() => dispatch(push('/privasypolicy'))}>個人情報保護法</li>
                    <li className={classes.point} onClick={() => dispatch(push('/privacystatements'))}>プライバシーステイトメント</li>
                </ul>
            </div>
            <Footer />
        </div>
    )
}

export default PoricyTermPage;
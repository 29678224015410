import { makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState, useCallback} from 'react';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../redux/company/operation';
import { push } from 'connected-react-router';
import NotSignInHeader from '../components/header/NotSignInHeader';

const useStyle = makeStyles((theme) => ({
    root: {
        height: "100vh",
        backgroundColor: "#FCFCFC"
    },
    card: {
        margin: "0 auto",
        width: "100%",
        maxWidth: 650,
        padding: "50px 100px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        border: "solid 1px #707070",
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 200
        },
        '&:hover': {
            background: "#EEEEEE",            
        },
    },
    space_big:{
        height: 50,
    },
    space_small: {
        height: 30,
    },
    position: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column"
        }
    },
    field: {
        width: 300,
        marginTop: 0
    },  
    flexFlow: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start"
    },
    text: {
        cursor: "pointer",
        width: "max-content",
        fontSize: 12,
        color: "#707070",
        paddingRight: 20,
        paddingLeft: 20,
        '&:hover': {
            opacity: 0.8
        },
    }
}))

const Reset = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    
    const inputEmail = useCallback((event) => {
        setEmail(event.target.value)
    }, [setEmail]);

    return(
        <div className="background">
            <NotSignInHeader />
            <div className={classes.space_big} />
            <div className={classes.space_big} />
            <div className={classes.space_big} />
            <Paper elevation={0} className={classes.card}>
            <div className={classes.flexFlow}>
            <p style={{fontSize: 14, color: "#707070"}}>登録したメールアドレス</p>
            <TextField
             multiline={true} margin='dense' variant="outlined" className={classes.field}
             required={true} rows={1} value={email} type={"email"} onChange={inputEmail} 
            />
            </div>
            <div className={classes.space_small} />
            <button variant="contained" className={classes.button} onClick={() => dispatch(resetPassword(email))}>パスワードのリセット</button>
            <div className={classes.space_small} />
            <div className={classes.position}>
                <Typography variant="p" className={classes.text} onClick={() => dispatch(push('/'))}>ログイン画面に戻る</Typography>
                <Typography variant="p" className={classes.text} onClick={() => dispatch(push('/signup'))}>店舗登録</Typography>
            </div>
            </Paper>
        </div>
    )
}

export default Reset;
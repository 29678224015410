import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Typography} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch } from 'react-redux';
import { signOut } from '../../redux/company/operation'
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    menu: {
        paddingLeft: 20,
        fontSize: 14,
        cursor: "pointer",
        color: "#707070"
    },
    menuLg: {
        display: "none",
        [theme.breakpoints.up('sm')]: {
          display: "block",
        }
      },
    menuSm: {
        display: "block",
        [theme.breakpoints.up('sm')]: {
          display: "none",
        }
    },
    layout: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }
}))
const NotSignHeaderMenu = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles();        
    return (
        <>
        <div className={classes.layout}>
        <div className={classes.menuLg}>                          
            <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/signup'))} style={{color: "#FF9300"}}>店舗登録</Typography>                                                                                      
            <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/'))} style={{color: "#FF9300"}}>ログイン</Typography>                                                                                      
            <Typography variant="p" className={classes.menu} onClick={() => dispatch(push('/contactform'))}>お問い合わせ</Typography>            
        </div>
        <div className={classes.layout}>
                <IconButton
                    aria-label="Menu Items"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={(e) => props.menuDrawerToggle(e)}
                    className={classes.menuSm}
                >
                    <MenuIcon />
                </IconButton>
        </div>
        </div>
        </>
    );
};

export default NotSignHeaderMenu;
import React, { useContext, useState } from "react";
import App from "./App";

export const multiStepContext = React.createContext();

const StepContext = () => {
    const [currentstep, setCurrentStep] = useState(1);
    const [companyName, setCompanyName] = useState(""),
          [firstName, setFirstName] = useState(""),
          [lastName, setLastName] = useState(""),
          [email, setEmail] = useState(""),
          [password, setPassword] = useState(""),
          [confirmPass, setConfirmPass] = useState(""),
          [tel, setTel] = useState(""),
          [category, setCategory] = useState([]),
          [introduce, setIntroduce] = useState(""),
          [station, setStation] = useState(""),
          [range, setRange] = useState(""),
          [times, setTimes] = useState([]),       
          [saraly, setSaraly] = useState(""),
          [saralyNight, setSaralyNight] = useState(""),
          [addressNum, setAddressNum] = useState(""),
          [address, setAddress] = useState(""),
          [contents, setContents] = useState([]),
          [complex, setComplex] = useState([]),
          [url, setUrl] = useState(""),
          [message, setMessage] = useState(""),
          [students, setStudents] = useState(""),
          [studentspast, setStudentspast] = useState(""),
          [gakutika, setGakutika] = useState(""),
          [pharmacy, setPharmacy] = useState(""),
          [images, setImages] = useState([]),
          [attendanceshift, setAttendanceshift] = useState([]),
          [shift, setShift] = useState([]),
          [term, setTerm] = useState([]),
          [onlyone, setOnlyone] = useState([]),
          [welfare, setWelfare] = useState([]),
          [ather, setAther] = useState([]),
          [partTime, setPartTime] = useState([]),
          [saralyMin, setSaralyMin] = useState(saraly*parseInt(partTime)*10),
          [saralyMidle, setSaralyMidle] = useState(saraly*parseInt(partTime)*15),
          [saralyMax, setSaralyMax] = useState(saraly*parseInt(partTime)*20),
          [shiftDetails, setShiftDetails] = useState("週1日以上、1日3時間以上\シフト自由・自己申告（14日ごとに提出）\n【勤務時間】\n全日 9:00～21:00\n※上記時間内で3h~勤務可能\n≪たとえば、こんな働き方も◎≫\n・家事が落ち着いた午後に出勤！\n・学校帰りにアルバイト♪\n・Wワークでランチ後に勤務☆\n※急なお休みや長期休みも相談OK"),
          [saralyDetails, setSaralyDetails] = useState("時給○○円以上＋交通費全額支給昇給あり\n・22時以降は時給○○％UP\n※研修中も同時給支払方法\n：月1回交通費\n：別途全額支給"),
          [partTimeDetails, setPartTimeDetails] = useState("長期(1ヶ月以上)\n短期(1ヶ月以内)\n夏休み限定\n冬休み限定\n春休み限定"),
          [welfareDetails, setWelfareDetails] = useState("おいしいまかないが自慢です！\n「まかないが食べたいからシフトを入れちゃおう！」もOK◎\n一人暮らしの強い味方！\n◆毎月社員登用のチャンスあり！登用実績も多数！\n「ゆくゆくは正社員に･･･」という方も大歓迎！\n◆丁寧な研修制度あり！\nやさしくしっかり教えます◎\n◆友達紹介制度で2万円支給中！※規定あり");

    return(
        <div>
            <multiStepContext.Provider 
                value={{
                    currentstep, setCurrentStep,
                    companyName, setCompanyName,
                    firstName, setFirstName,
                    lastName, setLastName,
                    email, setEmail,
                    password, setPassword,
                    confirmPass, setConfirmPass,
                    tel, setTel,
                    addressNum, setAddressNum,
                    address, setAddress,
                    category, setCategory,
                    introduce, setIntroduce,
                    station, setStation,
                    range, setRange,
                    times, setTimes,
                    saraly, setSaraly,
                    saralyMin, setSaralyMin,
                    saralyMidle, setSaralyMidle,
                    saralyMax, setSaralyMax,
                    saralyNight, setSaralyNight,
                    partTime, setPartTime,
                    contents, setContents,
                    complex, setComplex,
                    url, setUrl,
                    message, setMessage,
                    students, setStudents,
                    studentspast, setStudentspast,
                    gakutika, setGakutika,
                    pharmacy, setPharmacy,
                    images, setImages,
                    shift, setShift,
                    attendanceshift, setAttendanceshift,
                    term, setTerm,
                    welfare, setWelfare,
                    onlyone, setOnlyone,
                    ather, setAther,
                    shiftDetails, setShiftDetails,
                    saralyDetails, setSaralyDetails,
                    partTimeDetails, setPartTimeDetails,
                    welfareDetails, setWelfareDetails,
                }}
            >
                <App />
            </multiStepContext.Provider>
        </div>
    )
}
export default StepContext;
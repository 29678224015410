import React, { useCallback, useEffect, useContext, useState } from 'react';
import { Card, Paper, TextField } from '@material-ui/core';
import SelectBox from '../UIkit/SelectBox';
import { saveCompany } from '../redux/company/operation';
import { useDispatch } from 'react-redux';
import ImageArea from '../components/companys/imageArea'
import { auth, db } from '../firebase';
import RadioForm from '../UIkit/Radio';
import useStyle from '../templates/edit';
import MultiSelect from '../UIkit/MultiSelect';
import SetContent from '../UIkit/setContent';
import SideHeader from '../components/header/SideHeader';
import SetJobTime from '../UIkit/SerJobTime';
import SideDrower from '../components/header/SideDrower';
import { multiStepContext } from '../SteoContext';
import { isValidRequiredInput } from '../function/common';
import GakutikaRadio from '../UIkit/GakutikaRadio';
import PopoverLabel from '../UIkit/Popover';
import TimesExe from '../UIkit/TimesExe';
import ContentsExe from '../UIkit/ContentsExe';
import { push } from 'connected-react-router';
import PharmacyRadio from '../UIkit/PhamacyRadio';

const shifts = [
    '平日のみOK',
    '土日祝のみOK',
];
const attendanceshifts = [
    '週1日から',
    '週2日から',
    '週3日から',
    '週4日から',
];
const terms = [
    '長期(1ヶ月以上)',
    '短期(1ヶ月以内)',
    '単発',
    '夏休み限定',
    '春休み限定',
    '冬休み限定',
];
const welfares = [
    "まかないあり",
    "交通費支給",
    "社割あり",
    "賞与あり",
    "有給休暇あり",
];
const onlyones = [
    "髪型自由",
    "髪色自由",
    "ピアス可",
    "ネイル可",
];
const athers = [
    "長期休み限定",
    "日払い",
    "短時間勤務(1日2時間以内)OK",
    "未経験者OK",
    "友達と募集OK",
    "研修あり",
    "バイク通勤/車通勤OK",
    "資格所得支援制度",
    "オープニングスタッフ",
    "留学生歓迎",
    "即日勤務OK",
    "WワークOK",
    "残業なし",
    "送迎あり",
    "扶養控除内勤務可",
    "昇給あり",
    "履歴書不要",
    "オンライン面接可能",
];

const CompanyInfo = () => {
    const classes = useStyle();
    const dispatch = useDispatch();

    const { companyName, setCompanyName,
            addressNum, setAddressNum,
            address, setAddress,
            category, setCategory,
            introduce, setIntroduce,
            station, setStation,
            range, setRange,
            times, setTimes,
            saraly, setSaraly,
            saralyNight, setSaralyNight,
            contents, setContents,
            url, setUrl,
            message, setMessage,
            students, setStudents,
            studentspast, setStudentspast,
            gakutika, setGakutika,
            pharmacy, setPharmacy,
            images, setImages,
            attendanceshift, setAttendanceshift,
            shift, setShift,
            term, setTerm,
            welfare, setWelfare,
            onlyone, setOnlyone,
            ather, setAther,
            shiftDetails, setShiftDetails,
            saralyDetails, setSaralyDetails,
            partTimeDetails, setPartTimeDetails,
            welfareDetails, setWelfareDetails
    } = useContext(multiStepContext)

    const inputCompanyName = useCallback((e) => {
        setCompanyName(e.target.value)
    }, [setCompanyName])
    const inputIntroduce = useCallback((e) => {
        setIntroduce(e.target.value)
    }, [setIntroduce])   
    const inputSaraly = useCallback((e) => {
        setSaraly(e.target.value)
    }, [setSaraly])
    const inputSaralyNight = useCallback((e) => {
        setSaralyNight(e.target.value)
    }, [setSaralyNight])
    const inputAddressNum = useCallback((e) => {
        setAddressNum(e.target.value)
    }, [setAddressNum])
    const inputAddress = useCallback((e) => {
        setAddress(e.target.value)
    }, [setAddress])
    const inputStation = useCallback((e) => {
        setStation(e.target.value)
    }, [setStation])
    const inputRange = useCallback((e) => {
        setRange(e.target.value)
    }, [setRange])
    const inputMessage = useCallback((e) => {
        setMessage(e.target.value)
    }, [setMessage])
    const inputUrl = useCallback((e) => {
        setUrl(e.target.value)
    }, [setUrl])
    const inputSaralyDetails = useCallback((e) => {
        setSaralyDetails(e.target.value)
    }, [setSaralyDetails])
    const inputShiftDetails = useCallback((e) => {
        setShiftDetails(e.target.value)
    }, [setShiftDetails])
    const inputPartTimeDetails = useCallback((e) => {
        setPartTimeDetails(e.target.value)
    }, [setPartTimeDetails])
    const inputWelfareDetails = useCallback((e) => {
        setWelfareDetails(e.target.value)
    }, [setWelfareDetails])
    const inputStudent = useCallback((e) => {
        setStudents(e.target.value)
    }, [setStudents])
    const inputStudentpast = useCallback((e) => {
        setStudentspast(e.target.value)
    }, [setStudentspast])
    const inputGakutika = useCallback((e) => {
        setGakutika(e.target.value)
    }, [setGakutika])
    const inputPharmacy = useCallback((e) => {
        setPharmacy(e.target.value)
    }, [setPharmacy])
    const shiftChange = (event) => {
        setShift(event.target.value)
    }    
    const attendanceshiftChange = (event) => {
        setAttendanceshift(event.target.value)
    }    
    const termChange = (event) => {
        setTerm(event.target.value)
    }    
    const welfareChange = (event) => {
        setWelfare(event.target.value)
   }
    const onlyoneChange = (event) => {
        setOnlyone(event.target.value)
   }
    const ahterChange = (event) => {
        setAther(event.target.value)
   }

   const [introExe, setIntroExe] = useState(null),
         [timesExe, setTimesExe] = useState(null),
         [contentsExe, setContentsExe] = useState(null),
         [gakutikaExe, setGakutikaExe] = useState(null),
         [studentsExe, setStudentsExe] = useState(null),
         [pharmacyExe, setPharmacyExe] = useState(null),
         [studentspastExe, setStudentspastExe] = useState(null),
         [accessExe, setAccessExe] = useState(null),
         [urlExe, setUrlExe] = useState(null), 
         [messageExe, setmessageExe] = useState(null),
         [saralyExe, setSaralyExe] = useState(null),
         [shiftExe, setShiftExe] = useState(null), 
         [timeExe, setTimeExe] = useState(null), 
         [welfareExe, setWelfareExe] = useState(null);

    const introHandleOpen = (e) => {
        setIntroExe(e.currentTarget)
    }
    const timesHandleOpen = (e) => {
        setTimesExe(e.currentTarget)
    }
    const contentsHandleOpen = (e) => {
        setContentsExe(e.currentTarget)
    }
    const gakutikaHandleOpen = (e) => {
        setGakutikaExe(e.currentTarget)
    }
    const studentsHandleOpen = (e) => {
        setStudentsExe(e.currentTarget)
    }
    const studentspastHandleOpen = (e) => {
        setStudentspastExe(e.currentTarget)
    }
    const pharmacyHandleOpen = (e) => {
        setPharmacyExe(e.currentTarget)
    }
    const accessHandleOpen = (e) => {
        setAccessExe(e.currentTarget)
    }
    const urlHandleOpen = (e) => {
        setUrlExe(e.currentTarget)
    }
    const messageHandleOpen = (e) => {
        setmessageExe(e.currentTarget)
    }
    const saralyHandleOpen = (e) => {
        setSaralyExe(e.currentTarget)
    }
    const shiftHandleOpen = (e) => {
        setShiftExe(e.currentTarget)
    }
    const timeHandleOpen = (e) => {
        setTimeExe(e.currentTarget)
    }
    const welfareHandleOpen = (e) => {
        setWelfareExe(e.currentTarget)
    }

    const accessHandleClose = () => {
        setAccessExe(null)
    }
    const urlHanldeClose = () => {
        setUrlExe(null)
    }
    const messageHandleClose = () => {
        setmessageExe(null)
    }
    const saralyHandleClose = () => {
        setSaralyExe(null)
    }
    const timeHandleClose = () => {
        setTimeExe(null)
    }
    const shiftHanldeClose = () => {
        setShiftExe(null)
    }
    const welfareHandleClose = () => {
        setWelfareExe(null)
    }
    const introHandleClose = () => {
        setIntroExe(null)
    }
    const timesHanldeClose = () => {
        setTimesExe(null)
    }
    const contentsHandleClose = () => {
        setContentsExe(null)
    }
    const gakutikaHandleClose = () => {
        setGakutikaExe(null)
    }
    const studentsHandleClose = () => {
        setStudentsExe(null)
    }
    const studentspastHandleClose = () => {
        setStudentspastExe(null)
    }
    const pharmacyHandleClose = () => {
        setPharmacyExe(null)
    }

    const introOpen = Boolean(introExe);
    const timesOpen = Boolean(timesExe);
    const contentsOpen = Boolean(contentsExe);
    const gakutikaOpen = Boolean(gakutikaExe);
    const studentsOpen = Boolean(studentsExe);
    const studentspastOpen = Boolean(studentspastExe);
    const accessOpen = Boolean(accessExe);
    const urlOpen = Boolean(urlExe);
    const messageOpen = Boolean(messageExe);
    const saralyOpen = Boolean(saralyExe);
    const timeOpen = Boolean(timeExe);
    const shiftOpen = Boolean(shiftExe);
    const welfareOpen = Boolean(welfareExe);
    const pharmacyOpen = Boolean(pharmacyExe);

    const introId = introOpen ? 'simple-popover' : undefined;
    const timesId = timesOpen ? 'simple-popover' : undefined;
    const contentsId = contentsOpen ? 'simple-popover' : undefined;
    const gakutikaId = gakutikaOpen ? 'simple-popover' : undefined;
    const studentsId = studentsOpen ? 'simple-popover' : undefined;
    const studentspastId = studentspastOpen ? 'simple-popover' : undefined;
    const accessId = accessOpen ? 'simple-popover' : undefined;
    const urlId = urlOpen ? 'simple-popover' : undefined;
    const messageId = messageOpen ? 'simple-popover' : undefined;
    const saralyId = saralyOpen ? 'simple-popover' : undefined;
    const timeId = timeOpen ? 'simple-popover' : undefined;
    const shiftId = shiftOpen ? 'simple-popover' : undefined;
    const welfareId = welfareOpen ? 'simple-popover' : undefined;
    const pharmacyId = pharmacyOpen ? 'simple-popover' : undefined;

    const categories = [
        {id: "eat", name: "飲食"}, //赤
        {id: "leisure", name: "レジャー"}, //水色
        {id: "customer", name: "接客"}, //黄色
        {id: "sale", name: "販売"}, //ピンク
        {id: "education", name: "教育"}, //青
        {id: "delivery", name: "配送"}, //黄緑
        {id: "easy_work", name: "軽作業"}, //紫
        {id: "it", name: "IT"}, //黒
        {id: "medical", name: "医療・福祉"}, //薄いグレー
        {id: "specialty", name: "専門"}, //
        {id: "office", name: "事務"}, //緑
        {id: "ahter", name: "その他"}, //濃いグレー
    ]

    useEffect(() => {
        const myCompany = auth.currentUser;
        const companyId = myCompany.uid
        db.collection('companys').doc(companyId).get()
            .then(snapshot => {
                const data = snapshot.data();
                setCompanyName(data.companyName)
                setCategory(data.category)                       
                setSaraly(data.saraly)
                setSaralyNight(data.saralyNight)
                setAddressNum(data.addressNum)
                setAddress(data.address)
                setMessage(data.message)
                if(data.shift) {
                    setShift(data.shift)
                }
                if(data.attendanceshift) {
                    setAttendanceshift(data.attendanceshift)
                }
                if(data.term) {
                    setTerm(data.term)
                }
                if(data.welfare) {
                    setWelfare(data.welfare)
                }
                if(data.onlyone) {
                    setOnlyone(data.onlyone)
                }
                if(data.ather) {
                    setAther(data.ather)
                }
                setIntroduce(data.introduce)                
                setStudents(data.students)
                setStudentspast(data.studentspast)
                setGakutika(data.gakutika)
                setPharmacy(data.pharmacy)
                if(data.images) {
                    setImages(data.images)
                }
                if(data.contents) {
                    setContents(data.contents)
                }                
                if(data.times) {
                    setTimes(data.times)
                }            
                setStation(data.station)
                setRange(data.range)
                setUrl(data.url) 
                setShiftDetails(data.shiftDetails)
                setWelfareDetails(data.welfareDetails)
                setSaralyDetails(data.saralyDetails)
                setPartTimeDetails(data.partTimeDetails)
        })
       }, []);

    return(
        <div className={classes.root}>
            <SideDrower />
            <SideHeader />
            <Paper className={classes.content}>            
                <div className={classes.companyHome}>
                <Card className={classes.card}>
                <h1 className={classes.companyName}>
                <TextField
                multiline={true} label="社名"
                required={true} rows={1} value={companyName} type={"text"} onChange={inputCompanyName}
                />
                 {companyName === "" ? (
                    <p style={{fontSize: 12, color: "red"}}>※必須項目です</p>
                ) : (
                    <p style={{display: "none"}}></p>
                )}   
                </h1>
                <div className={classes.borderBottom} />     
                <div className={classes.introduce}>
                <TextField
                    multiline={false} fullWidth={true} label="一言紹介" 
                    rows={1} value={introduce} type={"text"} onChange={inputIntroduce}
                />        
                </div>                       
                <div style={{display: "flex"}}>
                {introduce.length <= 20 ? (
                    <p style={{fontSize: 12, color: "#707070", paddingLeft: 25}}>一言紹介(文字数{introduce.length}字)<span className={classes.nessesary}>※必須</span></p>
                ) : (
                    <p style={{fontSize: 12, color: "red", paddingLeft: 25}}>一言紹介(文字数{introduce.length}字*制限を超えています)</p>
                )}          
                <PopoverLabel 
                    handleClick={introHandleOpen}
                    id={introId}
                    open={introOpen}
                    anchorEl={introExe}
                    handleClose={introHandleClose}
                    message={
                        <p>学生さんに一番目につくお店の紹介文章です。<br />お店の特徴が分かりやすい文章を20字以内でお願いします。</p>
                    }
                />   
                </div>
                <div className={classes.marginTop} />
                <div className={classes.image}>
                    <ImageArea images={images} setImages={setImages}/>
                </div>
                    <div className={classes.column}>
                        <MultiSelect value={shift} onChange={shiftChange} options={shifts} label={"シフト"} />
                        <div className="module-spacer--small" />    
                        <MultiSelect value={attendanceshift} onChange={attendanceshiftChange} options={attendanceshifts} label={"週あたりの出勤日数"} />
                        <div className="module-spacer--small" />    
                        <MultiSelect value={term} onChange={termChange} options={terms} label={"勤務期間"} />
                        <div className="module-spacer--small" />    
                        <MultiSelect value={welfare} onChange={welfareChange} options={welfares} label={"福利厚生"} />                        
                        <div className="module-spacer--small" />    
                        <MultiSelect value={onlyone} onChange={onlyoneChange} options={onlyones} label={"自分らしさ"} />                        
                        <div className="module-spacer--small" />    
                        <MultiSelect value={ather} onChange={ahterChange} options={athers} label={"その他"} />                                        
                    </div>
                    <div className="module-spacer--small" />    
                </Card>            
                <div className="module-spacer--small" />     
                <div className="module-spacer--small" />     
                <div className={classes.table}>
                    <div className={classes.field}>
                            <p>給与</p>
                            <div style={{display: "flex", alignItems: "center"}}>
                            <input
                                variant="outlined" className={classes.saralyfield} margin='dense' step={10} min={850}
                                required={true} rows={1} value={saraly} type="number" onChange={inputSaraly} placeholder='山口県最低賃金857円'
                            />  円        
                            </div>      
                            {saraly === "" ? (
                                <p style={{fontSize: 12, color: "red"}}>※必須項目です</p>
                            ) : (
                                <p style={{display: "none"}}></p>
                            )}      
                              {saraly < 857 && saraly !== "" ? (
                                <p style={{fontSize: 12, color: "blue"}}>※最低賃金を下回っています</p>
                            ) : (
                                <p style={{display: "none"}}></p>
                            )}                                                     
                            <p>給与(22:00以降)</p>
                            <div style={{display: "flex", alignItems: "center"}}>
                            <input
                                variant="outlined" className={classes.saralyfield} margin='dense' id="standard-number" step={10} min={saraly * 1.25}
                                required={true} rows={1} value={saralyNight} type="number" onChange={inputSaralyNight} placeholder='1000'
                            />円        
                            </div>                                                      
                            {saralyNight < saraly*1.25 && saralyNight !== "" ? (
                                <p style={{fontSize: 12, color: "blue"}}>※深夜帯の時給は通常の1.25倍以上で入力して下さい</p>
                            ) : (
                                <p style={{display: "none"}}></p>
                            )}       
                        <div className="module-spacer--small" />                    
                            <p>職種</p>                        
                            <SelectBox
                                options={categories} required={false} value={category} select={setCategory} 
                            />     
                            {category === "" ? (
                                <p style={{fontSize: 12, color: "red"}}>※必須項目です</p>
                            ) : (
                                <p style={{display: "none"}}></p>
                            )}                              
                        <div className="module-spacer--small" />  
                            <div style={{display: "flex"}}>
                            <p>勤務時間</p>   
                            <PopoverLabel 
                                handleClick={timesHandleOpen}
                                id={timesId}
                                open={timesOpen}
                                anchorEl={timesExe}
                                handleClose={timesHanldeClose}
                                message={
                                    <div>
                                    <p>
                                        店舗様が希望する出勤時間をご記入ください。<br />
                                        複数記入していただいて構いません。<br />
                                        <span style={{color: "red"}}>※ご記入いただいたら必ず<strong>項目を追加ボタン</strong>をクリックして下さい。</span><br />
                                        <strong>記入例</strong></p>
                                    <TimesExe times={times} setTimes={setTimes} />  
                                    </div>
                                }
                            />     
                            </div>
                            <SetJobTime times={times} setTimes={setTimes} />   
                            {times.length > 0 ? (
                                <p style={{display: "none"}}></p>
                            ) : (
                                <p style={{fontSize: 12, color: "red"}}>※必須項目です</p>
                            )}                         
                        <div className="module-spacer--small" />                                                                                      
                            <p>住所</p>    
                            <div style={{display: "flex", alignItems: "center"}}>
                            〒<TextField
                                multiline={true} variant="outlined" margin='dense' className={classes.margin}
                                rows={1} value={addressNum} type={"text"} onChange={inputAddressNum}
                            />
                            </div>                    
                            {addressNum === "" ? (
                                <p style={{fontSize: 12, color: "red"}}>※必須項目です</p>
                            ) : (
                                <p style={{display: "none"}}></p>
                            )}   
                            <TextField
                                multiline={true}  variant="outlined" margin='dense' style={{width: 300}}
                                rows={1} value={address} type="text" onChange={inputAddress}                            
                            /> 
                            {address === "" ? (
                                <p style={{fontSize: 12, color: "red"}}>※必須項目です</p>
                            ) : (
                                <p style={{display: "none"}}></p>
                            )}   
                            <div className="module-spacer--small" />       
                            <div style={{display: "flex"}}>
                            <p>アクセス</p>
                            <PopoverLabel 
                                handleClick={accessHandleOpen}
                                id={accessId}
                                open={accessOpen}
                                anchorEl={accessExe}
                                handleClose={accessHandleClose}
                                message={
                                    <div>
                                    <p>
                                        ご登録いただく店舗様の所在地から最寄りの駅やバス停、施設などをご記入ください。<br />
                                        <strong>記入例</strong><br />
                                    </p>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <TextField
                                            multiline={true}  variant="outlined" margin='dense' style={{width: 280}}
                                            rows={1} value={"小野田駅"} 
                                        />から
                                        <TextField
                                            multiline={true}  variant="outlined" margin='dense' style={{width: 80}}
                                            rows={1} value={"1000"} 
                                        />m
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <TextField
                                            multiline={true}  variant="outlined" margin='dense' style={{width: 280}}
                                            rows={1} value={"小野田市役所"} 
                                        />から
                                        <TextField
                                            multiline={true}  variant="outlined" margin='dense' style={{width: 80}}
                                            rows={1} value={"500"} 
                                        />m
                                    </div>
                                    </div>
                                }
                            />   
                            </div>
                            <div className={classes.access}>
                            <div style={{display: "flex", alignItems: "center"}}>
                            <TextField
                                multiline={true}  variant="outlined" margin='dense' style={{width: 120}}
                                rows={1} value={station} type="text" onChange={inputStation} placeholder='○○駅'
                            />から
                            <TextField
                                multiline={true}  variant="outlined" margin='dense' style={{width: 80}}
                                rows={1} value={range} type="text" onChange={inputRange}                            
                            />m
                            </div>
                            </div>                                             
                        </div>                    
                        <div className={classes.contents}>
                            <div style={{display: "flex"}}>
                            <p>仕事内容</p>
                            <PopoverLabel 
                                handleClick={contentsHandleOpen}
                                id={contentsId}
                                open={contentsOpen}
                                anchorEl={contentsExe}
                                handleClose={contentsHandleClose}
                                message={
                                    <div>
                                    <p>
                                        募集する仕事の内容をご記入ください。<br />
                                        複数記入していただいて構いません。<br />
                                        <strong>仕事内容欄</strong>には大まかな仕事の内容、<br />
                                        <strong>詳細欄</strong>にはその仕事内容についてできるだけ詳しく説明して下さい。<br />
                                        <span style={{color: "red"}}>※ご記入いただいたら必ず<strong>項目を追加ボタン</strong>をクリックして下さい。</span><br />
                                        <strong>記入例</strong>
                                    </p>
                                    <ContentsExe contents={contents} setContents={setContents} />  
                                    </div>
                                }
                            />    
                            </div>
                            <SetContent contents={contents} setContents={setContents} />
                            {contents.length > 0 ? (
                                <p style={{display: "none"}}></p>
                            ) : (
                                <p style={{fontSize: 12, color: "red"}}>※必須項目です</p>
                            )}        
                            <div className="module-spacer--small" />  
                            <div style={{display: "flex"}}>  
                            <p>ホームページ(URL)</p>    
                            <PopoverLabel 
                                handleClick={urlHandleOpen}
                                id={urlId}
                                open={urlOpen}
                                anchorEl={urlExe}
                                handleClose={urlHanldeClose}
                                message={
                                    <p>
                                        ホームページなど店舗様を紹介できるサイトがございましたら<br />こちらにURLをご記入ください。
                                    </p>
                                }
                            />        
                            </div>                   
                            <TextField
                                multiline={false} variant="outlined" margin='dense' className={classes.url}
                                rows={1} value={url} type="text" onChange={inputUrl} 
                            />                                                   
                        </div>                    
                </div>
                <div className="module-spacer--small" />     
                <div className={classes.point}>
                    <div style={{display: "flex"}}>
                    <p>メッセージ</p>
                    <PopoverLabel 
                        handleClick={messageHandleOpen}
                        id={messageId}
                        open={messageOpen}
                        anchorEl={messageExe}
                        handleClose={messageHandleClose}
                        message={
                            <p>
                                お店の雰囲気、仕事内容、ポリシーなどご自由にご記入ください。<br />
                                文字数制限はございません。<br />
                                <span style={{color: "red", fontSize: 12}}>※改行部分は店舗詳細画面にもそのまま反映されます。</span>
                            </p>
                        }
                    />   
                    </div>
                    <TextField
                        label={"メッセージ"} multiline={true} className={classes.point} variant="outlined" 
                        rows={6} value={message} type="text" onChange={inputMessage} 
                    />
                </div>
                <div className={classes.pointDetails}>
                    <div className={classes.point}>
                    <div style={{display: "flex"}}>
                    <p>給与詳細情報</p>
                    <PopoverLabel 
                        handleClick={saralyHandleOpen}
                        id={saralyId}
                        open={saralyOpen}
                        anchorEl={saralyExe}
                        handleClose={saralyHandleClose}
                        message={
                            <p>
                                時給やボーナス、交通費などに関する情報を詳しくご記入ください。<br/>
                                ・時給は上がっていくのか？<br />
                                ・研修期間は時給どのくらいなのか？<br />
                                ・毎月支給なのか、日払い、週払いなのか？<br/>
                                ・支給日はいつなのか？<br/>
                                ・土日祝日は時給が上がるのか？<br />
                                ・ボーナスはあるのか etc...<br />
                                <strong>記入例</strong><br/>
                                時給900円~ + 交通費支給昇給あり<br />
                                研修期間1ヶ月<br />
                                ※研修期間の時給は880円です<br />
                                支払い方法・月払い<br />
                                毎月15日に給料はお支払いします.<br/>
                                <span style={{color: "red", fontSize: 12}}>※改行部分は店舗詳細画面にもそのまま反映されます。</span>
                            </p>
                        }
                    />     
                    </div>
                    <TextField
                        multiline={true} className={classes.point} variant="outlined" name='saralyform'
                        rows={6} value={saralyDetails} type="text" onChange={inputSaralyDetails} 
                        placeholder='時給900円以上＋交通費全額支給昇給あり&#13;&#10;
                                ・22時以降は時給25％UP&#13;&#10;
                                ※研修中も同時給支払方法&#13;&#10;
                                ：月1回交通費&#13;&#10;
                                ：別途全額支給'
                    />
                    </div>
                </div>
                <div className={classes.pointDetails}>
                    <div className={classes.point}>
                    <div style={{display: "flex"}}>
                    <p>シフト詳細情報</p>
                    <PopoverLabel 
                        handleClick={shiftHandleOpen}
                        id={shiftId}
                        open={shiftOpen}
                        anchorEl={shiftExe}
                        handleClose={shiftHanldeClose}
                        message={
                            <p>
                                希望する出勤日数や出勤時間、シフトに関する情報などを詳しくご記入ください。<br />
                                ・シフトをどういった形で決めるのか？<br />
                                ・テスト期間はお休みをもらえるのか？<br />
                                ・週に何日出勤しないといけないのか？<br />
                                ・短い時間でも応募可能なのか？ etc...<br />
                                <strong>記入例</strong><br />
                                ・シフト自己申告(毎月1回提出)<br />
                                ・勤務時間09:00 ~ 21:00までの間で4時間程度、フルタイムも大歓迎です！<br />
                                ・シフト週2・3日以上平日のみもOK！<br />
                                土・日・祝日は大歓迎です！<br />
                                学校のテスト期間に合わせたお休み、友達と旅行に行きたい！<br />
                                などのお休みは柔軟に対応します！<br />
                                あなたのライフスタイルに合わせた働き方を一緒に探しましょう！<br />
                                <span style={{color: "red", fontSize: 12}}>※改行部分は店舗詳細画面にもそのまま反映されます。</span>
                            </p>
                        }
                    />     
                    </div>
                    <TextField
                        multiline={true} className={classes.point} variant="outlined" 
                        rows={10} value={shiftDetails} type="text" onChange={inputShiftDetails} 
                        placeholder='週1日以上、1日3時間以上&#13;&#10;
                                    シフト自由・自己申告（14日ごとに提出）&#13;&#10;
                                    &#13;&#10;
                                    【勤務時間】&#13;&#10;
                                    全日 9:00～21:00&#13;&#10;
                                    ※上記時間内で3h~勤務可能&#13;&#10;
                                    &#13;&#10;
                                    ≪たとえば、こんな働き方も◎≫&#13;&#10;
                                    ・家事が落ち着いた午後に出勤！&#13;&#10;
                                    ・学校帰りにアルバイト♪&#13;&#10;
                                    ・Wワークでランチ後に勤務☆&#13;&#10;
                                    ※急なお休みや長期休みも相談OK'
                    />
                    </div>
                </div>
                <div className={classes.pointDetails}>
                    <div className={classes.point}>
                    <div style={{display: "flex"}}>
                    <p>福利厚生詳細情報</p>
                    <PopoverLabel 
                        handleClick={welfareHandleOpen}
                        id={welfareId}
                        open={welfareOpen}
                        anchorEl={welfareExe}
                        handleClose={welfareHandleClose}
                        message={
                            <p>
                                福利厚生・待遇、服装等の説明を記入してください<br />
                                <strong>記入例</strong><br />
                                ・まかない無料<br />
                                ・交通費支給<br />
                                ・有給取得可能<br />
                                ・制服貸与<br />
                                ・昇給あり<br />
                                <span style={{color: "red", fontSize: 12}}>※改行部分は店舗詳細画面にもそのまま反映されます。</span>
                            </p>
                        }
                    />   
                    </div>
                    <TextField
                        multiline={true} className={classes.point} variant="outlined" 
                        rows={8} value={welfareDetails} type="text" onChange={inputWelfareDetails} 
                        placeholder='おいしい"まかない"が自慢です！&#13;&#10;
                                    「まかないが食べたいからシフトを入れちゃおう！」もOK◎&#13;&#10;
                                    　一人暮らしの強い味方！&#13;&#10;
                                    ◆毎月社員登用のチャンスあり！登用実績も多数！&#13;&#10;
                                    　「ゆくゆくは正社員に･･･」という方も大歓迎！&#13;&#10;
                                    ◆丁寧な"研修制度"あり！&#13;&#10;
                                    　やさしくしっかり教えます◎&#13;&#10;
                                    ◆友達紹介制度で2万円支給中！※規定あり'
                    />
                    </div>
                </div>
                <div className={classes.pointDetails}>
                    <div className={classes.point}>
                    <div style={{display: "flex"}}>
                    <p>勤務期間</p>
                    <PopoverLabel 
                        handleClick={timeHandleOpen}
                        id={timeId}
                        open={timeOpen}
                        anchorEl={timeExe}
                        handleClose={timeHandleClose}
                        message={
                            <p>
                                応募するバイト期間をご記入ください<br />
                                1ヶ月以内→短期<br />
                                1ヶ月以上→長期<br />
                                <strong>記入例</strong><br />
                                短期(1ヶ月以内)<br />
                                長期(1ヶ月以上)<br />
                                単発<br />
                                夏休み限定/春休み限定<br />
                                <span style={{color: "red", fontSize: 12}}>※短期と長期で採用後にお支払いいただく料金が異なりますので、ご注意ください</span><br />
                                <span className={classes.topromotion} onClick={() => dispatch(push('/promotion'))}>詳しくはこちら</span>
                            </p>
                        }
                    />   
                    </div>
                    <TextField
                        multiline={true} className={classes.point} variant="outlined" 
                        rows={6} value={partTimeDetails} type="text" onChange={inputPartTimeDetails} placeholder='長期（3ヶ月以上）'
                    />
                    </div>
                </div>
                <div className="module-spacer--small" />   
                <Paper className={classes.favorite}>
                <p className={classes.favoriteText}>理科大生<span style={{fontSize: 18}}>必見</span>項目</p>
                    <div className={classes.ragio}>
                        <div style={{display: "flex"}}>
                        <p>理科大生在籍(有無)</p>
                        <PopoverLabel 
                            handleClick={studentsHandleOpen}
                            id={studentsId}
                            open={studentsOpen}
                            anchorEl={studentsExe}
                            handleClose={studentsHandleClose}
                            message={
                                <ul>
                                    <li>今現役の理科大生が働いているかを教えて下さい。</li>
                                </ul>
                            }
                        />     
                        </div>
                        <RadioForm value={students} onChange={inputStudent} select={setStudents} label={"理科大生在籍"} />
                    </div>
                    <div className={classes.ragio}>
                        <div style={{display: "flex"}}>
                        <p>過去に理科大生在籍(有無)</p>
                        <PopoverLabel 
                            handleClick={studentspastHandleOpen}
                            id={studentspastId}
                            open={studentspastOpen}
                            anchorEl={studentspastExe}
                            handleClose={studentspastHandleClose}
                            message={
                                <ul>
                                    <li>過去に理科大生在籍されていたかどうか教えて下さい。</li>
                                </ul>
                            }
                        />     
                        </div>
                        <RadioForm value={studentspast} onChange={inputStudentpast} select={setStudentspast} label={"過去に理科大生在籍"} />
                    </div>
                    <div className={classes.ragio}>
                        <div style={{display: "flex"}}>
                        <p>ガクチカ協力</p>
                        <PopoverLabel 
                            handleClick={gakutikaHandleOpen}
                            id={gakutikaId}
                            open={gakutikaOpen}
                            anchorEl={gakutikaExe}
                            handleClose={gakutikaHandleClose}
                            message={
                                <p>
                                    ガクチカとは？<br />
                                    学生時代頑張ったこと、力を入れたことの略称です。<br />
                                    ガクチカは就職活動において必ず聞かれる内容であり、重要な項目の一つです。<br />
                                    当サイトを通して採用された学生さんに就職活動で話せるような経験をさせていただきたいと思っています。<br />
                                    <strong>例えば...</strong><br />
                                    お店のPOP作成(求人募集や商品紹介など)<br />
                                    業務効率化のための提案<br />
                                    新商品の提案 etc...<br />
                                </p>
                            }
                        />     
                        </div>
                        <GakutikaRadio value={gakutika} onChange={inputGakutika} select={setGakutika}/>
                    </div>
                    <div className={classes.ragio}>
                        <div style={{display: "flex"}}>
                        <p>薬学部歓迎店舗</p>
                        <PopoverLabel 
                            handleClick={pharmacyHandleOpen}
                            id={pharmacyId}
                            open={pharmacyOpen}
                            anchorEl={pharmacyExe}
                            handleClose={pharmacyHandleClose}
                            message={
                                <p>
                                    薬学部は工学部に比べてアルバイトに使う時間が少なく なってしまいます。<br />
                                    そこで薬学部生でも安心して働ける よう協力してくださる店舗を募集しています。<br />
                                     条件としては...<br />
                                    ・18時or19時からの勤務可能<br />
                                    ・週1.2日からOK<br />
                                    ・土日のみOK<br />
                                    ・テスト前考慮<br />
                                    ・1日2hからOK 上記の条件を満たす店舗様は薬学部歓迎の項目にチェックをお願いします。
                                </p>
                            }
                        />     
                        </div>
                        <PharmacyRadio value={pharmacy} onChange={inputPharmacy} select={setPharmacy}/>
                    </div>
                </Paper>   
                <div className="module-spacer--small" />     
                <button 
                    className={isValidRequiredInput(companyName, addressNum, address, introduce, category, saraly) && introduce.length <= 20 && times.length > 0 && contents.length > 0 && (saralyNight >= saraly*1.25 || saralyNight === "" || saralyNight !== "NaN") && saraly >= 857 ? (classes.button) : (classes.buttonDisabled)}
                    disabled={!isValidRequiredInput(companyName, addressNum, address, introduce, category, saraly) || introduce.length > 20 || times.length === 0 || contents.length === 0 || attendanceshift.length < 0 || (saralyNight < saraly*1.25  && saralyNight !== "") || saraly < 857}
                    onClick={() => dispatch(saveCompany( companyName, introduce, images, saraly, saralyNight, category, times, addressNum, address, station, range, contents, attendanceshift, url, message, shift, term, welfare, onlyone, ather, students, studentspast, gakutika, pharmacy, saralyDetails, shiftDetails, welfareDetails, partTimeDetails))}
                >
                    情報を登録する
                </button>
            </div>
            <div className="module-spacer--small" />                 
            </Paper>
        </div>
    )
}

export default CompanyInfo;
                        
                       
            



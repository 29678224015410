import { Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useCallback } from "react";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    checkIcon: {
        float: "right"
    },
    cellDetail: {
        width: 150,
    },
    cellContent: {
        width: 100
    },
    iconCell: {
        height: 50,
        width: 50
    },
    content: {
        display: "flex",
        flexFlow: "column",
    }
}))
const ContentsExe = (props) => {
    const classes = useStyles();
    const [content, setContent] = useState("ホールスタッフ");
    const [detail, setDetail] = useState("お客様の注文を取ったり、お会計などが主な仕事です.接客を通して、コミュニケーション力をつけることができます！人とお話をすることが苦手な人、接客の経験がない人でも安心してください！優しい先輩たちが一から丁寧に教えてくれます一緒にお店を盛り上げていきましょう！");

    return(
        <div className={classes.root}>
                <div className={classes.content}>
                    <TextField 
                        fullWidth={false} label="仕事内容" multiline={false} required={true}
                        rows={1} value={content} type="text" margin="dense"
                    />
                    <TextField 
                        fullWidth={false} label="詳細" multiline={true} required={true}
                        rows={5} value={detail} type="text" margin="dense"
                    />
                </div>
                <p className={classes.checkIcon}>
                    <div style={{display: "flex"}}>
                        <p>項目を追加</p><CheckCircleIcon />
                    </div>
                </p>
        </div>
    )
}

export default ContentsExe;
import { makeStyles, Typography, IconButton, OutlinedInput, InputAdornment, TextField, Paper } from '@material-ui/core';
import React, { useState, useCallback, useEffect} from 'react';
import FieldInput from '../UIkit/FIeldInput';
import { useDispatch } from 'react-redux';
import { signIn } from '../redux/company/operation';
import { push } from 'connected-react-router';
import NotSignInHeader from '../components/header/NotSignInHeader';
import Footer from '../components/footer/footer';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyle = makeStyles((theme) => ({
    root: {
        height: "100vh",
        backgroundColor: "#FCFCFC",   
        [theme.breakpoints.down('sm')]: {
            position: "relative"
        }        
    },
    card: {
        margin: "0 auto",      
        width: "100%",
        maxWidth: 650,
        padding: "50px 100px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        border: "solid 1px #707070",
        [theme.breakpoints.down('sm')]: {            
            width: "90%",
            position: "absolute",
            top: "50%",
            left: "50%",
            WebkitTransform: "translateY(-50%) translateX(-50%)",            
        },
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
    space_big:{
        height: 50,
    },
    space_small: {
        height: 15,
    },
    position: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    reset: {
        fontSize: 12,
        paddingLeft: 150,
        color: "#707070",
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            paddingLeft: 0,
            width: 200
        },
        '&:hover': {
            opacity: 0.8
        },
    },
    text: {
        cursor: "pointer",
        width: "max-content",
        fontSize: 12,
        color: "#707070",
        '&:hover': {
            opacity: 0.8
        },
    },
    field: {
        width: 300,
        marginTop: 0,
    },
    link: {
        display: "flex",
        flexFlow: "column",
        textAlign: "left",
        justifyContent: "start",
        marginRight: 190,
        color: "#707070",
    },
    list: {
        fontSize:12,
        cursor: "pointer",
        '&:hover': {
            opacity: 0.8
        },
    },   
    flexFlow: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start"
    },   
    phone_space: {        
        [theme.breakpoints.down('sm')]: {
            height: 100
        }
    },
    pc_space: {       
        height: 50, 
        [theme.breakpoints.down('sm')]: {
            height: 0
        }
    },
}))

const SignIn = () => {
    useEffect(() => {
        console.log("+", process.env.REACT_APP_FIREBASE_API_KEY)
    }, [])
    const classes = useStyle();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isRevealPassword, setIsRevealPassword] = useState(false);

    const togglePassword = () => {
        setIsRevealPassword((prevState) => !prevState);
    }
    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    }, [setEmail]);

    const inputPassword = useCallback((e) => {
        setPassword(e.target.value)
    }, [setPassword]);

    return(
        <div className="background">
            <NotSignInHeader />
            <div className={classes.space_big} />
            <div className={classes.space_big} />                     
            <div className={classes.pc_space} />            
            <div className={classes.pc_space} />            
            <Paper elevation={0} className={classes.card}>
            <div className={classes.flexFlow}>
            <p style={{fontSize: 14, color: "#707070"}}>ID(メールアドレス)</p>
            <TextField
             multiline={true} className={classes.field} variant="outlined" margin='dense'
             required={true} rows={1} value={email} type={"email"} onChange={inputEmail}
            />
            </div>
            <div className={classes.space_small} />
            <div className={classes.flexFlow}>
            <p style={{fontSize: 14, color: "#707070"}}>パスワード</p>            
            <OutlinedInput 
                id="outlined-adornment-password"
                margin='dense'                
                className={classes.field}             
                type={isRevealPassword ? "text" : "password"}
                value={password}
                onChange={inputPassword}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePassword}
                            edge="end"
                        >
                            {isRevealPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            />               
            </div>         
            <p onClick={() => dispatch(push('/signin/reset'))} className={classes.reset}>ID, パスワードを忘れた ＞＞</p>
            <div className={classes.space_small} />
            <button variant="contained" type="submit" className={classes.button} onClick={() => dispatch(signIn(email, password))}>ログイン</button>
            <div className={classes.space_small} />
            </Paper>
            <div className={classes.phone_space} />
            <Footer />
        </div>
    )
}

export default SignIn;
import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MenuIcon from "@material-ui/icons/Menu";
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { useDispatch } from 'react-redux';
import { signOut } from '../../redux/company/operation'
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    menu: {
        paddingLeft: 20,
        fontSize: 14,
        cursor: "pointer",
        color: "#707070"
    },
    menuLg: {
        display: "none",
        [theme.breakpoints.up('sm')]: {
          display: "block",
        }
      },
    menuSm: {
        display: "block",
        [theme.breakpoints.up('sm')]: {
          display: "none",
        }
    },
    layout: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 999
    },
    space: {
        height: 30,
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
    },
}))
const SideHeaderMenu = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles();        
    return (
        <>
        <div className={classes.layout}>
        <div className={classes.menuLg}>                                   
                <div className={classes.space} />                
                <ListItem button  onClick={() => dispatch(push("/companyinfo"))}>
                <ListItemIcon>
                    <SendIcon />
                </ListItemIcon>
                <ListItemText  primary="管理" />
                </ListItem>                
                <ListItem button onClick={() => dispatch(push('/confirmapplications'))}>
                  <ListItemIcon>                    
                      <InboxIcon />                    
                  </ListItemIcon>
                  <ListItemText primary="オファー" />                                
                </ListItem>              
                <ListItem button onClick={() => dispatch(push('/inteviewcontact'))}>
                  <ListItemIcon>                    
                      <InboxIcon />                    
                  </ListItemIcon>
                  <ListItemText primary="検討中" />                                
                </ListItem>              
                <ListItem button onClick={() => dispatch(push('/chat/login'))}>
                  <ListItemIcon>
                      <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="口コミ" />
                </ListItem>                                                                                             
                <ListItem button onClick={() => dispatch(push('/mapview'))}>
                  <ListItemIcon>
                      <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="プレビュー" />
                </ListItem>                                                                                             
                <ListItem button onClick={() => dispatch(push('/contactform/login'))}>
                  <ListItemIcon>
                      <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="お問い合わせ" />
                </ListItem>                                                                                             
                <ListItem button onClick={() => dispatch(signOut())} >
                  <ListItemIcon>
                      <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="サインアウト" />
                </ListItem>                                                                                             
        </div>
        <div className={classes.layout}>
                <IconButton
                    aria-label="Menu Items"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={(e) => props.menuDrawerToggle(e)}
                    className={classes.menuSm}
                >
                    <MenuIcon />
                </IconButton>
        </div>
        </div>
        </>
    );
};

export default SideHeaderMenu;
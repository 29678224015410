import { auth, db, FirebaseTimeStamp } from "../../firebase";
import { fetchPostsAction } from "./actions";
import { push } from "connected-react-router";

export const fetchPosts = () => {
    return async(dispatch, getState) => {
        const list = [];

        db.collection('posts')
            .orderBy('created_at', 'desc')
            .get()
            .then((snaphosts) => {
                snaphosts.forEach(snaphost => {
                    const data = snaphost.data();
                    list.push(data)
            })
            dispatch(fetchPostsAction(list))
        })
    }
}

export const addChat = (category, content) => {
    return async(dispatch) => {
        const companyid = auth.currentUser.uid
        const companyRef = db.collection('posts').doc();
        const timestamp = FirebaseTimeStamp.now();
        const chatContent = {
            category: category,
            content: content,
            created_at: timestamp,
            created_id: companyid
        }
        chatContent['postId'] = companyRef.id;
        await companyRef.set(chatContent, {merge: true});
        dispatch(push('/chat/login'))
    }
}
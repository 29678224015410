import React, { useState } from "react";
import { List, ListItem, Divider, Button } from "@material-ui/core";
import TextDetail from "../../UIkit/TextDetails";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme) => ({
   root: {
       width: 400,              
       height: "100vh",              
       paddingTop: 20
   }, 
   list: {
       height: "auto",              
       [theme.breakpoints.down('sm')]: {
            height: "auto"
        }
   },
   flex: {
       display: "flex",
       justifyContent: "space-between",
       alignItems: "center"
   },
   column: {
       display: "flex",
       flexFlow: "column"
   },
   button: {
       width: 100,
       marginLeft: 10,
       marginRight: 10,      
   },
   buttons: {
       display: "flex",
       flexFlow: "column"     
   }
}))
const InteviewContactItem = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const datetimeToString = (dt) => {
        return dt.getFullYear() + '-'
            + ('00' + (dt.getMonth()+1)).slice(-2) + '-'
            + ('00' + dt.getDate()).slice(-2) + ' '       
    };

    const inteviewcontact = props.inteviewcontact
    const inteviewcontactDateTime = datetimeToString(inteviewcontact.send_at.toDate());
    return(        
        <div>
            <List className={classes.list}>
                <>
                    <ListItem key={inteviewcontact.maildetailboxId} className={classes.flex}>
                        <div>
                            <TextDetail label={"応募日時"} value={inteviewcontactDateTime} />
                            <TextDetail label={"氏名"} value={inteviewcontact.from_firstName + inteviewcontact.from_lastName} />                                                                                      
                        </div> 
                        <div className={classes.buttons}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={() => dispatch(push('/inteviewcontact/' + inteviewcontact.maildetailboxId))}>詳細</Button>                                                                                      
                        </div>                   
                    </ListItem>
                </>
            </List>
            <div className="module-spacer--extra-small" />
            <Divider />
        </div>           
    )
}

export default InteviewContactItem;
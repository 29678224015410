import React, { useState, useCallback } from "react";
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography, makeStyles } from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import NoImage from '../../assets/img/no_image.png'
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { FirebaseTimeStamp } from "../../firebase";

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 360,
      margin: 10,
      height: 380
    },
    image: {
        objectFit: "cover",
        width: "100%",
        height: 200
    },
    bookMark: {
        marginRight: 5,
        cursor: "pointer",
        color: "red",
        '&:hover': {
            opacity: 0.6,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 5
        }
    },
    detailsbutton: {
        color: "#fff",
        padding: "3px 10px",
        borderRadius: 15,
        backgroundColor: "#FF9D00",
        width: "fit-content",
        fontSize: 14,
        cursor: "pointer",
        '&:hover': {
            opacity: 0.7,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
  }));

const YumetownListItem = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const [selectedCompany, setSelectedCompany] = useState(null)
    const yumetown = props.yumetown
    const images = props.yumetown.images.length === 0 ? (
                        NoImage
                    ) : (
                            props.yumetown.images[0].path
                    )

    return(
        <Card className={classes.root}>
        <CardActionArea onClick={() => dispatch(push('/companydetail/' + yumetown.companyid))}>
            <CardMedia
            component="img"
            alt="店舗写真"
            image={images}
            className={classes.image}
            />
            <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
                {yumetown.companyName}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
                {yumetown.introduce}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
                {"時給 " + yumetown.saraly + "円~"}
            </Typography>
            </CardContent>
        </CardActionArea>
        <CardActions>
                <>
                    <p size="small" className={classes.detailsbutton} onClick={() => dispatch(push('/companydetail/' + yumetown.companyid))}>
                        詳細を見る
                    </p>
                </>    
        </CardActions>
    </Card>
    )
}

export default YumetownListItem;
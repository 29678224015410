import React from 'react';

const ImagePreview = (props) => {
    return(
        <div onClick={() => props.delete(props.id)}>
            <img alt="店舗の写真です" src={props.path}/>
        </div>
    )
}

export default ImagePreview;
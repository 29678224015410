import React, { useContext, useState } from "react";
import { makeStyles, Paper, FormControlLabel, Checkbox } from "@material-ui/core";
import { multiStepContext } from "../../SteoContext";
import Promis from "../../assets/pdf/promis.pdf";

const useStyles = makeStyles((theme) => ({
    card: {
        width: 600,
        padding: 30,
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        border: "solid 1px #707070", 
        [theme.breakpoints.down('sm')]: {            
            width: "90%",    
            margin: "0 auto",
        },
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
    buttonDisabled: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#ccc",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
    },
}))
const Agreement = () => {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const handleClick = () => {
        setChecked(checked?false:true)
    }
    const { setCurrentStep } = useContext(multiStepContext)
    return(
        <div>
            <div className={classes.form}>
            <Paper elevation={0} className={classes.card}>           
            <h2 className={classes.title}>契約内容</h2>
            <FormControlLabel
                value="end"
                control={<Checkbox color="primary" />}
                label="上記の契約に同意する"
                labelPlacement="agree"
                onClick={handleClick}
            />
            <div className='module-spacer--extra' /> 
            <div>
                <a href={Promis} download={Promis}>
                    <button disabled={checked !== true} className={checked === true && classes.button || checked !== true && classes.buttonDisabled} onClick={() => setCurrentStep(2)}>次へ</button>
                </a>
            </div>
            </Paper>
        </div>
        </div>
    )
}

export default Agreement;
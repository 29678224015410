// Storesの初期状態を管理、アプリに必要なStateを記述
const initialState = {
    loading: {
        state: false,
        text: ""
    },
    companys: {
        companyinfo: [],
        isSignedIn: false,
        mails: [],
        maildetails: [],
        role: "",
        companyid: "",
        companyName: "",
        sanpark: [],
        yumetown: [],
    },
    posts: {
        posts: [],
    }
}

export default initialState;
import { createSelector } from "reselect";

const companysSelector = (state) => state.companys;

export const getCompanys = createSelector(
    [companysSelector],
    state => state.companyinfo //initialState(company)のlistを展開している
)

export const getCompanyMails = createSelector(
    [companysSelector],
    state => state.mails //initialState(company)のlistを展開している
)

export const getCompanyMailDetails = createSelector(
    [companysSelector],
    state => state.maildetails //initialState(company)のlistを展開している
)

export const getIsSignedInCompany = createSelector(
    [companysSelector],
    state => state.isSignedIn
)

export const getCompanyId = createSelector(
    [companysSelector],
    state => state.companyid
)

export const getCompanyRole = createSelector(
    [companysSelector],
    state => state.role
)

export const getComapnyName = createSelector(
    [companysSelector],
    state => state.companyName, //initialState(user)のuidを展開している
)

export const getSanpark = createSelector(
    [companysSelector],
    state => state.sanpark //initialState(company)のlistを展開している
)
export const getYumetown = createSelector(
    [companysSelector],
    state => state.yumetown //initialState(company)のlistを展開している
)
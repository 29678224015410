import { Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, IconButton, TextField, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import SelectBox from "./SelectBox";
import { useContext } from "react";
import { multiStepContext } from "../SteoContext";

const weekdays = [
    {id: "zero", name: "平日"},
    {id: "one", name: "土・日・祝"},
    {id: "one", name: "全曜日"},
]
const hour = [
    {id: "zero", name: "00"},
    {id: "one", name: "01"},
    {id: "two", name: "02"},
    {id: "three", name: "03"},
    {id: "four", name: "04"},
    {id: "five", name: "05"},
    {id: "six", name: "06"},
    {id: "seve", name: "07"},
    {id: "eight", name: "08"},
    {id: "nine", name: "09"},
    {id: "ten", name: "10"},
    {id: "eleven", name: "11"},
    {id: "twelve", name: "12"},
    {id: "thirteen", name: "13"},
    {id: "fourteen", name: "14"},
    {id: "fifteen", name: "15"},
    {id: "sixteen", name: "16"},
    {id: "seventeen", name: "17"},
    {id: "eighteen", name: "18"},
    {id: "nineteen", name: "19"},
    {id: "twenty", name: "20"},
    {id: "twentyone", name: "21"},
    {id: "twentytwo", name: "22"},
    {id: "twentythree", name: "23"},
    {id: "twentyfour", name: "24"},
]
const minutes = [
    {id: "zero", name: "00"},
    {id: "one", name: "10"},
    {id: "two", name: "20"},
    {id: "three", name: "30"},
    {id: "four", name: "40"},
    {id: "five", name: "50"},
]
const useStyles = makeStyles((theme) => ({
    root: {
        width: 400,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    checkIcon: {
        display: "flex",
        padding: "5px 10px",
        color: "#fff",
        backgroundColor: "red",
        border: "none",
        borderRadius: 5,
        '&:hover': {
            opacity: 0.6
        },
    },
    iconCell: {
        height: 10,
        width: 10
    },
    content: {
        display: "flex",
        flexFlow: "column"
    },
    field: {
        width: 65
    },
    week: {
        width: 200
    },
    flex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 10
    },
    saralyfield: {
        width: 200,
        borderRadius: 5,
        padding: 5,
    },
}))
const SetJobTime = (props) => {
    const { saraly, saralyNight } = useContext(multiStepContext)
    const classes = useStyles();
    const [index, setIndex] = useState(0)
    const [weekday, setWeekday] = useState([]);
    const [startMinutes, setStartMinutes] = useState([]);
    const [startHour, setStartHour] = useState([]);
    const [finishMinutes, setFinishMinutes] = useState([]);
    const [finishHour, setFinishHour] = useState([]);
    const [saralycontent, setSaralycontent] = useState("");

    const editContent = (index, weekday, startMinutes, startHour, finishMinutes, finishHour, saralycontent) => {
        setIndex(index)
        setWeekday(weekday)
        setStartMinutes(startMinutes)
        setStartHour(startHour)
        setFinishMinutes(finishMinutes)
        setFinishHour(finishHour)
        setSaralycontent(saralycontent)
    };

    const inputSaralycontent = useCallback((event) => {
        setSaralycontent(event.target.value)
    }, [setSaralycontent]);

    const addContent = (index, weekday, startHour, startMinutes, finishHour, finishMinutes, saralycontent) => {
        if(startHour === "" || startMinutes === "" || finishHour === "" || finishMinutes === "" || saralycontent === "") {
            return false
        } else if(parseInt(saralycontent) < 857) {
            return false
        } else if(parseInt(saralycontent) < parseInt(saraly)) {
            return false
        } else if(startHour > 22 && parseInt(saralycontent) < parseInt(saralyNight)) {
            return false
        } else {
            if(index === props.times.length) {
                props.setTimes(preveState => [...preveState, {weekday: weekday, startHour: startHour, startMinutes: startMinutes, finishHour: finishHour, finishMinutes: finishMinutes, saralycontent: saralycontent}])
                setIndex(index + 1)
                setWeekday("")
                setStartMinutes("")
                setStartHour("")
                setFinishMinutes("")
                setFinishHour("")
                setSaralycontent("")
            } else {
                const newTimes = props.times
                newTimes[index] = {weekday: weekday, startHour: startHour, startMinutes: startMinutes, finishHour: finishHour, finishMinutes: finishMinutes, saralycontent: saralycontent}
                props.setTimes(newTimes)
                setIndex(newTimes.length)
                setWeekday("")
                setStartMinutes("")
                setStartHour("")
                setFinishMinutes("")
                setFinishHour("")
                setSaralycontent("")
            }
        } 
    }

    useEffect(() => {
        setIndex(props.times.length)
    }, [props.times.length])
    const deleteContent = (deleteIndex) => {
        const newTimes = props.times.filter((item, i) => i !== deleteIndex);
        props.setTimes(newTimes)
    }
    return(
        <div className={classes.root}>
                {props.times.length > 0 && (
                    props.times.map((item, i) => (
                        <Paper className={classes.flex}>
                        <div className={classes.content}>
                            <h2 style={{textAlign: "left"}}>{"項目" +( i + 1)}</h2>
                            <div className={classes.flex}>
                                <h2>曜日<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                                <p>{item.weekday}</p>
                            </div>
                            <div className={classes.flex}>
                                <h2>開始時刻<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                                <p>{item.startHour + " : " + item.startMinutes}</p>
                            </div>
                            <div className={classes.flex}>
                                <h2>終了時刻<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                                <p>{item.finishHour + " : " + item.finishMinutes}</p>
                            </div>
                            <div className={classes.flex}>
                                <h2>時給<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                                <p>{item.saralycontent + "円"}</p>
                            </div>
                        </div>
                            <div className={classes.content}>
                                <div className={classes.flex}>
                                    <p>編集</p>
                                    <IconButton className={classes.iconCell} onClick={() => editContent(i, item.weekday, item.startMinutes, item.startHour, item.finishMinutes,  item.finishHour,  item.saralycontent)}>
                                        <EditIcon />
                                    </IconButton>
                                </div>
                                <div className={classes.flex}>
                                    <p>消去</p>
                                    <IconButton className={classes.iconCell} onClick={() => deleteContent(i)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                       </Paper>
                    ))
                    )}
                    <Divider />
                <div className={classes.content}>
                    <div className={classes.flex}>
                        <h2>曜日<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                        <SelectBox 
                            options={weekdays} required={false} value={weekday} select={setWeekday} className={classes.week}
                        />
                    </div>
                    <div className={classes.flex}>
                        <h2>開始時刻<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                        <SelectBox 
                            options={hour} required={false} value={startHour} select={setStartHour} className={classes.field}
                        />
                        <span style={{marginRight: 5, marginLeft: 5}}>時</span>
                        <SelectBox 
                            options={minutes} required={false} value={startMinutes} select={setStartMinutes} className={classes.field}
                        />
                        <span style={{marginRight: 5, marginLeft: 5}}>分</span>
                    </div>
                    <div className={classes.flex}>
                        <h2>終了時刻<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                        <SelectBox 
                            options={hour} required={false} value={finishHour} select={setFinishHour} className={classes.field}
                        />
                        <span style={{marginRight: 5, marginLeft: 5}}>時</span>
                        <SelectBox 
                            options={minutes} required={false} value={finishMinutes} select={setFinishMinutes} className={classes.field}
                        />
                        <span style={{marginRight: 5, marginLeft: 5}}>分</span>
                    </div>
                    <div className={classes.flex}>
                    <h2>時給<strong style={{marginRight: 5, marginLeft: 5}}>:</strong></h2>
                        <input
                            variant="outlined" className={classes.saralyfield} margin='dense' step={10} min={850}
                            required={true} rows={1} value={saralycontent} type="number" onChange={inputSaralycontent} placeholder='山口県最低賃金857円'
                        />  
                    </div>
                </div>
                {finishHour - startHour > 10 ? (
                    <p style={{fontSize: 12, color: "blue", textAlign: "left"}}>※労働時間が8時間を超える可能性があります</p>
                ) : (
                    <p style={{display: "none"}}></p>
                )}
                 {parseInt(saralycontent) < 857 && saralycontent !== "" ? (
                    <p style={{fontSize: 12, color: "blue", textAlign: "left"}}>※最低賃金を下回っています。</p>
                ) : (
                    <p style={{display: "none"}}></p>
                )}      
                 {parseInt(saralycontent) < parseInt(saraly) && saralycontent !== "" ? (
                    <p style={{fontSize: 12, color: "blue", textAlign: "left"}}>※設定した賃金を下回っています</p>
                ) : (
                    <p style={{display: "none"}}></p>
                )}      
                 {startHour >= 22 && parseInt(saralycontent) < parseInt(saralyNight) ? (
                    <p style={{fontSize: 12, color: "blue", textAlign: "left"}}>※深夜料金の設定を下回っています</p>
                ) : (
                    <p style={{display: "none"}}></p>
                )}      
                <button className={classes.checkIcon} onClick={() => addContent(index, weekday, startHour, startMinutes, finishHour, finishMinutes, saralycontent)}>
                    <p>登録</p><CheckCircleIcon />
                </button>
        </div>
    )
}

export default SetJobTime;
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SanparkListItem from "../../components/companys/SanparkListItem";
import { makeStyles } from "@material-ui/core";
import { fetchSanpark } from "../../redux/company/operation";
import { getSanpark } from "../../redux/company/selectors";
import SideHeader from "../../components/header/SideHeader";
import Footer from "../../components/footer/footer";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: "#EBEBEB",
        height: "100vh",
    },
    item: {
        display: "flex",
        flexWrap: "wrap",                    
        [theme.breakpoints.down('sm')]: {
            marginTop: 80,
            margin: "0 auto",
            flexFlow: "column",
            alignItems: "center",
        }
    }
})) 

const Sanpark = () => {
    const classes = useStyles();
    const selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const getSanparks = getSanpark(selector)

    useEffect(() => {
        dispatch(fetchSanpark());
    }, [])

    return(
        <div className={classes.root}>
            <SideHeader />
            <div className={classes.item}>
                {getSanparks.length > 0 && (
                    getSanparks.map((sanpark) => <SanparkListItem key={sanpark.companyid} sanpark={sanpark} /> )
                )}
            </div>
        </div>
    )
}

export default Sanpark;
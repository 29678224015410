import React from "react";
import { makeStyles } from "@material-ui/core";
import SideHeader from "../components/header/SideHeader";
import Map from "../components/maps/Map/Map";

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      backgroundColor: "#EBEBEB",
      height: "100vh",
      [theme.breakpoints.down('sm')]: {
        width: "100%",      
        height: "auto",
        display: "block"        
      },
    },  
    content: {
      width: "95%",
      [theme.breakpoints.down('sm')]: {                     
        width: "100%",
        margin: "0 auto",
        padding: 0
     },
    },
    space: {
      height: 15
    },
    phone_space: {
      [theme.breakpoints.down('sm')]: {
        height: 30
      }
    }
  }));
const MapView = () => {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <SideHeader />
            <main className={classes.content}>
                <Map />
            </main>
        </div>
    )
}

export default MapView;
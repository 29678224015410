import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText, Drawer } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { signOut } from '../../redux/company/operation';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const useStyles = makeStyles((theme) => ({
  drawer: {
      [theme.breakpoints.up('sm')]: {
          width: "100%",          
      }
  },        
  drawerPaper: {
      width: "100%",      
  },
  textColor: {
    color: "#fff"
  },
  list: {
    backgroundColor: "#FF9D00",
  }
}));

const SideDrower = (props) => {
    const { container } = props;
    const dispatch = useDispatch();    
    const classes = useStyles();
  
    return (
      <Drawer
        container={container}
        variant="temporary"
        anchor="top"
        open={props.open}
        onClose={(e) => props.onClose(e)}
        classes={{paper: classes.drawerPaper}}        
        ModalProps={{keepMounted: true}}
      >
    <div
      onClose={(e) => props.onClose(e)}
      onKeyDown={(e) => props.onClose(e)}
    > 
      <List component="nav" aria-label="main mailbox folders" className={classes.list}>
        <ListItem button>
          <ListItemIcon>
              <SendIcon />
          </ListItemIcon>
          <ListItemText className={classes.textColor} primary="管理" onClick={() => dispatch(push("/companyinfo"))} />
          </ListItem>                
          <ListItem button>
            <ListItemIcon>                    
                <InboxIcon />                    
            </ListItemIcon>
            <ListItemText className={classes.textColor} primary="オファー" onClick={() => dispatch(push('/confirmapplications'))} />                                
          </ListItem>        
          <ListItem button>
            <ListItemIcon>                    
                <InboxIcon />                    
            </ListItemIcon>
            <ListItemText className={classes.textColor} primary="検討中" onClick={() => dispatch(push('/inteviewcontact'))} />                                
          </ListItem>        
          <ListItem button>    
            <ListItemIcon>                    
                <InboxIcon />                    
            </ListItemIcon>
            <ListItemText className={classes.textColor} primary="口コミ" onClick={() => dispatch(push('/chat/login'))} />                                
          </ListItem>            
          <ListItem button>    
            <ListItemIcon>                    
                <InboxIcon />                    
            </ListItemIcon>
            <ListItemText className={classes.textColor} primary="プレビュー" onClick={() => dispatch(push('/mapview'))} />                                
          </ListItem>            
          <ListItem button onClick={() => dispatch(push('/contactform/login'))}>
                  <ListItemIcon>
                      <SendIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.textColor} primary="お問い合わせ" />
                </ListItem>          
          <ListItem button>
            <ListItemIcon>
                <SendIcon />
            </ListItemIcon>
            <ListItemText className={classes.textColor} primary="サインアウト" onClick={() => dispatch(signOut())} />
        </ListItem>   
      </List>
      </div>
      </Drawer>
    );
  }
  
  export default SideDrower;
  
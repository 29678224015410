import React, {useContext} from "react";
import { Stepper, StepLabel, Step, makeStyles } from "@material-ui/core";
import BasicInfo from "../components/MultiStep/BasicInfo";
import MandatoryInfo from "../components/MultiStep/MandatoryInfo";
import Any from "../components/MultiStep/Any";
import { multiStepContext } from "../SteoContext";
import Confirm from "../components/MultiStep/Confirm";
import NotSignInHeader from "../components/header/NotSignInHeader";
import FooterVar2 from "../components/footer/footer_ver2"
import AnyDetails from "../components/MultiStep/AnyDetails";
import Agreement from "../components/MultiStep/Agreement";

const useStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexFlow: "column",
        backgroundColor: "#FCFCFC",   
        alignItems: "center",
    },
    stepper: {
        width: "30%",
        [theme.breakpoints.down('sm')]: {
            width: 300
        }
    },
    steplabel: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    }
}))

const SignUp = () => {
    const classes = useStyle();
    const {currentstep} = useContext(multiStepContext);
    function showStep (step) {
        switch(step) {
            case 1 : 
                return <BasicInfo />
            case 2 : 
                return <MandatoryInfo />
            case 3 :
                return <Any />
            case 4 : 
                return <AnyDetails />
            case 5 : 
                return <Confirm />
        }
    }
    return(
        <div>
        <NotSignInHeader />
        <div className="module-spacer--medium" />
        <div className={classes.root}>
          <Stepper className={classes.stepper} activeStep={currentstep - 1} orientation="horizontal">
              <Step>
                  <StepLabel></StepLabel>
              </Step>
              <Step>
                  <StepLabel></StepLabel>
              </Step>
              <Step>
                  <StepLabel></StepLabel>
              </Step>
              <Step>
                  <StepLabel></StepLabel>
              </Step>
              <Step>
                  <StepLabel></StepLabel>
              </Step>
          </Stepper>
          {showStep(currentstep)}
        </div>
        <div className="module-spacer--small" />
        <FooterVar2 />
        </div>
    )
}

export default SignUp;
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import YumetownListItem from "../../components/companys/YumetownListItem";
import { fetchYumetown } from "../../redux/company/operation";
import { getYumetown } from "../../redux/company/selectors";
import { makeStyles } from "@material-ui/core";
import SideHeader from "../../components/header/SideHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: "#EBEBEB",
        height: "100vh",
    },
    item: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down('sm')]: {
            marginTop: 80,
            margin: "0 auto",
            flexFlow: "column",
            alignItems: "center",
        }
    }
})) 

const Yumetown = () => {
    const classes = useStyles();
    const selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const getYumetowns = getYumetown(selector)

    useEffect(() => {
        dispatch(fetchYumetown());
    }, [])

    return(
        <div className={classes.root}>
            <SideHeader />
            <div className={classes.item}>
                {getYumetowns.length > 0 && (
                    getYumetowns.map((yumetown) => <YumetownListItem key={yumetown.companyid} yumetown={yumetown} /> )
                )}
            </div>
        </div>
    )
}

export default Yumetown;
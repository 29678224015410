import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCompanyMailDetails } from "../redux/company/selectors";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import SideHeader from "../components/header/SideHeader";
import { useDispatch } from "react-redux";
import { fetchCompanyMailDetails } from "../redux/company/operation";
import InteviewContactItem from "../components/companys/InteviewContactItem";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: "#EBEBEB",
        height: "100vh",           
    },      
    paper: {
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            marginTop: 50,
            width: "100%"
        }   
    }
}))

const Inteviewcontact = () => {
    const classes = useStyles();    
    const selector = useSelector((state) => state)
    const dispatch = useDispatch();
    const inteviewcontacts = getCompanyMailDetails(selector);

    useEffect(() => {
        dispatch(fetchCompanyMailDetails())
    }, [])
    
    return(
        <div className={classes.root}>
        <SideHeader />
        <Paper className={classes.paper} elevation={0}>
            {inteviewcontacts.length > 0 && (
                inteviewcontacts.map((inteviewcontact) => <InteviewContactItem inteviewcontact={inteviewcontact} key={inteviewcontact.maildetailboxId}/>)
            )}
        </Paper>              
    </div>
    )
}

export default Inteviewcontact;
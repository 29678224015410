
export const FETCH_COMPANYS = "FETCH_COMPANYS";
export const fetchCompanysAction = (companys) => {
    return {
        type: "FETCH_COMPANYS",
        payload: companys
    }
};

export const FETCH_MAILS = "FETCH_MAILS";
export const fetchCompanyMailsAction = (mails) => {
    return {
        type: "FETCH_MAILS",
        payload: mails
    }
};

export const FETCH_MAIL_DETAILS = "FETCH_MAIL_DETAILS";
export const fetchCompanyMailDetailsAction = (maildetails) => {
    return {
        type: "FETCH_MAIL_DETAILS",
        payload: maildetails
    }
};

export const SIGN_IN = "SIGN_IN";
export const signInAction = (userState) => {
    return{
        type: "SIGN_IN",
        payload: {
            isSignedIn: true,
            role: userState.role,
            companyid: userState.companyid,
            companyName: userState.companyName
        }
    }
};

export const SIGN_OUT = "SIGN_OUT";
export const signOutAction = () => {
    return{
        type: "SIGN_OUT",
        payload: {
            isSignedIn: false,
            role: "",
            companyid: "",
            companyName: "",
        }
    }
};

export const FETCH_SANPARK = "FETCH_SANPARK";
export const fetchSanparkAction = (sanpark) => {
    return {
        type: "FETCH_SANPARK",
        payload: sanpark
    }
};
export const FETCH_YUMETOWN = "FETCH_YUMETOWN";
export const fetchYumetownAction = (yumetown) => {
    return {
        type: "FETCH_YUMETOWN",
        payload: yumetown
    }
};
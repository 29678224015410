import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React from 'react';

const usestyle = makeStyles((theme) => ({
    field: {
        width: 200,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: 200,
        },
    },
}))

const SelectBox = (props) => {
    const classes = usestyle();
    return(
        <FormControl className={classes.formControl}>
            <InputLabel>{props.label}</InputLabel>
            <Select
             required={props.required} value={props.value} placeholder={props.placeholder} className={props.className}
             onChange={(event) => props.select(event.target.value)} variant="outlined" margin='dense'
            >
                {props.options.map((option) => (
                    <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectBox;
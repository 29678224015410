import React from 'react';
import {Route, Switch} from 'react-router'
import CompanyHome from './templates/CompanyHome';
import SignIn from './templates/SignIn';
import Reset from './templates/Reset';
import Auth from './Auth';
import CompanyInfo from './templates/CompanyInfo';
import CompanyPro from './templates/CompanyPro';
import ConfirmApplications from './templates/ConfirmApplications';
import ApplicationDetail from './templates/ApplicationDetail';
import TermOfService from './templates/TermOfService';
import PrivasyPolicy from './templates/PrivasyPolicy'
import SignUp from './templates/SignUp';
import Contact from './templates/Contact';
import PrivacyStatements from './templates/PrivacyStatements';
import PoricyTermPage from './templates/PoricyTermPage';
import CompanyOverview from './templates/CompanyOverview';
import ChatLogin from './templates/ChatLogin';
import Promotion from './templates/Promotion';
import Inteviewcontact from './templates/InteviewContact';
import ApplicationDetailInteview from './templates/ApplicationDetailInteview';
import MapView from './templates/MapView';
import CompanyDetails from './templates/CompanyDetails';
import Sanpark from './templates/complex/Sanpark';
import Yumetown from './templates/complex/Yumetown';

const Router = () => {
   return(
       <Switch>
           <Route exact path={"/"} component={SignIn} />
           <Route exact path={"/signup"} component={SignUp} />
           <Route exact path={"/signin/reset"} component={Reset} />
           <Route exact path={"/poricyandtermpage"} component={PoricyTermPage} />
           <Route exact path={"/privacystatements"} component={PrivacyStatements} />
           <Route exact path={"/privasypolicy"} component={PrivasyPolicy} />
            <Route exact path={"/termofservice"} component={TermOfService} />
            <Route exact path={"/contactform"} component={Contact} />
            <Route exact path={"/companyoverview"} component={CompanyOverview} />
            <Route exact path={"/promotion"} component={Promotion} />
           <Auth>
                <Route exact path={"/sanparkshops"} component={Sanpark} />
                <Route exact path={"/yumetownshops"} component={Yumetown} />
                <Route exact path={"/mapview"} component={MapView} />
                <Route exact path={"/companydetail/:id"} component={CompanyDetails} />
                <Route exact path={"/confirmapplications/:id"} component={ApplicationDetail} />
                <Route exact path={"/inteviewcontact/:id"} component={ApplicationDetailInteview} />
                <Route exact path={"/confirmapplications"} component={ConfirmApplications} />
                <Route exact path={"/inteviewcontact"} component={Inteviewcontact} />
                <Route exact path={"/companypro"} component={CompanyPro} />                
                <Route path={"/companyinfo"} component={CompanyInfo} />
                <Route exact path={"/companyhome"} component={CompanyHome} />
                <Route exact path={"/chat/login"} component={ChatLogin} />
                <Route exact path={"/contactform/login"} component={Contact} />
           </Auth>
       </Switch>
   )
}

export default Router;
import React, { useState } from "react";
import { List, ListItem, Divider, Button } from "@material-ui/core";
import TextDetail from "../../UIkit/TextDetails";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { push } from "connected-react-router";
import { auth, db } from "../../firebase";
import { getCompanyId } from "../../redux/company/selectors";
import { showLoadingAction, hideLoadingAction } from "../../redux/loading/actions";
import { useEffect } from "react";
import { init, send } from "emailjs-com";

const useStyles = makeStyles((theme) => ({
   root: {
       width: 400,              
       height: "100vh",              
       paddingTop: 20
   }, 
   list: {
       height: "auto",              
       [theme.breakpoints.down('sm')]: {
            height: "auto"
        }
   },
   flex: {
       display: "flex",
       justifyContent: "space-between",
       alignItems: "center"
   },
   column: {
       display: "flex",
       flexFlow: "column"
   },
   button: {
       width: 100,
       marginLeft: 10,
       marginRight: 10,      
   },
   buttons: {
       display: "flex",
       flexFlow: "column"     
   }
}))
const ConfirmApplicationItem = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [company, setCompany] = useState(null)
    const selector = useSelector((state) => state)
    const datetimeToString = (dt) => {
        return dt.getFullYear() + '-'
            + ('00' + (dt.getMonth()+1)).slice(-2) + '-'
            + ('00' + dt.getDate()).slice(-2) + ' '       
    };

    const removeApplicationUser = (id) => {
        const companyid = getCompanyId(selector);
        return db.collection('companys').doc(companyid)
                 .collection('mailbox').doc(id)
                 .delete()
                 .then(() => {
                     dispatch(push('/confirmapplications'))
                 })
    }

    useEffect(() => {
        const companyid = auth.currentUser.uid;
        db.collection('companys').doc(companyid).get()
            .then(doc => {
                const data = doc.data()
                setCompany(data)
            })
    }, [])

    const sendMail = () => {
        dispatch(showLoadingAction('面接完了受理中...'))
        const userID = process.env.REACT_APP_EMAIL_JS_USER_ID;
        const serviceID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
        const templateID = process.env.REACT_APP_EMAIL_JS_CONTACT_TEMPLATE_ID;

        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(userID);
            const template_param = {
                companyName: company.companyName,
                firstName: confirmApplication.firstName,
                lastName: confirmApplication.lastName,
            }; 

            send(serviceID, templateID, template_param)
            .then(() => {                
                dispatch(removeApplicationUser(confirmApplication.applicationsId))
                dispatch(hideLoadingAction());
            }).catch(() => {
                dispatch(hideLoadingAction());
                alert("完了受理に失敗しました");
            })                        
        }    
    }
    const confirmApplication = props.confirmApplication
    const confirmApplicationDateTime = datetimeToString(confirmApplication.send_at.toDate());
    return(        
        <div>
            <List className={classes.list}>
                <>
                    <ListItem key={confirmApplication.mailboxId} className={classes.flex}>
                        <div>
                            <TextDetail label={"応募日時"} value={confirmApplicationDateTime} />
                            <TextDetail label={"氏名"} value={confirmApplication.from_firstName + "××"} />                                                                                      
                        </div> 
                        <div className={classes.buttons}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={() => dispatch(push('/confirmapplications/' + confirmApplication.mailboxId))}>詳細</Button>                                                                                      
                        </div>                   
                    </ListItem>
                </>
            </List>
            <div className="module-spacer--extra-small" />
            <Divider />
        </div>           
    )
}

export default ConfirmApplicationItem;
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  field: {
    width: 400,
    [theme.breakpoints.down('sm')] : {
      width: 300,
      margin: "0 auto"
    }
  },  
  option: {
    width: 400,
    [theme.breakpoints.down('sm')] : {
      width: 200,
    }
  }
}))

const MultiSelect = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    return (
        <div>
          <FormControl className={classes.field}>
            <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              margin='dense'
              value={props.value}
              onChange={props.onChange}
              className={classes.field}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} className={classes.field}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {props.options.map((option) => (
                <MenuItem key={option} value={option} className={classes.option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
}

export default MultiSelect;
import './App.css';
import Router from './Router'
import Loading from './UIkit/Loading';

function App() {
  return (
    <div className="App">
      <Loading>
        <Router />
      </Loading>
    </div>
  );
}

export default App;

import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CompanyPro from './CompanyPro';
import SideHeader from '../components/header/SideHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: "#EBEBEB",
    height: "100vh",
    [theme.breakpoints.down('sm')]: {
      width: "100%",      
      height: "auto",
      display: "block"        
    },
  },  
  content: {
    width: "95%",
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {                     
      width: "100%",
      margin: "0 auto",
      padding: 0
   },
  },
  space: {
    height: 15
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "80%",
    marginRight: 195,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      justifyContent: "center",
      margin: "0 auto"
    }
  },
  buttonEdit: {
    backgroundColor: "#fff",
    border: "1px solid #333",
    fontSize: 20,
    width: 140,
    height: 40,
    marginLeft: 15,
    WebkitTextStroke: "0.5px #000000",
    marginTop: 20,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: 100,
      marginRight: 15
    }
  },
  phone_space: {
    [theme.breakpoints.down('sm')]: {
      height: 30
    }
  }
}));

 
const CompanyHome = () => {
    const dispatch = useDispatch();
    const classes = useStyles();            
  
    return(
        <div className={classes.root}>          
            <SideHeader />
            <div className="module-spacer--small" />
            <div className="module-spacer--small" />
            <div className="module-spacer--small" />
            <div className="module-spacer--small" />                        
            <main className={classes.content}>
                <CompanyPro />
            <div className={classes.buttons}>              
              <button className={classes.buttonEdit} onClick={() => dispatch(push("/companyinfo"))}>編集する</button>              
            </div>
            <div className={classes.phone_space} />
            <div className={classes.phone_space} />            
            </main>            
        </div>
    )
}

export default CompanyHome;
import React, { useState, useCallback, useEffect } from "react";
import { Paper, TextField, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SideHeader from "../components/header/SideHeader"
import { addChat, fetchPosts } from "../redux/posts/operations";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPosts } from "../redux/posts/selectors";
import PostsItem from "../components/companys/PostsItem";
import SelectBox from "../UIkit/SelectBox";
import { auth, db } from "../firebase";

const useStyle = makeStyles((theme) => ({
    root: {
        height: "100vh",
        display: "flex",        
        justifyContent: "space-between",
        backgroundColor: "#EBEBEB",
    },
    content: {
        display: "flex",
        justifyContent: "space-between",
        width: "80%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            flexFlow: "column",
            alignItems: "center"
        }
    },
    form: {
        padding: 30,
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",
        [theme.breakpoints.down('sm')]: {
            padding: 10
        }    
    },
    field: {
        width: 600,    
        [theme.breakpoints.down('sm')]: {
            width: 300,
            marginTop: 100
        }    
    },
    caution: {
        fontSize: 14,
        color: "#707070"
    },
    fieldHarf: {
        width: 100,        
    },
    view: {
        width: 400,
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
    none: {
        display: "none"
    }
}))

const categories = [
    {id: "eat", name: "飲食"}, 
    {id: "leisure", name: "レジャー"},
    {id: "customer", name: "接客"}, 
    {id: "sale", name: "販売"}, 
    {id: "education", name: "教育"},
    {id: "delivery", name: "配送"}, 
    {id: "easy_work", name: "軽作業"},
    {id: "it", name: "IT"}, 
    {id: "medical", name: "医療・福祉"}, 
    {id: "specialty", name: "専門"}, 
    {id: "office", name: "事務"}, 
    {id: "ahter", name: "その他"},
]

const ChatLogin = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const posts = getPosts(selector);
    const [content, setContent] = useState(""),
          [category, setCategory] = useState([]);

    const inputContent = useCallback((e) => {
        setContent(e.target.value)
    }, [setContent]);

    useEffect(() => {
        dispatch(fetchPosts());
    }, [])

    useEffect(() => {
        const companyid = auth.currentUser.uid
        db.collection('companys').doc(companyid).get()
            .then(snapshot => {
                const data =  snapshot.data()
                if(data.category) {
                    setCategory(data.category)
                }                
            })
    }, [])

    return (
        <div className={classes.root}>
            <SideHeader />
                <div className={classes.content}>
                    <div className={classes.form}>
                        <div className={classes.none}>
                            <SelectBox
                                options={categories} required={false} value={category} 
                                select={setCategory}
                            />         
                        </div>
                        <TextField 
                            value={content} onChange={inputContent} required={true} multiline={true}
                            type="text" placeholder="コメント..." className={classes.field}
                        />
                        <p className={classes.caution}>※誹謗中傷など不適切なコメントはこちらで修正または削除させていただく場合がございます</p>
                        <div className="module-spacer--small" />
                        <button className={classes.button} onClick={() => dispatch(addChat(category, content))}>投稿</button>
                    </div>
                    <Paper className={classes.view}>
                        <List>
                            {posts.length > 0 && (
                                posts.map((post) => <PostsItem post={post} key={post.postId}/>)
                            )}
                        </List>
                    </Paper>       
                </div>  
        </div>
    )
}

export default ChatLogin;
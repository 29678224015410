import React, { useContext, useCallback, useState } from "react";
import { TextField, Paper } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import { multiStepContext } from "../../SteoContext";
import ImageArea from "../companys/imageArea";
import MultiSelect from "../../UIkit/MultiSelect";
import RadioForm from "../../UIkit/Radio";
import PopoverLabel from "../../UIkit/Popover";

const useStyle = makeStyles((theme) => ({
    card: {
        width: 600,
        padding: 30,
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        border: "solid 1px #707070", 
        [theme.breakpoints.down('sm')]: {            
            width: "90%",    
            margin: "0 auto",
        },
    },
    title: {
        fontSize: 20,
        fontWeight: 400
    },
    images: {
        [theme.breakpoints.down('sm')]: {
            width: 300,
            margin: "0 auto"
        }
    },
    button: {
        padding: "5px 50px",
        fontSize: 14,
        color: "#707070",
        backgroundColor: "#fff",
        border: "1px solid #707070",
        borderRadius: 5,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            width: 150,
        },
        '&:hover': {
            background: "#EEEEEE",
         },
    },
    field: {
        width: 400,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    flexFlow: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",
    },
    flex: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
            flexFlow: "column",
            alignItems: "flex-start",
            width: 300,
        }
    },
}))

const shifts = [
    '平日のみOK',
    '土日祝のみOK',
];
const terms = [
    '長期(1ヶ月以上)',
    '短期(1ヶ月以内)',
    '単発',
    '夏休み限定',
    '春休み限定',
    '冬休み限定',
];
const welfares = [
    "まかないあり",
    "交通費支給",
    "社割あり",
    "賞与あり",
    "有給休暇あり",
];
const onlyones = [
    "髪型自由",
    "髪色自由",
    "ピアス可",
    "ネイル可",
];
const athers = [
    "長期休み限定",
    "日払い",
    "短時間勤務(1日2時間以内)OK",
    "未経験者OK",
    "友達と募集OK",
    "研修あり",
    "バイク通勤/車通勤OK",
    "資格所得支援制度",
    "オープニングスタッフ",
    "留学生歓迎",
    "即日勤務OK",
    "WワークOK",
    "残業なし",
    "送迎あり",
    "扶養控除内勤務可",
    "昇給あり",
    "履歴書不要",
    "オンライン面接可能",
];
const Any = () => {
    const { setCurrentStep, 
            images, setImages,            
            shift, setShift,
            term, setTerm,
            welfare, setWelfare,
            onlyone, setOnlyone,
            ather, setAther,
            station, setStation,            
            range, setRange,
            message, setMessage,
            url, setUrl
        } = useContext(multiStepContext)

    const shiftChange = (event) => {
        setShift(event.target.value)
    }    
    const termChange = (event) => {
        setTerm(event.target.value)
    }    
    const welfareChange = (event) => {
        setWelfare(event.target.value)
    }
    const onlyoneChange = (event) => {
        setOnlyone(event.target.value)
    }
    const ahterChange = (event) => {
        setAther(event.target.value)
    }
    const inputStation = useCallback((e) => {
        setStation(e.target.value)
    }, [setStation])
    const inputRange = useCallback((e) => {
        setRange(e.target.value)
    }, [setRange])
    const inputMessage = useCallback((e) => {
        setMessage(e.target.value)
    }, [setMessage])
    const inputUrl = useCallback((e) => {
        setUrl(e.target.value)
    }, [setUrl]) 
    const classes = useStyle();

    const [accessExe, setAccessExe] = useState(null),
          [urlExe, setUrlExe] = useState(null), 
          [messageExe, setmessageExe] = useState(null);

    const accessHandleOpen = (e) => {
        setAccessExe(e.currentTarget)
    }
    const urlHandleOpen = (e) => {
        setUrlExe(e.currentTarget)
    }
    const messageHandleOpen = (e) => {
        setmessageExe(e.currentTarget)
    }

    const accessHandleClose = () => {
        setAccessExe(null)
    }
    const urlHanldeClose = () => {
        setUrlExe(null)
    }
    const messageHandleClose = () => {
        setmessageExe(null)
    }

    const accessOpen = Boolean(accessExe);
    const urlOpen = Boolean(urlExe);
    const messageOpen = Boolean(messageExe);

    const accessId = accessOpen ? 'simple-popover' : undefined;
    const urlId = urlOpen ? 'simple-popover' : undefined;
    const messageId = messageOpen ? 'simple-popover' : undefined;

    return(
        <div className={classes.form}>
            <ImageArea images={images} setImages={setImages} />
            <Paper elevation={0} className={classes.card}>           
            <h2 className={classes.title}>店舗任意情報</h2>
            <div className={classes.flexFlow}>            
            <div style={{display: "flex"}}>
            <p style={{fontSize: 14, color: "#707070"}}>アクセス</p>  
            <PopoverLabel 
                    handleClick={accessHandleOpen}
                    id={accessId}
                    open={accessOpen}
                    anchorEl={accessExe}
                    handleClose={accessHandleClose}
                    message={
                        <div>
                        <p>
                            ご登録いただく店舗様の所在地から最寄りの駅やバス停、施設などをご記入ください。<br />
                            <strong>記入例</strong><br />
                        </p>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <TextField
                                multiline={true}  variant="outlined" margin='dense' style={{width: 280}}
                                rows={1} value={"小野田駅"} 
                            />から
                            <TextField
                                multiline={true}  variant="outlined" margin='dense' style={{width: 80}}
                                rows={1} value={"1000"} 
                            />m
                        </div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <TextField
                                multiline={true}  variant="outlined" margin='dense' style={{width: 280}}
                                rows={1} value={"小野田市役所"} 
                            />から
                            <TextField
                                multiline={true}  variant="outlined" margin='dense' style={{width: 80}}
                                rows={1} value={"500"} 
                            />m
                        </div>
                        </div>
                    }
            />   
            </div>
                <div className={classes.flex}>
                    <TextField
                        multiline={true}  variant="outlined" margin='dense' style={{width: 280}}
                        rows={1} value={station} type="text" onChange={inputStation} placeholder='○○駅'
                    />から
                    <TextField
                        multiline={true}  variant="outlined" margin='dense' style={{width: 80}}
                        rows={1} value={range} type="text" onChange={inputRange} placeholder="100"                            
                    />m
                </div>
            </div>
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}>
            <p style={{fontSize: 14, color: "#707070"}}>シフト</p> 
            <MultiSelect value={shift} onChange={shiftChange} options={shifts} label={""}/>
            </div>
            <div className={classes.flexFlow}>
            <p style={{fontSize: 14, color: "#707070"}}>勤務期間</p> 
            <MultiSelect value={term} onChange={termChange} options={terms} label={""}/>
            </div>
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}>
            <p style={{fontSize: 14, color: "#707070"}}>福利厚生</p> 
            <MultiSelect value={welfare} onChange={welfareChange} options={welfares} label={""} />   
            </div>
            <div className={classes.flexFlow}>
            <p style={{fontSize: 14, color: "#707070"}}>自分らしさ</p> 
            <MultiSelect value={onlyone} onChange={onlyoneChange} options={onlyones} label={""} />   
            </div>
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}>
            <p style={{fontSize: 14, color: "#707070"}}>その他</p> 
            <MultiSelect value={ather} onChange={ahterChange} options={athers} label={""} />
            </div>  
            <div className='module-spacer--extra' />           
            <div className={classes.flexFlow}>
            <div style={{display: "flex"}}>
                <p style={{fontSize: 14, color: "#707070"}}>ホームページURL</p> 
                <PopoverLabel 
                    handleClick={urlHandleOpen}
                    id={urlId}
                    open={urlOpen}
                    anchorEl={urlExe}
                    handleClose={urlHanldeClose}
                    message={
                        <p>
                            ホームページなど店舗様を紹介できるサイトがございましたら<br />こちらにURLをご記入ください。
                        </p>
                    }
                />   
            </div>
            <TextField
                multiline={true} variant="outlined" className={classes.field} margin='dense'
                required={true} rows={1} value={url} type={"text"} onChange={inputUrl} placeholder='例)https://wwwww.com'
            />    
            </div>   
            <div className='module-spacer--extra' /> 
            <div className={classes.flexFlow}>
            <div style={{display: "flex"}}>
            <p style={{fontSize: 14, color: "#707070"}}>メッセージ</p> 
            <PopoverLabel 
                    handleClick={messageHandleOpen}
                    id={messageId}
                    open={messageOpen}
                    anchorEl={messageExe}
                    handleClose={messageHandleClose}
                    message={
                        <p>
                            お店の雰囲気、仕事内容、ポリシーなどご自由にご記入ください。<br />
                            文字数制限はございません。<br />
                            <span style={{color: "red", fontSize: 12}}>※改行部分は店舗詳細画面にもそのまま反映されます。</span>
                        </p>
                    }
            />   
            </div>
            <TextField
                multiline={true} variant="outlined" className={classes.field} margin='dense'
                required={true} rows={6} value={message} type={"text"} onChange={inputMessage} placeholder='メッセージ'
            />
            </div>
            <div className='module-spacer--extra' /> 
            <div>
                <button onClick={() => setCurrentStep(2)} className={classes.button} >戻る</button>
                <button onClick={() => setCurrentStep(4)} className={classes.button} >次へ</button>
            </div>
            </Paper>
        </div>
    )
}

export default Any;
import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
    root: {
        maxWidth: 300,
    },
})

const FieldInput = (props) => {
    const classes = useStyle();
    return(
        <TextField
         fullWidth={props.fullWidth}
         label={props.label}
         placeholder={props.placeholder}
         margin="dense"
         multiline={props.multiline}
         required={props.required}
         rows={props.rows}
         value={props.value}
         type={props.type}
         onChange={props.onChange}
         id="outlined-basic" 
         variant="outlined"
         className={classes.root}
         ref={props.ref}
         name={props.naem}
        />
    )
}

export default FieldInput;
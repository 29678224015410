import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import ImageSwiper from '../components/companys/imageSwiper';
import useStyle from './companydetail';
import { Card, Paper } from '@material-ui/core';
import HTMLReactParser from 'html-react-parser';
import { useSelector } from 'react-redux';
import SideHeader from '../components/header/SideHeader';

const returnCodeToBr = (text) => {
    if(text === "") {
        return text
    } else {
        return HTMLReactParser(text.replace(/\r?\n/g, '<br>'))
    }
}

const CompanyDetails = () => {
    const classes = useStyle();
    const [companyInfo, setCompanyInfo] = useState(null);
    const selector = useSelector((state) => state);
    const path = selector.router.location.pathname
    const id = path.split('/companydetail/')[1]
    useEffect(() => {
        db.collection('companys').doc(id).get()
        .then(doc => {                
            const data = doc.data()
            setCompanyInfo(data)
        })
    }, [])


    return(
        <div className={classes.root}>
            <SideHeader />
        <div className={classes.companyHome}>
          <div className="module-spacer--medium" />
          {companyInfo && (
            <div>
            <Card className={
                    companyInfo.category === "飲食" && classes.card_eat || 
                    companyInfo.category === "レジャー" && classes.card_Leisure|| 
                    companyInfo.category === "接客" && classes.card_customer || 
                    companyInfo.category === "販売" && classes.card_sale || 
                    companyInfo.category === "教育" && classes.card_education || 
                    companyInfo.category === "配送" && classes.card_delivery || 
                    companyInfo.category === "軽作業" && classes.card_easy_work || 
                    companyInfo.category === "IT" && classes.card_it || 
                    companyInfo.category === "医療・福祉" && classes.card_medical || 
                    companyInfo.category === "専門" && classes.card_specialty || 
                    companyInfo.category === "事務" && classes.card_office || 
                    companyInfo.category === "その他" && classes.card_ahter 
                    }>
                <h1 className={
                    companyInfo.category === "飲食" && classes.companyName_eat || 
                    companyInfo.category === "レジャー" && classes.companyName_Leisure|| 
                    companyInfo.category === "接客" && classes.companyName_customer || 
                    companyInfo.category === "販売" && classes.companyName_sale || 
                    companyInfo.category === "教育" && classes.companyName_education || 
                    companyInfo.category === "配送" && classes.companyName_delivery || 
                    companyInfo.category === "軽作業" && classes.companyName_easy_work || 
                    companyInfo.category === "IT" && classes.companyName_it || 
                    companyInfo.category === "医療・福祉" && classes.companyName_medical || 
                    companyInfo.category === "専門" && classes.companyName_specialty || 
                    companyInfo.category === "事務" && classes.companyName_office || 
                    companyInfo.category === "その他" && classes.companyName_ahter 
                }>
                {companyInfo.companyName !== "" && companyInfo.companyName}
                </h1>
                <div className={classes.borderBottom} />
                <p className={classes.introduce}>{companyInfo.introduce}</p>
                <div className={classes.column}>
                    <div className={classes.sliderBox}>
                        {companyInfo.images && (
                            <ImageSwiper images={companyInfo.images}/>
                        )}
                    </div>                        
                    <ul className={classes.flex}>
                        {companyInfo.attendanceshift  && 
                            companyInfo.attendanceshift.map(doc => (
                                <li className={classes.filter}>{doc}</li>
                        ))}
                        {companyInfo.shift  && 
                            companyInfo.shift.map(doc => (
                                <li className={classes.filter}>{doc}</li>
                        ))}
                        {companyInfo.welfare  && 
                            companyInfo.welfare.map(doc => (
                                <li className={classes.filter}>{doc}</li>
                        ))}     
                        {companyInfo.onlyone  && 
                            companyInfo.onlyone.map(doc => (
                                <li className={classes.filter}>{doc}</li>
                        ))}     
                            {companyInfo.ather  && 
                            companyInfo.ather.map(doc => (
                                <li className={classes.filter}>{doc}</li>
                        ))}                                                                      
                    </ul>
                </div>
                <div className="module-spacer--small" />
                </Card>                
                <table className={classes.table}>
                    <div className={classes.detailLeft}>  
                    {companyInfo.saraly && (
                    <tr className={classes.tr}>
                    <th className={classes.th}>給与</th>
                    <td className={classes.td}>時給{companyInfo.saraly}円~</td>
                    </tr>        
                    )}        
                    <div className="module-spacer--small" />                                                                                
                    {isNaN(companyInfo.saralyNight) ? (
                        <p style={{display: "none"}}></p>
                    ) : (
                    <tr className={classes.tr}>
                    <th className={classes.th}>深夜給与</th>
                    <td className={classes.td}>時給{companyInfo.saralyNight}円~(22:00以降)</td>
                    </tr>        
                    )}                                                                                         
                    <div className="module-spacer--small" />
                    {companyInfo.category && ( 
                    <tr className={classes.tr}>
                    <th className={classes.th}>職種</th>
                    <td className={classes.td}>{companyInfo.category}</td>
                    </tr>
                    )}
                    <div className="module-spacer--small" /> 
                    {companyInfo.times && (
                        companyInfo.times.length > 0 && (
                        <tr className={classes.tr}>
                        <th className={classes.th}>勤務時間</th>
                        <td className={classes.td}>{companyInfo.times && 
                            companyInfo.times.map(doc => (
                                <ul style={{display: "flex"}}>
                                <li className={classes.partTimefont}>{doc.startHour} : {doc.startMinutes} ~ </li>
                                <li className={classes.partTimefont}>{doc.finishHour} : {doc.finishMinutes} 時給{doc.saralycontent}円</li>
                                </ul>
                            ))
                        }</td>    
                        </tr>    
                        )
                    )}
                    <div className="module-spacer--small" />
                    <tr className={classes.tr}>
                    <th className={classes.th}>住所</th>
                    <td className={classes.td}>〒 {companyInfo.addressNum}<br />{companyInfo.address}</td>                       
                    </tr>
                    <div className="module-spacer--small" />
                    {companyInfo.station && companyInfo.range && (
                    <tr className={classes.tr}>
                    <th className={classes.th}>アクセス</th>
                    <td className={classes.td}>{companyInfo.station}から{companyInfo.range}m</td>                       
                    </tr>      
                    )}  
                    <div className="module-spacer--small" />  
                    {companyInfo.url && (
                    <tr className={classes.tr}>
                    <th className={classes.th}>URL</th>
                    <td className={classes.td}><a href={companyInfo.url}>{companyInfo.url}</a></td>                       
                    </tr>   
                    )}                       
                    </div>
                    <div className={classes.detailRight}>
                    {companyInfo.contents && (
                      companyInfo.contents.length > 0 && (
                        <tr className={classes.tr}>
                        <th className={classes.th}>仕事内容</th>
                        <td className={classes.td}>
                            {companyInfo.contents.map(doc => (
                                <ul>
                                <li className={classes.td}>◆{doc.content}</li>
                                <li className={classes.td}>{returnCodeToBr(doc.detail)}</li>
                                </ul>
                            ))}
                        </td>               
                        </tr>
                        )
                    )}         
                    </div>
                </table>
                <div className="module-spacer--medium" />
                    {companyInfo.message && (
                    <div className={classes.point}>
                    <h2 className={classes.point_sub}>POINT</h2>
                    <p className={classes.message}>{returnCodeToBr(companyInfo.message)}</p>
                    </div>
                    )}            
                    <div className="module-spacer--medium" />
                    {companyInfo.contents && (
                        <div className={classes.pointDetail}>
                        <h2 className={classes.pointDetailSubject}>お仕事情報</h2>    
                        <div className="module-spacer--small" />
                        <p className={classes.pointDetailTitle}>お仕事内容</p>
                        {companyInfo.contents.map(doc => (
                            <ul>
                            <li style={{textAlign: "left"}}>◆{doc.content}</li>
                            <li style={{textAlign: "left"}}>{returnCodeToBr(doc.detail)}</li>
                            </ul>
                        ))}
                        </div>
                    )}
                <div className={classes.pointDetailFlex}>
                {companyInfo.saralyDetails && (
                    <div className={classes.pointDetailSmall}>
                        <div className="module-spacer--small" />
                        <p className={classes.pointDetailTitle}>給与詳細</p>
                        <p style={{textAlign: "left"}}>{returnCodeToBr(companyInfo.saralyDetails)}</p>
                    </div>
                )}
                {companyInfo.shiftDetails && (
                    <div className={classes.pointDetailSmall}>
                    <div className="module-spacer--small" />
                    <p className={classes.pointDetailTitle}>シフト・勤務時間</p>
                    <p style={{textAlign: "left"}}>{returnCodeToBr(companyInfo.shiftDetails)}</p>
                    <ul className={classes.flexDetails}>
                    {companyInfo.shift  && 
                        companyInfo.shift.map(doc => (
                            <li className={classes.filter}>{doc}</li>
                    ))}           
                    {companyInfo.attendanceshift  && 
                        companyInfo.attendanceshift.map(doc => (
                        <li className={classes.filter}>{doc}</li>
                    ))}                                                                   
                    </ul>
                    </div>
                )}               
                </div>
                <div className={classes.pointDetailFlex}>
                {companyInfo.welfareDetails && (
                    <div className={classes.pointDetailSmall}>
                    <div className="module-spacer--small" />
                    <p className={classes.pointDetailTitle}>待遇・福利厚生</p>
                    <p style={{textAlign: "left"}}>{returnCodeToBr(companyInfo.welfareDetails)}</p>
                    <ul className={classes.flexDetails}>
                    {companyInfo.welfare  && 
                        companyInfo.welfare.map(doc => (
                            <li className={classes.filter}>{doc}</li>
                    ))}                                                                     
                    </ul>
                    </div>
                )}             
                {companyInfo.partTimeDetails && (
                    <div className={classes.pointDetailSmall}>
                    <div className="module-spacer--small" />
                    <p className={classes.pointDetailTitle}>勤務期間</p>
                    <p style={{textAlign: "left"}}>{returnCodeToBr(companyInfo.partTimeDetails)}</p>
                    <ul className={classes.flexDetails}>
                    {companyInfo.term  && 
                        companyInfo.term.map(doc => (
                            <li className={classes.filter}>{doc}</li>
                    ))}                                                                     
                    </ul>
                    </div>
                )}              
                </div>      
                </div>
            )}    
            <div className="module-spacer--medium" />
        </div>
        </div>
    )
}
                            


export default CompanyDetails;
import React from "react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
    button: {
        width: 20,
        height: 20,
        borderRadius: "50%",
        backgroundColor: "#FF9D00",
        color: "#fff",
        fontSize: 10,
        border: "none"
    }
}))

const PopoverLabel = (props) => {
    const classes = useStyle()
    return(
        <div>
            <button className={classes.button} aria-describedby={props.id} color="warning" variant="contained" onClick={props.handleClick}>
                ?
            </button>
            <Popover
                id={props.id}
                open={props.open}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            >
                <Typography style={{width: 300}} sx={{ p: 2 }}>{props.message}</Typography>
            </Popover>
        </div>
    )
}

export default PopoverLabel;
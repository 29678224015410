import { fetchCompanyMailDetailsAction, signInAction, signOutAction } from "./actions";
import { FirebaseTimeStamp, db, auth } from "../../firebase"
import { push } from "connected-react-router";
import { fetchCompanysAction, fetchCompanyMailsAction, fetchSanparkAction, fetchYumetownAction } from "./actions";
import { hideLoadingAction, showLoadingAction } from "../loading/actions";
import { isValidEmailFormat, isValidRequiredInput } from "../../function/common";
import { init, send } from "emailjs-com";

const companysRef = db.collection('companys')

export const fetchCompanyMails = () => {
    return async(dispatch, getState) => {
        const user = auth.currentUser;
        const companyid = user.uid
        const list = []

        db.collection('companys').doc(companyid)
            .collection('mailbox')
            .orderBy('send_at', 'desc')
            .get()
            .then((snapshots) => {
                snapshots.forEach(snapshot => {
                    const data = snapshot.data();
                    list.push(data)
                })
            dispatch(fetchCompanyMailsAction(list))            
            })
    }
}
export const fetchCompanyMailDetails = () => {
    return async(dispatch) => {
        const user = auth.currentUser;
        const companyid = user.uid
        const list = []

        db.collection('companys').doc(companyid)
            .collection('maildetailbox')
            .orderBy('send_at', 'desc')
            .get()
            .then((snapshots) => {
                snapshots.forEach(snapshot => {
                    const data = snapshot.data();
                    list.push(data)
                })
            dispatch(fetchCompanyMailDetailsAction(list))            
            })
    }
}

export const listenAuthState = () => {
    return async (dispatch) => {
        return auth.onAuthStateChanged(company => {
            if(company) {
              const companyid = company.uid

                db.collection('companys').doc(companyid).get()
                  .then(snapshot => {
                      const data = snapshot.data();

                      dispatch(signInAction({
                          isSignedIn: true,
                          role: data.role,
                          companyid: data.companyid,
                          companyName: data.companyName
                      }))
                  }) 
            } else {
                dispatch(push('/'))
            }
        })
    }
}

export const deleteAcount = () => {
    return async(dispatch) => {
        const user = auth.currentUser;
        const uid = user.uid
        dispatch(showLoadingAction("退会手続き中..."))
        db.collection('companys').doc(uid).delete().then(() => {
            user.delete().then(() => {
                dispatch(hideLoadingAction());
                dispatch(push('/signin'))
           })
        }).catch(() => {
            dispatch(hideLoadingAction());
        })
    }
} 

export const signUp = (companyName, firstName, lastName, email, password, confirmPass, tel ,addressNum, address, category, saraly, saralyNight, introduce, station, range, times, contents, attendanceshift, gakutika, pharmacy, url, message, students, studentspast, images, shift, term, welfare, onlyone, ather, shiftDetails, saralyDetails, partTimeDetails, welfareDetails) => {
    const userID = process.env.REACT_APP_EMAIL_JS_USER_ID;
    const serviceID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
    const templateID = process.env.REACT_APP_SIGN_UP_TEMPLATE_ID;   
    return async (dispatch) => {
        dispatch(showLoadingAction("登録中..."))
        // Validations
        if(!isValidRequiredInput(companyName, firstName, lastName, email, password, confirmPass, tel, addressNum, address)) {
            dispatch(hideLoadingAction());
            alert('必須項目が未入力です。');
            return false
        }

        if(!isValidEmailFormat(email)) {
            dispatch(hideLoadingAction());
            alert('メールアドレスの形式が不正です。もう1度お試しください。')
            return false
        }
        if (password !== confirmPass) {
            dispatch(hideLoadingAction());
            alert('パスワードが一致しません。もう1度お試しください。')
            return false
        }
        if (password.length < 6) {
            dispatch(hideLoadingAction());
            alert('パスワードは6文字以上で入力してください。')
            return false
        }

        return auth.createUserWithEmailAndPassword(email, password)
            .then(result => {
                const user = result.user;

                if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
                    init(userID);
                    const template_param = {
                        companyName: companyName,
                        firstName: firstName,
                        lastName: lastName,
                        email: email
                    }
                    send(serviceID, templateID, template_param) 
                        .then(() => {
                            const companyid = user.uid;
                            const timestamp = FirebaseTimeStamp.now();
            
                            const companyData = {
                                companyid: companyid,
                                created_at: timestamp,
                                role: "company",
                                companyName: companyName,
                                firstName: firstName,
                                lastName: lastName,
                                email: email,
                                tel: tel,
                                addressNum: addressNum,
                                address: address,
                                category: category,
                                saraly: parseInt(saraly),
                                saralyNight: parseInt(saralyNight),
                                introduce: introduce,
                                station: station,
                                range: range,
                                times: times,
                                contents: contents,
                                attendanceshift: attendanceshift,
                                gakutika: gakutika,
                                pharmacy: pharmacy,
                                url: url,
                                message: message,
                                students: students,
                                studentspast: studentspast,
                                images: images,
                                shift: shift,
                                term: term,
                                welfare: welfare,
                                onlyone: onlyone,
                                ather: ather,
                                shiftDetails: shiftDetails,
                                saralyDetails: saralyDetails,
                                partTimeDetails: partTimeDetails,
                                welfareDetails: welfareDetails,
                                updated_at_at: timestamp
                            }
            
                            db.collection('companys').doc(companyid).set(companyData)
                            .then(() => {
                                dispatch(hideLoadingAction());
                                dispatch(push('/companyhome'))      
                            }).catch((error) => {
                                throw new Error(error)    
                            })
                        })
                }         
            }).catch((error) => {
                dispatch(hideLoadingAction());
                alert('アカウント登録に失敗しました。もう1度お試しください。')
                throw new Error(error)
            })
    }
}

export const signIn = (email, password) => {
    return async (dispatch) => {
        dispatch(showLoadingAction("サインイン..."))
        if (!isValidRequiredInput(email, password)) {
            dispatch(hideLoadingAction());
            alert('メールアドレスかパスワードが未入力です。')
            return false
        }
        if (!isValidEmailFormat(email)) {
            dispatch(hideLoadingAction());
            alert('メールアドレスの形式が不正です。')
            return false
        }
        return auth.signInWithEmailAndPassword(email, password)
        .then(result => {
            const companyState = result.user;

            if(!companyState) {
                dispatch(hideLoadingAction());
                throw new Error("企業IDを取得できません")                             
            }

            const comapnyId = companyState.uid

            return db.collection('companys').doc(comapnyId).get()
            .then(snapshot => {
                const data = snapshot.data();
                
                if(!data) {
                    dispatch(hideLoadingAction())
                    throw new Error('ユーザーデータが存在しません')                    
                }
                dispatch(signInAction({
                    isSignedIn: true,
                    role: data.role,
                    companuid: data.companuid,
                    companyName: data.companyName,
                }))

                dispatch(hideLoadingAction())
                dispatch(push('/companyhome'))
            })
       }).catch(() => {
            dispatch(hideLoadingAction());            
       })
   }
}

export const signOut = () => {
    return async (dispatch) => {
        dispatch(showLoadingAction('サインアウト...'))
        auth.signOut() 
           .then(() => {
               dispatch(signOutAction());
               dispatch(hideLoadingAction());
               dispatch(push('/'));
           }).catch(() => {
                dispatch(hideLoadingAction());
                throw new Error('ログアウトに失敗しました。')
           })
    }
}

export const resetPassword = (email) => {
    return async (dispatch) => {
        dispatch(showLoadingAction('メールを送信中...'))
        if (email === "") {
            alert ("必須項目が未入力です")
            return false
        } else {
            auth.sendPasswordResetEmail(email)
             .then(() => {
                dispatch(hideLoadingAction());
                 dispatch(push('/'))
             }) .catch (() => {
                dispatch(hideLoadingAction());
                 alert ("パスワードリセットに失敗しました。通信環境を確認してください。")
             })
        }
    }
}
                

export const fetchCompanys = () => {
    return async(dispatch) => {
        companysRef.get()
         .then(snaphots => {
             const companyList = [];
             snaphots.forEach(snaphot => {
                 const company = snaphot.data()
                 companyList.push(company)
             })
             dispatch(fetchCompanysAction(companyList))
         })
    }
}

export const saveCompany = ( companyName, introduce, images, saraly, saralyNight, category, times, addressNum, address, station, range, contents, attendanceshift, url, message, shift, term, welfare, onlyone, ather, students, studentspast, gakutika, pharmacy, saralyDetails, shiftDetails, welfareDetails, partTimeDetails) => {
    return async(dispatch) => {
        dispatch(showLoadingAction('企業情報追加中...'))
        if(introduce.length > 20) {
            dispatch(hideLoadingAction());
            alert('一言紹介は20字以内にしてください')
            return false
        }
        const myComapny = auth.currentUser;
        const companyid = myComapny.uid
            const timestamp = FirebaseTimeStamp.now();
            const data ={
                companyName: companyName,
                introduce: introduce,
                images: images,
                saraly: parseInt(saraly),
                saralyNight: parseInt(saralyNight),
                category: category,
                times: times,
                addressNum: addressNum,
                address: address,
                station: station, 
                range: range,
                contents: contents,
                attendanceshift: attendanceshift,
                url: url,
                message: message,
                shift: shift, 
                term: term,
                welfare: welfare,
                onlyone: onlyone,
                ather: ather,
                students: students,
                studentspast: studentspast,
                gakutika: gakutika,
                pharmacy: pharmacy,
                saralyDetails: saralyDetails,
                shiftDetails: shiftDetails,
                welfareDetails: welfareDetails,
                partTimeDetails: partTimeDetails,
                updated_at: timestamp
            }

            return db.collection('companys').doc(companyid).set(data, {merge: true})
            .then(() => {
                dispatch(hideLoadingAction());
                dispatch(push('/companyhome'))
            }).catch(() => {
                dispatch(hideLoadingAction())
                throw Error('企業情報が追加できませんでした。')
            })
        }
}

export const fetchSanpark = () => {
    return async(dispatch) => {
        const list = []
        companysRef
        .where('complex', '==', '小野田サンパーク')
        .get()
        .then((docs) => {
            docs.forEach(doc => {
                const data = doc.data();
                list.push(data)
            })
            dispatch(fetchSanparkAction(list));
        })
    }
}
export const fetchYumetown = () => {
    return async(dispatch) => {
        const list = []
        companysRef
        .where('complex', '==', '宇部ゆめタウン')
        .get() 
        .then((snapshots) => {
            snapshots.forEach(snaphot => {
                const data = snaphot.data();
                list.push(data)
                })
                dispatch(fetchYumetownAction(list))
            })
    }
}

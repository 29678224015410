import React, {useState, useCallback} from 'react';
import Divider from '@material-ui/core/Divider'
import {makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, List, ListItem, Tab, Tabs, Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { ClearRefinements, SearchBox, RangeInput, RefinementList } from 'react-instantsearch-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "instantsearch.css/themes/reset.css";
import "instantsearch.css/themes/satellite.css";
import { CustomRangeSlider } from './RangeSlider';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        overflowY: "auto",
        height: "90vh",
    },
    tabs:{
        color: "#333",
    },
    searchField: {
        alignItems: 'center',
        display: 'flex',
    },
    margin: {
        height: 12,
    },
    container: {
        padding: '10px',
    },
    icon: {
        alignItems: 'center',
        display: 'flex',
    },
    nested: {
        paddingLeft: theme.spacing(1),
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
};
}
  


const Search = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
      const handleChangeIndex = (index) => {
        setValue(index);
    };

    return(
        <div className={classes.container}>
        <Typography variant="h6" style={{textAlign: "left", fontSize: 12}}>条件絞り込み</Typography>
            <div className={classes.searchField}>
                <SearchBox　searchAsYouType={false} translations={{placeholder: '検索...',}} />
            </div>
            <div className={classes.margin}/>
            <Divider />
            <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                    style: {
                      backgroundColor: 'orange',
                      height: '2px'
                    }
                }}
                className={classes.tabs}
                aria-label="full width tabs example"
                variant="fullWidth"
                style={{width: 230}}
            >
                <Tab label="人気の条件" style={{minWidth: "50%"}} {...a11yProps(0)} />
                <Tab label="その他の条件" style={{minWidth: "50%"}} {...a11yProps(1)} />
            </Tabs>
            <div className={classes.margin}/>
            <ClearRefinements  translations={{reset: '検索条件をクリア',}}/>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
            <TabPanel value={value} index={0} dir={theme.direction}>
                <Typography className={classes.icon}>
                   職種
                </Typography>
                <List component="div" disablePadding>
                    <ListItem className="left-panel">
                        <RefinementList attribute="category"/>
                    </ListItem>
                </List>
                <Divider />
                <Typography className={classes.icon}>
                    時給
                </Typography>
                <List component="div" disablePadding>
                        <CustomRangeSlider attribute="saraly"/>
                </List>
                    <div className={classes.margin}/>
                    <div className={classes.margin}/>
                    <div className={classes.margin}/>
                <Divider />
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >          
                    <Typography className={classes.heading}>
                        深夜時給
                    </Typography>          
                    </AccordionSummary>
                    <AccordionDetails>
                    <List component="div" disablePadding>
                        <CustomRangeSlider attribute="saralyNight"/>
                    </List>
                    </AccordionDetails>
                </Accordion>    
                <div className={classes.margin}/>
                <Typography className={classes.icon}>
                    現役理科大生
                </Typography>
                <List component="div" disablePadding>
                    <ListItem className={classes.nested}>
                    <RefinementList attribute="students"/>
                    </ListItem>
                </List>
                <Divider />
                <Typography className={classes.icon}>
                    ガクチカ協力店
                </Typography>
                <List component="div" disablePadding>
                    <ListItem className={classes.nested}>
                    <RefinementList attribute="gakutika"/>
                    </ListItem>
                </List>
                <Divider />
                <Typography className={classes.icon}>
                    薬学部歓迎店舗
                </Typography>
                <List component="div" disablePadding>
                    <ListItem className={classes.nested}>
                    <RefinementList attribute="pharmacy"/>
                    </ListItem>
                </List>
                <Divider />
                <Typography className={classes.icon}>
                    週に勤務する日数
                </Typography>
                <List component="div" disablePadding>
                    <ListItem className={classes.nested}>
                    <RefinementList attribute="attendanceshift"/>
                    </ListItem>
                </List>
                <Divider />
                <Typography className={classes.icon}>
                    シフト
                </Typography>
                <List component="div" disablePadding>
                    <ListItem className={classes.nested}>
                    <RefinementList attribute="shift"/>
                    </ListItem>
                </List>
                <Divider />
                <Typography className={classes.icon}>
                    福利厚生
                </Typography>
                <List component="div" disablePadding>
                    <ListItem className={classes.nested}>
                    <RefinementList attribute="welfare"/>
                    </ListItem>
                </List>
                <Divider />
                <Typography className={classes.icon}>
                    自分らしさ
                </Typography>
                <List component="div" disablePadding>
                    <ListItem className={classes.nested}>
                    <RefinementList attribute="onlyone"/>
                    </ListItem>
                </List>
                <Divider />               
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
            <Typography className={classes.icon}>
                   その他
                </Typography>
                <List component="div" disablePadding>
                    <ListItem className="left-panel">
                        <RefinementList attribute="ather"/>
                    </ListItem>
                </List>
                <Divider />
            </TabPanel>
            <Divider />
        </SwipeableViews>
        </div>
        </div>
    )
}

export default Search;